import React from "react";

function TeaWhite({width=20, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#ffffff" fillRule="nonzero" transform="translate(-430 -36)">
          <g transform="translate(430 36)">
            <path d="M4.017 5a.5.5 0 01-.278-.916c.408-.272.387-.476-.14-1.364-.405-.68-1.083-1.82.14-2.636a.499.499 0 11.554.832c-.35.234-.377.382.165 1.293.414.696 1.106 1.86-.164 2.707A.5.5 0 014.017 5zM7.993 5a.5.5 0 01-.278-.916c.408-.272.387-.476-.141-1.364C7.17 2.04 6.492.9 7.715.084a.499.499 0 11.554.832c-.35.234-.377.382.165 1.293.414.696 1.106 1.86-.164 2.707A.497.497 0 017.993 5zM11.993 5a.5.5 0 01-.278-.916c.408-.272.387-.476-.141-1.364-.404-.68-1.082-1.82.141-2.636a.499.499 0 11.554.832c-.351.234-.378.381.164 1.293.414.696 1.106 1.86-.164 2.707a.491.491 0 01-.276.084z"></path>
            <path d="M12 24H4C2.07 24 .5 22.43.5 20.5v-14A.5.5 0 011 6h14a.5.5 0 01.5.5v14c0 1.93-1.57 3.5-3.5 3.5zM1.5 7v13.5C1.5 21.879 2.622 23 4 23h8c1.378 0 2.5-1.122 2.5-2.5V7h-13z"></path>
            <path d="M17 17h-2a.5.5 0 010-1h2c.827 0 1.5-.673 1.5-1.5v-4c0-.827-.673-1.5-1.5-1.5h-2a.5.5 0 010-1h2c1.378 0 2.5 1.122 2.5 2.5v4c0 1.379-1.122 2.5-2.5 2.5zM5 12a.5.5 0 01-.5-.5v-5a.5.5 0 011 0v5a.5.5 0 01-.5.5z"></path>
            <path d="M7 16H3a.5.5 0 01-.5-.5v-3c0-.133.053-.26.146-.354l1-1A.504.504 0 014 11h2c.133 0 .26.053.354.146l1 1a.504.504 0 01.146.354v3a.5.5 0 01-.5.5zm-3.5-1h3v-2.293L5.793 12H4.207l-.707.707V15z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TeaWhite;