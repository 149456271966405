import React from "react";

function Maybe(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 171 171"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#FEB116"
        strokeWidth="1"
        transform="translate(6 6)"
      >
        <path
          strokeLinecap="round"
          strokeWidth="7.8"
          d="M34.52 79.52h90"
        ></path>
        <circle cx="79.5" cy="79.5" r="79.5" strokeWidth="10.4"></circle>
      </g>
    </svg>
  );
}

export default Maybe;
