import React from "react";

function Medical({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-135 -310)">
          <g transform="translate(135 310)">
            <path d="M9 24h-.017a.5.5 0 01-.483-.517c.047-1.405.701-2.088 1.242-2.412a.5.5 0 01.515.858c-.477.286-.732.82-.758 1.588A.499.499 0 019 24z"></path>
            <g>
              <path d="M12 8C5.895 8 .949 7 .899 6.99A.5.5 0 011 6c.866 0 1.888-.747 2.876-1.469C4.954 3.744 5.972 3 7 3c1.902 0 5.127 1.99 5.264 2.075a.501.501 0 01-.528.85C10.874 5.39 8.342 4 7 4c-.702 0-1.633.681-2.534 1.339-.477.349-.961.702-1.451.992C4.979 6.62 8.268 7 12 7a.5.5 0 010 1z"></path>
              <path d="M12 8a.5.5 0 010-1c3.736 0 7.023-.379 8.986-.668-.491-.291-.975-.645-1.452-.993C18.633 4.681 17.702 4 17 4c-1.344 0-3.875 1.39-4.736 1.925a.5.5 0 11-.527-.849C11.873 4.99 15.098 3 17 3c1.028 0 2.046.744 3.124 1.531C21.112 5.253 22.134 6 23 6a.5.5 0 01.101.99C23.051 7 18.105 8 12 8z"></path>
              <path d="M12 5a2.503 2.503 0 01-2.5-2.5C9.5 1.121 10.622 0 12 0s2.5 1.121 2.5 2.5S13.378 5 12 5zm0-4c-.827 0-1.5.673-1.5 1.5S11.173 4 12 4s1.5-.673 1.5-1.5S12.827 1 12 1z"></path>
              <path d="M12 24a.5.5 0 01-.5-.5v-16a.5.5 0 011 0v16a.5.5 0 01-.5.5z"></path>
            </g>
            <path d="M14 20a.501.501 0 01-.281-.914c1.179-.799 1.791-1.526 1.722-2.048-.111-.841-1.921-1.467-2.893-1.804-.292-.101-.544-.188-.724-.266-.597-.191-4.817-1.612-5.275-3.639-.096-.424-.068-1.062.609-1.695.848-.791 2.257-.749 2.817-.158.312.329.392.885-.129 1.385-.337.323-.334.598-.302.776.146.828 1.49 1.891 2.652 2.402l.01.005c.163.07.396.15.669.245 1.34.464 3.366 1.164 3.558 2.617.126.957-.578 1.94-2.153 3.007A.492.492 0 0114 20zm-5.262-9.963c-.344 0-.675.12-.897.328-.359.336-.353.586-.316.744.095.42.576.895 1.26 1.352a2.305 2.305 0 01-.226-.648c-.076-.43-.035-1.069.595-1.674a.208.208 0 00.021-.021 1.013 1.013 0 00-.368-.079 1.544 1.544 0 00-.069-.002z"></path>
            <path d="M14 14a.5.5 0 01-.353-.853c1.06-1.061 1.184-1.617.509-2.293-.481-.48-.368-.932-.296-1.104.184-.444.741-.731 1.42-.731.607 0 1.185.229 1.583.628.719.719.743 1.391.637 1.827-.344 1.409-2.456 2.22-3.348 2.503A.54.54 0 0114 14zm.829-3.889l.034.035c.781.781.815 1.546.579 2.191.605-.354.999-.742 1.086-1.102.025-.102.1-.41-.372-.883-.213-.213-.532-.335-.876-.335a1.012 1.012 0 00-.451.094z"></path>
            <path d="M15 24a.5.5 0 01-.492-.592c.008-.057.205-1.583-2.651-2.429-2.818-.835-4.253-1.83-4.389-3.043-.111-.996.659-1.962 2.287-2.873a.5.5 0 01.489.873c-1.191.666-1.84 1.354-1.781 1.89.038.343.473 1.244 3.679 2.194 3.764 1.115 3.368 3.472 3.349 3.571A.5.5 0 0115 24z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Medical;