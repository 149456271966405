import React from "react";

function Bitcoin({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="2">
        <g fill="#303030" fillRule="nonzero" transform="translate(-362 -310)">
          <g transform="translate(362 310)">
            <path d="M12 23.5C5.659 23.5.5 18.341.5 12S5.659.5 12 .5 23.5 5.659 23.5 12 18.341 23.5 12 23.5zm0-22C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5z"></path>
            <path d="M12.5 17.5H9a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h3.5a2.998 2.998 0 011.656 5.5 2.998 2.998 0 01-1.656 5.5zm-3-1h3c1.103 0 2-.897 2-2s-.897-2-2-2h-3v4zm0-5h3c1.103 0 2-.897 2-2s-.897-2-2-2h-3v4z"></path>
            <path d="M10 7.5a.5.5 0 01-.5-.5V6a.5.5 0 011 0v1a.5.5 0 01-.5.5zM12 7.5a.5.5 0 01-.5-.5V6a.5.5 0 011 0v1a.5.5 0 01-.5.5zM10 18.5a.5.5 0 01-.5-.5v-1a.5.5 0 011 0v1a.5.5 0 01-.5.5zM12 18.5a.5.5 0 01-.5-.5v-1a.5.5 0 011 0v1a.5.5 0 01-.5.5z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Bitcoin;