import React from "react";

function Bin({width=19, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 19 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-242 -159)">
          <g transform="translate(242 159)">
            <path d="M14.2 4.8c1.323 0 2.4-1.077 2.4-2.4S15.523 0 14.2 0a2.403 2.403 0 00-2.4 2.4c0 1.323 1.077 2.4 2.4 2.4zm0-4c.882 0 1.6.718 1.6 1.6 0 .882-.718 1.6-1.6 1.6-.882 0-1.6-.718-1.6-1.6 0-.882.718-1.6 1.6-1.6zM5.071 19.029a.402.402 0 00.289.17l.04.001c.102 0 .202-.04.277-.111l2-1.92a.4.4 0 00.051-.517l-2-2.88a.402.402 0 00-.606-.06l-2 1.92a.4.4 0 00-.05.517l2 2.88zm.268-4.416l1.539 2.214-1.416 1.36-1.539-2.215 1.416-1.36zM15.8 6.4h-3.348a3.6 3.6 0 00-2.71 1.23L8.018 9.6H6.2c-.662 0-1.2.538-1.2 1.2 0 .662.538 1.2 1.2 1.2H9a.397.397 0 00.233-.074l2.167-1.548V22.4c0 .882.718 1.6 1.6 1.6.478 0 .906-.21 1.2-.543.294.333.722.543 1.2.543.882 0 1.6-.718 1.6-1.6v-6.468a1.203 1.203 0 001.6-1.132V9.2c0-1.544-1.256-2.8-2.8-2.8zm2 8.4a.4.4 0 01-.8 0V9.6a.4.4 0 00-.8 0v12.8c0 .44-.36.8-.8.8-.44 0-.8-.36-.8-.8v-6a.4.4 0 10-.8 0v6c0 .44-.358.8-.8.8-.44 0-.8-.36-.8-.8V9.6a.398.398 0 00-.216-.356.396.396 0 00-.416.03L8.872 11.2H6.2a.4.4 0 010-.8h2c.115 0 .225-.05.3-.137l1.845-2.107a2.802 2.802 0 012.107-.956H15.8c1.102 0 2 .898 2 2v5.6zm-8.702.412a.397.397 0 00-.485.291L6.688 23.2H3.313l-1.925-7.697a.4.4 0 10-.776.194l2 8A.4.4 0 003 24h4a.402.402 0 00.389-.303l2-8a.4.4 0 00-.291-.485z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Bin;