import React from "react";

function Clock({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23 23"
    >
      <path
        fill="#ffffff"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        d="M11.5 23C5.149 23 0 17.851 0 11.5S5.149 0 11.5 0 23 5.149 23 11.5 17.851 23 11.5 23zm0-21.083a9.583 9.583 0 100 19.166 9.583 9.583 0 000-19.166zm2.137 13.129l-2.808-2.817a.966.966 0 01-.287-.741v-4.78a.958.958 0 111.916 0v4.343l2.588 2.587a.996.996 0 11-1.409 1.408z"
        transform="translate(-124 -2244) translate(23 1991) translate(19 97) translate(82 156)"
      ></path>
    </svg>
  );
}

export default Clock;