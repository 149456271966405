const adviceCost = (num) => {

    let cost = +num*0.02;

    if(cost<1000){
        return 1000
    } else {
      return cost
    }

  }

const chargeCalc = (wealth = 1000, gCharge= 0.0050, bCharge = 0.0200, gInvest = 1.05, bInvest = 1.00, year=10, interest=1.05) => {

    let gChargeTotal = +wealth;
    let bChargeTotal = +wealth;
    let bInvestTotal = +wealth;
    let gInvestTotal = +wealth;


    const bChargeReturn = interest - bCharge;
    const gChargeReturn = interest - gCharge;
    const gInvestReturn = gInvest - gCharge;
    const bInvestReturn = bInvest - gCharge;

    const gInvestOne = gInvestTotal * gInvestReturn;
    const bInvestOne = bInvestTotal * bInvestReturn;

    const adviserCost = adviceCost(+wealth)


    for(let i =0; i<year; i++){
        gChargeTotal = gChargeTotal*gChargeReturn;
        bChargeTotal = bChargeTotal*bChargeReturn;

        gInvestTotal = gInvestTotal*gInvestReturn
        bInvestTotal = bInvestTotal*bInvestReturn


    }

    let chargeDifference = gChargeTotal-bChargeTotal;
    let investDifference = gInvestTotal-bInvestTotal;


    chargeDifference = Math.round(chargeDifference);
    investDifference = Math.round(investDifference);


    let returnObject ={}

    returnObject.badCharge = Math.round(bChargeTotal *100)/100
    returnObject.goodCharge = Math.round(gChargeTotal *100)/100
    returnObject.chargeDifference = chargeDifference ;
    returnObject.goodInvest = Math.round(gInvestOne);
    returnObject.badInvest = Math.round(bInvestOne);
    returnObject.investDifference = investDifference ;
    returnObject.adviserCost = Math.round(adviserCost);



    return returnObject;

}


export default chargeCalc;