import React from "react";

function Meeting({width=24, height=16}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 16"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-213 -312)"
      >
        <rect
          width="286"
          height="390"
          x="69"
          y="149"
          stroke="#E8E3E3"
          strokeWidth="2"
          rx="4"
        ></rect>
        <g fill="#303030" fillRule="nonzero">
          <path
            d="M12 15.5c-.614 0-1.407-.34-1.775-.582-.446-.293-2.684-1.976-4.546-3.535-.31-.26-.771-.742-1.217-1.208-.331-.345-.631-.662-.786-.793a.5.5 0 01.646-.764c.17.144.501.487.863.865.408.426.87.908 1.137 1.133 1.901 1.592 4.102 3.234 4.453 3.465.293.194.892.419 1.225.419.468 0 .511-.12.53-.17a.5.5 0 01.808-.199c.199.182.421.147.574.089a.95.95 0 00.59-.766.5.5 0 01.839-.319c.125.116.459.144.779-.018.126-.063.417-.245.383-.563a.5.5 0 01.781-.465c.147.103.418.049.616-.121a.586.586 0 00.149-.751c-.375-.78-2.042-2.128-3.512-3.317-.813-.657-1.585-1.282-2.157-1.819a9.835 9.835 0 00-.35.15c-.26.117-.433.189-.751.238-.77 1.022-1.804 1.38-2.937 1.006-.452-.149-.756-.469-.859-.901-.24-1.015.689-2.399 1.373-3.083 1.797-1.798 3.055-2.473 5.348-1.448 1.968.881 3.94 1.57 3.96 1.577a.501.501 0 01-.329.945c-.02-.007-2.032-.71-4.04-1.608-1.77-.791-2.608-.384-4.233 1.242-.683.683-1.21 1.705-1.105 2.144.015.064.043.131.199.183.489.164 1.221.254 1.928-.803a.5.5 0 01.366-.219c.337-.034.421-.072.671-.184.164-.073.372-.167.709-.289a.5.5 0 01.523.117c.547.547 1.405 1.241 2.313 1.975 1.628 1.317 3.313 2.68 3.784 3.662.332.689.174 1.453-.4 1.944a1.635 1.635 0 01-1.147.411c-.144.361-.434.67-.835.872-.41.206-.861.265-1.255.181a1.97 1.97 0 01-1.041.961c-.385.15-.785.149-1.137.012-.263.221-.642.334-1.135.334z"
            transform="translate(213 312)"
          ></path>
          <path
            d="M10 3.5H5.5a.5.5 0 010-1H10a.5.5 0 010 1zM18.539 11.482a.5.5 0 01-.28-.915l1.462-.982a.5.5 0 01.558.83l-1.462.982a.496.496 0 01-.278.085zM17 13a.498.498 0 01-.309-.107c-.527-.416-2.794-2.779-3.051-3.047a.5.5 0 11.722-.692c.99 1.032 2.591 2.671 2.949 2.953A.5.5 0 0117 13zM15 14a.502.502 0 01-.312-.109c-.742-.594-2.538-2.533-2.556-2.552a.5.5 0 01.737-.677c.34.37 1.845 1.969 2.444 2.448A.501.501 0 0115 14zM13 15a.499.499 0 01-.322-.118c-.737-.622-2.133-2.102-2.543-2.541a.5.5 0 01.731-.683c.285.305 1.744 1.858 2.457 2.459A.5.5 0 0113 15z"
            transform="translate(213 312)"
          ></path>
          <path
            d="M3.5 10.5h-3A.5.5 0 010 10V1A.5.5 0 01.53.501c1.03.061 4.457.324 5.314 1.136a.498.498 0 01.154.406c-.227 2.6-1.95 7.888-2.023 8.112a.5.5 0 01-.475.345zM1 9.5h2.135c.372-1.174 1.571-5.065 1.836-7.255-.63-.306-2.364-.582-3.971-.709V9.5zM23.5 11.5h-3a.5.5 0 01-.482-.367c-.372-1.348-2.307-6.874-2.925-7.869a.498.498 0 01.212-.717C17.396 2.505 19.574 1.5 23.5 1.5a.5.5 0 01.5.5v9a.5.5 0 01-.5.5zm-2.626-1H23V2.506c-2.379.056-4.018.501-4.781.76C19 4.93 20.4 8.956 20.874 10.5z"
            transform="translate(213 312)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Meeting;