import React from "react";

function Book({width=24, height=18}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 18"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-94 -54)">
          <g transform="translate(94 54)">
            <path d="M23.122 2.514L21.5 2.108V2a.5.5 0 00-.146-.354C20.56.853 17.521.5 16.5.5c-.792 0-3.266.077-4.51.891C10.88.778 8.403.5 7.5.5c-.87 0-3.79.083-4.854 1.146A.504.504 0 002.5 2v.108l-1.622.406a.5.5 0 00-.379.485v14a.5.5 0 00.556.498l8.601-.956c.346.571 1.24.958 2.344.958 1.104 0 1.997-.386 2.344-.958l8.601.956a.493.493 0 00.389-.125.502.502 0 00.167-.373v-14a.5.5 0 00-.379-.485zM9.5 15.552l-8.001.889V3.39L2.5 3.139V15a.5.5 0 00.854.354c.586-.587 2.56-.854 4.146-.854.554 0 1.294.081 2 .201v.851zm-2-2.052c-.691 0-2.68.052-4 .608V2.232c.72-.502 2.527-.732 4-.732 1.216 0 3.336.376 4 .749v11.918c-1.233-.458-3.214-.667-4-.667zm6 2.501c0 .125-.532.5-1.5.5s-1.5-.375-1.5-.5v-1.096c.557.139.994.297 1.146.45a.508.508 0 00.162.107l.001.001A.523.523 0 0012 15.5c.021 0 .041-.01.062-.012a.51.51 0 00.068-.013c.02-.005.041-.004.061-.012.03-.012.046-.042.073-.06.029-.019.065-.023.09-.048.219-.219.632-.394 1.146-.527v1.173c0-.001 0-.001 0 0zm-1-1.893V2.232c.72-.502 2.527-.732 4-.732 1.216 0 3.336.376 4 .749v11.918c-1.233-.458-3.214-.667-4-.667-.691 0-2.68.052-4 .608zm10.001 2.333l-8.001-.889v-.914a14.886 14.886 0 012-.138c1.33 0 3.743.45 4.146.854a.504.504 0 00.545.108A.5.5 0 0021.5 15V3.139l1.001.251v13.051z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Book;