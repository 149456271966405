import React from "react";

function UpArrow({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill="#303030"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M5.191 13.039a.5.5 0 00-.545.108L3 14.793l-1.646-1.647A.501.501 0 00.5 13.5v10a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-10a.498.498 0 00-.309-.461zM4.5 23h-3v-8.293l1.147 1.146a.5.5 0 00.707 0L4.5 14.707V23zM22.354 5.146A.504.504 0 0022 5h-1a.504.504 0 00-.354.146l-2 2a.504.504 0 00-.146.354v16a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-17a.504.504 0 00-.146-.354l-1-1zM22.5 23h-3V7.707L21.207 6h.586l.707.707V23zM23 0h-3a.501.501 0 00-.354.854L20.792 2 17 5.793l-1.647-1.646a.5.5 0 00-.707 0L11 7.793 9.353 6.147a.5.5 0 00-.707 0L3 11.793l-1.647-1.646a.5.5 0 00-.707.707l2 2a.502.502 0 00.708-.001L9 7.207l1.647 1.646a.5.5 0 00.707 0L15 5.207l1.646 1.647a.5.5 0 00.707 0L21.5 2.708l1.146 1.146a.504.504 0 00.545.108.5.5 0 00.309-.462v-3A.5.5 0 0023 0zm-.5 2.293L21.207 1H22.5v1.293zm-7.147 4.853a.5.5 0 00-.707 0l-2 2a.504.504 0 00-.146.354v14a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-14a.5.5 0 00-.147-.354l-2-2zM16.5 23h-3V9.707l1.5-1.5 1.5 1.5V23zM9.353 9.146a.5.5 0 00-.707 0l-2 2a.504.504 0 00-.146.354v12a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-12a.5.5 0 00-.147-.354l-2-2zM10.5 23h-3V11.707l1.5-1.5 1.5 1.5V23z"
        transform="translate(-244 -521) translate(244 521)"
      ></path>
    </svg>
  );
}

export default UpArrow;