import React from "react";

function GiftInc({width=24, height=22}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 22"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-164 -279)">
          <g transform="translate(164 279)">
            <path d="M3.5 20.036h-3a.5.5 0 01-.5-.5v-9a.5.5 0 01.529-.499c1.03.062 4.457.324 5.314 1.136a.5.5 0 01.154.407c-.227 2.6-1.949 7.888-2.022 8.111a.5.5 0 01-.475.345zm-2.5-1h2.135c.372-1.175 1.571-5.065 1.837-7.255-.631-.305-2.366-.582-3.972-.709v7.964z"></path>
            <path d="M13.396 22.005c-.652 0-1.232-.184-2.072-.501l-7.481-2.493a.501.501 0 01.317-.949l7.5 2.5c1.803.683 1.948.734 6.152-.988l4.957-2.253a2.265 2.265 0 00-2.315-.757l-3.831.958a.504.504 0 01-.606-.364.5.5 0 01.364-.606l3.831-.958a3.27 3.27 0 013.737 1.72.502.502 0 01-.241.679l-5.5 2.5c-2.611 1.069-3.799 1.512-4.812 1.512z"></path>
            <path d="M14.426 17.961c-.23 0-.46-.022-.685-.067l-4.339-.867a.5.5 0 01.195-.981l4.339.867c.361.072.739.063 1.097-.026l.96-.239c-.061-.913-.507-1.351-1.614-1.626l-8.987-1.997a.501.501 0 01.217-.977l9 2c1.658.413 2.391 1.285 2.391 2.83v.158a.5.5 0 01-.379.485l-1.346.336a3.512 3.512 0 01-.849.104z"></path>
            <path d="M22.5 16.5a.5.5 0 01-.5-.5V7H9v6a.5.5 0 01-1 0V6.5a.5.5 0 01.5-.5h14a.5.5 0 01.5.5V16a.5.5 0 01-.5.5z"></path>
            <path d="M23.5 7h-16a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v3a.5.5 0 01-.5.5zM8 6h15V4H8v2z"></path>
            <path d="M15.5 15a.5.5 0 01-.5-.5v-11a.5.5 0 011 0v11a.5.5 0 01-.5.5z"></path>
            <path d="M15.5 4a.5.5 0 01-.416-.778C15.172 3.091 17.258 0 19.5 0c.982 0 1.696.594 1.696 1.411 0 .698-.528 1.362-1.448 1.823C18.731 3.742 17.303 4 15.5 4zm4-3c-1.046 0-2.214 1.085-2.964 1.967 1.416-.097 2.272-.381 2.765-.627.661-.331.896-.707.896-.929C20.196 1.169 19.91 1 19.5 1z"></path>
            <path d="M15.5 4c-1.803 0-3.231-.258-4.248-.766-.92-.461-1.448-1.125-1.448-1.823C9.804.594 10.518 0 11.5 0c2.242 0 4.328 3.091 4.416 3.223A.499.499 0 0115.5 4zm-4-3c-.41 0-.696.169-.696.411 0 .222.234.598.896.929.492.246 1.349.53 2.763.627C13.711 2.085 12.542 1 11.5 1z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default GiftInc;