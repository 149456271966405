import React from "react";

function Unemployment({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g fill="#303030" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path
          d="M21.841 24c-.578 0-1.12-.225-1.528-.633l-6.761-8.031A8.514 8.514 0 018.502 17c-4.687 0-8.5-3.813-8.5-8.5 0-4.687 3.814-8.5 8.5-8.5s8.5 3.813 8.5 8.5a8.51 8.51 0 01-1.665 5.05l8.001 6.731c.874.873.874 2.244.031 3.086a2.148 2.148 0 01-1.528.633zm-8.218-9.856c.143 0 .283.061.383.178l7.043 8.369c.38.374 1.173.407 1.613-.032a1.165 1.165 0 000-1.643l-8.339-7.013a.501.501 0 01-.062-.703 7.509 7.509 0 001.74-4.801c0-4.136-3.364-7.5-7.5-7.5s-7.5 3.364-7.5 7.5 3.364 7.5 7.5 7.5a7.509 7.509 0 004.801-1.74.508.508 0 01.321-.115z"
          transform="translate(-28 -76) translate(28 76)"
        ></path>
        <path
          d="M4.502 11c-.827 0-1.5-.673-1.5-1.5a.5.5 0 011 0 .5.5 0 001 0v-3a.5.5 0 011 0v3c0 .827-.673 1.5-1.5 1.5zM8.502 11c-.827 0-1.5-.673-1.5-1.5v-2c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5v2c0 .827-.673 1.5-1.5 1.5zm0-4a.5.5 0 00-.5.5v2a.5.5 0 001 0v-2a.5.5 0 00-.5-.5zM12.502 11h-1a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1a1.502 1.502 0 011.117 2.5 1.502 1.502 0 01-1.117 2.5zm-.5-1h.5a.5.5 0 000-1h-.5v1zm0-2h.5a.5.5 0 000-1h-.5v1z"
          transform="translate(-28 -76) translate(28 76)"
        ></path>
      </g>
    </svg>
  );
}

export default Unemployment;