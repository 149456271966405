
import React from "react";

function Family({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g fill="#303030" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path
          d="M23.91 17.322l-2.182-5.465.422-2.261c.126-3.851-.138-4.041-.512-4.188a.598.598 0 00-.083-.033c-.347-.103-.765-.191-1.003.067l-.997 1.078-.998-1.078c-.24-.258-.659-.169-1.003-.067a.505.505 0 00-.081.032c-.373.146-.638.333-.504 4.265l.413 2.186-2.182 5.464a.502.502 0 00.465.686h2.377v5.394a.5.5 0 001 0v-5.894a.5.5 0 00-.5-.5h-2.139l1.962-4.915a.498.498 0 00.026-.278l-.431-2.252c-.07-2.148-.003-2.951.047-3.244l1.182 1.276a.517.517 0 00.734 0l1.182-1.277c.05.29.119 1.08.055 3.168l-.439 2.329a.498.498 0 00.026.278l1.961 4.915h-2.139a.5.5 0 00-.5.5v5.894a.5.5 0 001 0v-5.394h2.377a.499.499 0 00.464-.686zM4.493 15.035a.5.5 0 00-.5.5v7.947a.5.5 0 001 0v-7.947a.5.5 0 00-.5-.5zM4.493 4.557c1.165 0 1.938-1.446 1.938-2.403A1.94 1.94 0 004.493.216a1.94 1.94 0 00-1.938 1.938c.001.957.774 2.403 1.938 2.403zm0-3.34c.517 0 .938.42.938.938 0 .58-.485 1.403-.938 1.403-.453 0-.937-.824-.937-1.404 0-.517.42-.937.937-.937zM11.505 15.428c1.03 0 1.688-1.228 1.688-2.072a1.69 1.69 0 00-1.688-1.688 1.69 1.69 0 00-1.688 1.688c0 .845.658 2.072 1.688 2.072zm0-2.76a.69.69 0 01.688.688c0 .461-.392 1.072-.688 1.072-.296 0-.688-.611-.688-1.072a.69.69 0 01.688-.688zM19.556 4.775c1.121 0 1.864-1.446 1.864-2.403 0-1.068-.836-1.938-1.864-1.938s-1.866.869-1.866 1.938c0 .956.745 2.403 1.866 2.403zm0-3.341c.477 0 .864.42.864.938 0 .616-.484 1.403-.864 1.403s-.866-.787-.866-1.403c0-.517.388-.938.866-.938z"
          transform="translate(-170 -961) translate(170 961)"
        ></path>
        <path
          d="M7.524 5.247a.489.489 0 00-.187-.099c-.094-.027-.207-.035-.752-.057l-.283-.013c-.446-.03-.87.22-1.093.652-.225.434-1.207.435-1.431 0-.222-.431-.636-.676-1.093-.653l-.295.014c-.536.022-.647.03-.741.057a.514.514 0 00-.185.097c-.213.177-.86.718-.578 8.253a.627.627 0 00.192.405c.157.136.339.159.511.152l.545 9.454a.492.492 0 00.528.471.5.5 0 00.47-.527l-.568-9.859a.64.64 0 00-.195-.408c-.153-.132-.33-.155-.499-.154-.174-5.134.115-6.585.208-6.927l.353-.015.317-.015c.049-.01.112.058.141.112.286.555.901.9 1.604.9.704 0 1.318-.345 1.604-.9.028-.054.068-.116.141-.112l.306.015.342.013c.13.426.399 1.936.23 6.929-.17 0-.346.022-.499.154a.65.65 0 00-.195.4l-.569 9.867a.498.498 0 00.47.527l.029.001a.5.5 0 00.499-.472l.545-9.454c.175.009.354-.016.511-.152a.63.63 0 00.192-.404c.283-7.53-.363-8.073-.575-8.252zM11.505 16.209c-1.293 0-2.346 1.31-2.346 2.919 0 1.112.731 4.777 2.346 4.777 1.614 0 2.345-3.665 2.345-4.777.001-1.61-1.052-2.919-2.345-2.919zm0 6.696c-.452 0-1.346-2.296-1.346-3.777 0-1.04.616-1.919 1.346-1.919.729 0 1.345.879 1.345 1.919.001 1.481-.893 3.777-1.345 3.777z"
          transform="translate(-170 -961) translate(170 961)"
        ></path>
      </g>
    </svg>
  );
}

export default Family;