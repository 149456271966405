import React from "react";

function Gold({width=24, height=18}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 18"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-441 -310)">
          <g transform="translate(441 310)">
            <g>
              <path d="M1.5 13h-1a.5.5 0 01-.481-.637l1.585-5.549A2.513 2.513 0 014.009 5h2.982c1.11 0 2.099.746 2.404 1.813a.501.501 0 01-.962.276A1.505 1.505 0 006.991 6H4.009c-.666 0-1.259.447-1.443 1.088L1.163 12H1.5a.5.5 0 010 1z"></path>
              <path d="M.501 13a.5.5 0 01-.482-.637l1.726-6.041a2.514 2.514 0 011.507-1.647L14.627.3a4.49 4.49 0 011.616-.3h2.749c1.11 0 2.099.746 2.404 1.813l.585 2.05a.5.5 0 11-.962.274l-.585-2.049A1.507 1.507 0 0018.991 1h-2.749a3.51 3.51 0 00-1.257.233L3.611 5.609a1.506 1.506 0 00-.904.987L.981 12.637a.5.5 0 01-.48.363z"></path>
              <path d="M10.5 6a.5.5 0 01-.176-.968l8-3a.5.5 0 01.352.936l-8 3A.493.493 0 0110.5 6z"></path>
            </g>
            <g transform="translate(1 5)">
              <path d="M10.52 13H.5a.5.5 0 01-.481-.637l1.585-5.549A2.513 2.513 0 014.009 5h2.982c1.11 0 2.099.746 2.404 1.813l1.549 5.423a.5.5 0 01-.424.764zm-9.357-1h8.674L8.434 7.088A1.507 1.507 0 006.991 6H4.009c-.666 0-1.259.447-1.442 1.088L1.163 12z"></path>
              <path d="M10.5 13a.5.5 0 01-.192-.962l11.589-4.829-1.463-5.122A1.507 1.507 0 0018.991 1h-2.749a3.51 3.51 0 00-1.257.233L3.611 5.609a1.506 1.506 0 00-.904.987L.981 12.637a.5.5 0 01-.962-.274l1.726-6.041a2.507 2.507 0 011.507-1.646L14.627.3a4.49 4.49 0 011.616-.3h2.749c1.11 0 2.099.746 2.404 1.813l1.585 5.55a.501.501 0 01-.289.599l-12 5A.498.498 0 0110.5 13z"></path>
              <path d="M10.5 6a.5.5 0 01-.176-.968l8-3a.5.5 0 01.352.936l-8 3A.493.493 0 0110.5 6z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Gold;