import React from "react";

function Diversify({width=24, height=23}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 23"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-524 -305)">
          <path
            d="M12 16.8A4.805 4.805 0 017.2 12c0-2.647 2.153-4.8 4.8-4.8s4.8 2.153 4.8 4.8-2.153 4.8-4.8 4.8zm5.6-4.8c0-3.088-2.512-5.6-5.6-5.6A5.606 5.606 0 006.4 12c0 3.088 2.512 5.6 5.6 5.6 3.088 0 5.6-2.512 5.6-5.6zm.8 9.6a.8.8 0 11.002-1.602.8.8 0 01-.002 1.602zm0-2.4c-.223 0-.435.046-.628.13l-1.755-2.098a.4.4 0 10-.613.513l1.738 2.078c-.211.271-.342.607-.342.977 0 .882.718 1.6 1.6 1.6.882 0 1.6-.718 1.6-1.6 0-.882-.718-1.6-1.6-1.6zM5.6 21.6a.8.8 0 11.002-1.602A.8.8 0 015.6 21.6zm2.383-4.368l-1.755 2.097A1.602 1.602 0 004 20.8c0 .883.718 1.601 1.6 1.601.882 0 1.6-.718 1.6-1.6 0-.37-.131-.706-.342-.977l1.738-2.078a.4.4 0 00-.613-.513zM22.4 9.6a.8.8 0 11.002-1.602A.8.8 0 0122.4 9.6zm0-2.4c-.882 0-1.6.718-1.6 1.6 0 .05.01.099.015.149l-2.553 1.004a.4.4 0 00.293.744l2.527-.993A1.598 1.598 0 0024 8.8c0-.882-.718-1.6-1.6-1.6zM1.6 9.6a.8.8 0 11.002-1.602A.8.8 0 011.6 9.6zm3.846 1.097a.4.4 0 10.292-.745L3.185 8.95c.005-.05.015-.098.015-.149 0-.882-.718-1.6-1.6-1.6-.882 0-1.6.718-1.6 1.6a1.598 1.598 0 002.918.904l2.528.993zM12 .8a.8.8 0 11-.002 1.602A.8.8 0 0112 .8zm-.4 2.343V5.2a.4.4 0 00.8 0V3.143c.688-.179 1.2-.8 1.2-1.543C13.6.718 12.882 0 12 0c-.882 0-1.6.718-1.6 1.6 0 .743.512 1.364 1.2 1.543z"
            transform="translate(524 305)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Diversify;