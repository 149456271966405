import React from 'react';
// import ReactGA from "react-ga";

import ExpandableCard from '../../components/expandableCard/expandableCard'

import Person from '../../icons/person';
import Income from '../../icons/income';
import UpArrow from '../../icons/upArrow';


import Cost from '../../icons/cost';
import IconHead from '../../components/iconHead/iconHead';
import CardHeader from '../../components/cardHeader/cardHeader';
import Bitcoin from '../../icons/bitcoin';
import Shoe from '../../icons/shoe';
import Gold from '../../icons/gold';
import House from '../../icons/house';

const WealthOpportunity = () => {

        const wealthCat = [
            {title: 'Earning', wording: 'You earn by providing services. Services come in two forms.   The first is where you lend your time and skills (e.g. employment).  The second is where you lend what you own (e.g. a rental property).  '},
            {title: 'Investing', wording: 'Investing allows you to grow what you have. Generally this requires you to buy assets that you sell at a later point for a profit .'}
        
        ]

        const wealthOptions = [
            {title: 'You', wording: 'Your time, skills and even your brand hold value to individuals and companies. By improving your knowledge, experience and network, your services become more valued.'},
            {title: 'Stock Market', wording: 'Stocks and shares allow you to earn from the work others. Investing in stock market provides earnings in the form of dividends. If the company grows, the value of your investment goes up too.'},
            {title: 'Property', wording: '7.8 Billion people live in the world and 68 Million of these live in the UK. They all need space to live, sleep and work. Owning property will grow your wealth if the demand for property increases. Renting provides earnings. As Mark Twain once said "Buy land, they are not making it anymore".'},
            {title: 'Commodities', wording: 'Items and materials that you trade and exchange are commodities. Gold, oil and other raw materials are all common examples.  Buying commodities at low prices before demand increases and prices rise, creates wealth.'},
            {title: 'Currencies', wording: 'Currencies such as the pound and dollar fluctuate against each other. Forex trading allows you to take advantage of the swings in these fluctuations to make money. '},
            {title: 'Alternatives', wording: 'Luxury art, cars and even limited edition trainers are alternative investments. If there is market for something, there is an opportunity to make money from buying low and selling high.'},
            {title: 'Crypto', wording: 'Cryptocurrencies, such as Bitcoin and Ethereum, are digital commodities. With limited supply, perceived value and demand, there is opportunity. While potentially lucrative, Crypto is high risk and volatile.'},
        ]


        return (


            <div>

            <ExpandableCard title='Options'>

                <div className='expandLeftSide Opacsixty'>
                    <p className=''>2</p>
                </div>


                <div>
                        <CardHeader title='You create wealth by' primary='yes'/>
                
                        <div className='standardPadding'>
                        <IconHead title={wealthCat[0].title} wording={wealthCat[0].wording}>
                            <Income width='50' height='48'/>

                        </IconHead>

                        <IconHead title={wealthCat[1].title} wording={wealthCat[1].wording } nounderline='true'>
                            <UpArrow width='50' height='48'/>

                        </IconHead>

                        </div>

                        <CardHeader title='Your options include'/>


                        <div className='standardPadding'>

                        <IconHead title={wealthOptions[0].title} wording={wealthOptions[0].wording}>
                            <Person width='50' height='48'/>

                        </IconHead>

                        <IconHead title={wealthOptions[1].title} wording={wealthOptions[1].wording}>
                            <UpArrow width='50' height='48'/>

                        </IconHead>

                        <IconHead title={wealthOptions[2].title} wording={wealthOptions[2].wording}>
                            <House width='50' height='48'/>
                            

                        </IconHead>

                        <IconHead title={wealthOptions[3].title} wording={wealthOptions[3].wording}>
                             <Gold width='50' height='48'/>
                            

                        </IconHead>

                        <IconHead title={wealthOptions[4].title} wording={wealthOptions[4].wording}>
                            <Cost width='50' height='48'/>

                        </IconHead>

                        <IconHead title={wealthOptions[5].title} wording={wealthOptions[5].wording}>
                            <Shoe width='50' height='48'/>
                        </IconHead>

                        <IconHead title={wealthOptions[6].title} wording={wealthOptions[6].wording} nounderline='true'>
                            <Bitcoin width='50' height='48'/>

                        </IconHead>



                    </div>
                </div>

            </ExpandableCard>
        </div>

                
        )



    }

    export default WealthOpportunity;