import React from "react";

function ThumbDown({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 23"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-167 -386)"
      >
        <rect
          width="286"
          height="390"
          x="69"
          y="149"
          stroke="#E8E3E3"
          strokeWidth="2"
          rx="4"
        ></rect>
        <g fill="#303030" fillRule="nonzero">
          <path
            d="M13 23c-1.038 0-1.781-.585-1.813-.609A.502.502 0 0111 22v-3.391l-2.881-6.24-.119-.06v.191a.5.5 0 01-.5.5h-5A2.502 2.502 0 010 10.5v-8C0 1.121 1.121 0 2.5 0h3c1.081 0 2.005.69 2.353 1.653C8.685 1.225 9.805 1 10.5 1h9.179c1.089 0 2.042.734 2.266 1.747a2.255 2.255 0 01-.18 1.502 2.24 2.24 0 011.001 3A2.24 2.24 0 0124 9.25c0 .601-.239 1.161-.65 1.574.467.511.705 1.196.64 1.909C23.872 14.004 22.727 15 21.381 15h-6.177c.306.929.796 2.632.796 4 0 2.169-1.843 4-3 4zm-1-1.271c.207.114.569.271 1 .271.548 0 2-1.362 2-3 0-1.76-.958-4.297-.967-4.322A.5.5 0 0114.5 14h6.881c.834 0 1.542-.597 1.612-1.358a1.494 1.494 0 00-.712-1.415.502.502 0 01.034-.871A1.24 1.24 0 0023 9.25c0-.631-.477-1.161-1.108-1.233a.5.5 0 01-.286-.861A1.24 1.24 0 0022 6.25c0-.631-.477-1.161-1.108-1.233a.5.5 0 01-.286-.861c.329-.311.461-.745.362-1.192-.123-.56-.665-.964-1.289-.964H10.5c-.812 0-2.174.381-2.646.854A.5.5 0 017 2.5C7 1.673 6.327 1 5.5 1h-3C1.673 1 1 1.673 1 2.5v8c0 .827.673 1.5 1.5 1.5H7v-.5c0-.173.09-.334.237-.426a.503.503 0 01.486-.021l1 .5a.499.499 0 01.23.237l3 6.5a.495.495 0 01.047.21v3.229z"
            transform="translate(167 386)"
          ></path>
          <path
            d="M7.5 12a.5.5 0 01-.5-.5v-9a.5.5 0 011 0v9a.5.5 0 01-.5.5z"
            transform="translate(167 386)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ThumbDown;