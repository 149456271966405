import React from "react";

function ActiveFund({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-610 -328)">
          <g transform="translate(610 328)">
            <path d="M23.853 23.147l-6.449-6.449A9.945 9.945 0 0020 10c0-5.514-4.486-10-10-10S0 4.486 0 10s4.486 10 10 10a9.948 9.948 0 006.697-2.595l6.449 6.449a.502.502 0 00.707 0 .5.5 0 000-.707zM10 19c-4.963 0-9-4.038-9-9s4.037-9 9-9 9 4.038 9 9-4.037 9-9 9zm1.5-11h-3a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5zm-.5 6H9V9h2v5zm-4.5-3h-3a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zM6 14H4v-2h2v2zm10.5-9h-3a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5zm-.5 9h-2V6h2v8z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ActiveFund;