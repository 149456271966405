import React from 'react';

const IconSection = (props) => {
        
        return (
            <div className='resultSummary'>
                <div className='fteen flexWrap'>
                    {props.children[0]}
                </div>

                <div className='eighty'>
                    {props.children[1]}
                </div>
            </div>
            
        )
}


export default IconSection;



                                        