import React from "react";

function Sunshade({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-241 -209)">
          <g transform="translate(241 209)">
            <path d="M8.602 6.816l.002.002 4.501 3.135-6.86 9.274a.499.499 0 10.803.595l6.878-9.297 4.525 3.152h.001l3.281 2.286a.496.496 0 00.695-.125c1.457-2.09 1.922-4.681 1.311-7.295-.605-2.592-2.201-4.899-4.49-6.496l-.006-.006-.008-.003C14.487-1.267 8.19-.462 5.197 3.835a.502.502 0 00.125.696l3.28 2.285zm12.716-1.302a9.61 9.61 0 011.448 3.257c.505 2.161.19 4.3-.885 6.077l-2.463-1.716c1.256-1.945 1.973-4.086 2.013-6.082a8.007 8.007 0 00-.113-1.536zm-3.079-2.906l-2.55 3.661a.5.5 0 00.821.571l2.55-3.661c.921.855 1.406 2.2 1.372 3.851-.037 1.804-.683 3.753-1.828 5.535l-9.01-6.277c1.276-1.692 2.88-2.973 4.559-3.632 1.536-.605 2.965-.616 4.086-.048zm-2.991-1.305a7.95 7.95 0 00-1.462.422c-1.859.73-3.618 2.144-5.008 3.996L6.317 4.006c2.061-2.582 5.567-3.544 8.931-2.703zm7.974 15.779l-5.874 3.916H2.499a.5.5 0 00-.354.147l-2 2a.5.5 0 00.707.707l1.854-1.854h14.585l1.854 1.854a.502.502 0 00.707 0 .5.5 0 000-.707l-1.569-1.569 5.493-3.662a.5.5 0 10-.554-.832z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Sunshade;