import React from 'react';
// import ReactGA from "react-ga";

import ExpandableCard from '../../components/expandableCard/expandableCard'
import ActionCard from '../../components/actionCard/actionCard';
import CardHeader from '../../components/cardHeader/cardHeader';
const ProtectDeath = () => {


    // https://www.gov.uk/inheritance-tax

    const inhertitanceTax = [

        {
        title: 'IHT Threshold',
        description: 'Inheritance Tax is only a liable for assets in excess of your threshold. Your threshold is currently £325,000 but this can increase up to £650,000 if you include a deceased spouses allowance.  There is also a main residency allowance that can be added if your main residency is passed to your children/ grandchildren.',
        pros: ['If your estate value is lower than your threshold, you do not have to worry about inheritance tax planning'],
        cons: ['If your estate value is higher than your threshold, you will have an IHT liability and should explore other strategies'],
        steps: ['Work out your IHT threshold and whether you need to undertake IHT planning'],
        icon: 'Threshold',
        },
        {
        title: 'Gifts',
        description: 'Within a given year, you can give gifts up to a value of £3,000 without any IHT liability',
        pros: ['It is a free IHT allowance.'],
        cons: ['You may not want to gift your money before you die.'],
        warnings: ['Any gifts you give above this level will count as part of your estate for the next 7 years.  Although these gifts will have a tapered level of IHT tax.  The actual level depends on when the gift was made within the last 7 years.'],
        steps: ['Identify if you want to give gifts as part of an IHT strategy.'],
        icon: 'Gift',
        },
        {
            title: 'Gifts from Surplus Income',
            description: 'You can give unlimited gifts from any surplus annual income you receive without those gifts counting towards estate',
            pros: ['If you have unused income, this strategy can be used to stop your IHT liability from increasing.'],
            cons: ['The approach does not help reduce your current IHT liability as the gifts need to come from future income.'],
            warnings: ['For these gift to count as exempt, they must be regular in nature e.g. paying into a savings plan, come from an income and not materially impact your standard of living.  If they do not fit this criteria, they will count as normal gifts and liable to IHT.'],
            steps: ['Work out your excess income and identify how that excess can be used to support your beneficiaries on a regular basis e.g. school fees, insurance premiums, saving plans for grandchildren.'],
            icon: 'GiftInc',
        },
        {
            title: 'Pensions',
            description: 'Any money left in your pension, is exempt from IHT.',
            pros: ['It provides an easy way for you to transfer wealth to your beneficiaries.'],
            cons: ['Your beneficiaries will have to pay income tax on funds accessed from your pension.'],
            warnings: ['There is an upper limit on the amount you can hold within a pension before a higher tax charge is liable.'],
            steps: ['Look to draw income from other sources of wealth (e.g. ISAs) before touching your pension.'],
            icon: 'Sunshade',
        },
         {
            title: 'Equity Release',
            description: 'You can look to use equity release/ a lifetime mortgage to access the capital within your property if your main residence is above your IHT threshold.',
            pros: ['Provides the opportunity to reduce your IHT liability of wealth tied up in property.'],
            cons: ['Money you release from a property will still form part of your estate. You will need to either spend or use other strategies to reduce your IHT liability.'],
            warnings: ['Equity Release has a reputation for offering poor terms.'],
            steps: ['Speak to an adviser on whether this strategy is worthwhile.'],
            icon: 'House',
        },
               {
        title: 'Life Insurance',
        description: 'You can use a Life Insurance policy to provide you with a lump sum to cover your IHT liability on death',
        pros: ['A simple way to ensure your beneficiaries receive the majority of your estate on your death'],
        cons: ['Life Insurance will involve paying relatively expensive premiums that will need to be lower than your IHT liability to be worthwhile.'],
        warnings: ['The policy will need to be set up under trust to ensure it does not form part of your estate on death.'],
        steps: ['Work out the level of cover required and search the market for the best policy.'],
        icon: 'LifeInsure',
    },
        
        {
        title: 'Inheritance Investment Schemes',
        description: 'Inheritance investment schemes invest in a variety of investments that benefit from Business Property Relief (BPR).  If these investments are held for longer than 2 years, they are exempt from IHT',
        pros: ['If you have a high attitude to risk, these investments provide a number of tax benefits with an investment upside.'],
        cons: ['These schemes are high risk as they invest in small enterprise schemes. Values can go down and even lost.'],
        warnings: ['The underlying investments of the schemes currently receive BPR, that may not always be the case.'],
        steps: ['Speak to an adviser'],
        icon: 'Family',
    },
                
    ]
        
        
        

        

        const lossofincome = [
            {
                title: 'Life Insurance',
                description: 'Life insurance provides your family with an income or lump sum on death',
                pros: [
                    'Your family do not have to worry about finances during a traumatic time.'
                ],
                cons:['You have a pay regular premiums to an insurer for a policy that may not be paid out.'],
                warnings: ['The policy will have terms, clauses and small print that will determine if money will be payed.'],
                steps:['Visit a comparison site and find the best insurance policy for you.'],
                icon: 'LifeInsure',
            },
            {
                title: 'Pensions',
                description: 'Your pension can be used by your dependants to withdraw an income on your death. Although your dependants will incur income tax on any withdrawals.',
                pros: [
                    'Payments into your pension can be used as a safety net for your family and used by you later in life when your dependants are no longer dependant.'
                ],
                cons:['Your pension may not provide the level of cover required to support your dependants.'],
                steps:['Look to pay more money into your pension and nominate your beneficiaries.'],
                icon: 'Sunshade',
            }
        ]



        return (

            <div>
            
                            <ExpandableCard title='Death'>

                                <div className='expandLeftSide'>
                                    <p className=''>5</p>
                                </div>
                                
                                <div>


                                    <CardHeader title='Inheritance Tax' primary='yes'/>

                                    <div className='standardPadding'>
                                    

                                        { inhertitanceTax.map((action) => {
                                            return <ActionCard key={action.title} option={action}/>
                                        }) }
                                    </div>

                                    <CardHeader title='Loss of Income'/>

                                    <div className='standardPadding'>

                                        { lossofincome.map((action) => {
                                            return <ActionCard option={action}/>
                                        }) }

                                    </div>

                                </div>


                            </ExpandableCard>
                        </div>  
                
        )



    }

    export default ProtectDeath;

    







