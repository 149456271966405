import React from "react";

function Calender({width=24, height=20}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-286 -151)">
          <g transform="translate(286 151)">
            <path d="M21 2h-1.5v-.5C19.5.673 18.827 0 18 0s-1.5.673-1.5 1.5V2h-9v-.5C7.5.673 6.827 0 6 0S4.5.673 4.5 1.5V2H3A2.503 2.503 0 00.5 4.5v15C.5 20.878 1.621 22 3 22h18c1.379 0 2.5-1.122 2.5-2.5v-15C23.5 3.122 22.379 2 21 2zm-3.5-.5a.5.5 0 011 0v2a.5.5 0 01-1 0v-2zm-12 0a.5.5 0 011 0v2a.5.5 0 01-1 0v-2zm17 18c0 .827-.673 1.5-1.5 1.5H3c-.827 0-1.5-.673-1.5-1.5V8h21v11.5zm0-12.5h-21V4.5C1.5 3.673 2.173 3 3 3h1.5v.5C4.5 4.327 5.173 5 6 5s1.5-.673 1.5-1.5V3h9v.5c0 .827.673 1.5 1.5 1.5s1.5-.673 1.5-1.5V3H21c.827 0 1.5.673 1.5 1.5V7z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Calender;