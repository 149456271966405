import React from "react";

function Theft({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g fill="#303030" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path
          d="M23.5 24H.5a.5.5 0 01-.5-.5v-.734a6.505 6.505 0 014.365-6.139l2.572-.895a.504.504 0 01.533.134C8.73 17.242 10.339 18 12 18s3.27-.758 4.529-2.134a.502.502 0 01.533-.134l2.572.895A6.502 6.502 0 0124 22.766v.734a.5.5 0 01-.5.5zM1 23h22v-.234a5.505 5.505 0 00-3.693-5.195l-2.269-.789C15.621 18.215 13.844 19 12 19c-1.844 0-3.621-.785-5.038-2.218l-2.269.789A5.504 5.504 0 001 22.766V23zM10.5 9h-1a.5.5 0 010-1h1a.5.5 0 010 1zM14.5 9h-1a.5.5 0 010-1h1a.5.5 0 010 1z"
          transform="translate(-379 -224) translate(379 224)"
        ></path>
        <path
          d="M12 17c-2.464 0-4.653-1.99-5.562-5.002C5.614 11.948 5 10.891 5 9.5c0-1.413.634-2.481 1.478-2.5h.006a.488.488 0 01.558.468.512.512 0 01-.564.536C6.337 8.058 6 8.583 6 9.5c0 .968.376 1.5.5 1.5a.535.535 0 01.467-.05c.147.06.272.176.312.332C7.979 14.104 9.876 16 12 16s4.021-1.896 4.722-4.718a.504.504 0 01.297-.343.496.496 0 01.452.039c.161.047.529-.512.529-1.478 0-.917-.337-1.442-.479-1.496l-.015.002a.5.5 0 01-.546-.556c.032-.274.286-.49.557-.45a.521.521 0 01.132.033C18.463 7.19 19 8.199 19 9.5c0 1.391-.614 2.448-1.438 2.498C16.653 15.01 14.464 17 12 17z"
          transform="translate(-379 -224) translate(379 224)"
        ></path>
        <path
          d="M17.5 8a.505.505 0 01-.196-.04C17.281 7.95 15.017 7 12 7c-3.017 0-5.281.95-5.304.96a.507.507 0 01-.212.04C5.888 7.981 5 7.566 5 6.5c0-.812.515-1.246 1.023-1.415C6.263 2.873 8.369 0 12 0c3.631 0 5.737 2.873 5.977 5.085C18.485 5.254 19 5.688 19 6.5c0 1.066-.888 1.481-1.484 1.5H17.5zM12 6c2.859 0 5.04.781 5.574.991.188-.031.426-.14.426-.491 0-.411-.326-.49-.521-.5A.506.506 0 0117 5.5C17 3.661 15.22 1 12 1 8.78 1 7 3.661 7 5.5a.5.5 0 01-.484.5c-.19.01-.516.089-.516.5 0 .349.236.459.427.49C6.962 6.78 9.143 6 12 6z"
          transform="translate(-379 -224) translate(379 224)"
        ></path>
        <path
          d="M8 5.5a.5.5 0 01-.127-.984C7.953 4.495 9.866 4 12 4a.5.5 0 010 1c-2 0-3.854.479-3.873.484A.508.508 0 018 5.5zM14.006 12c-1.255 0-1.675-.455-1.879-1h-.255c-.207.552-.629 1-1.872 1-2.608 0-4.155-1.763-4.49-3.4a.5.5 0 01.39-.59.505.505 0 01.59.39C6.745 9.652 7.953 11 10 11c.863 0 .902-.162 1.008-.594.055-.223.262-.406.492-.406h1c.23 0 .431.157.485.381.112.455.152.619 1.021.619 2.043 0 3.249-1.348 3.504-2.6a.505.505 0 01.59-.39.5.5 0 01.39.59c-.335 1.637-1.88 3.4-4.484 3.4z"
          transform="translate(-379 -224) translate(379 224)"
        ></path>
      </g>
    </svg>
  );
}

export default Theft;