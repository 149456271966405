import React from 'react';
import ReactGA from "react-ga";

import { withRouter } from "react-router-dom";

import ProtectTax from './ProtectTax';
import ProtectWealth from './ProtectWealth';
import ProtectRisks from './ProtectRisks';
import ProtectDeath from './ProtectDeath';
import ProtectMisfortune from './ProtectMisfortune';
import Aside from '../Aside/Aside';
import BannerHeader from '../../components/bannerHeader/bannerHeader';

const DIYWealthPres = (props) => {


    ReactGA.pageview('/ProtectWealth');



        return (

            <div>

                <div className='banner protect'>


                        <BannerHeader title="How to Protect Wealth" subHeader="5 considerations to securing your future"/> 

                        


                </div>

                <div className='container'>

                    <div className='content-area'>
                        
                        <ProtectRisks/>

                        <ProtectWealth/>

                        <ProtectMisfortune/>

                        <ProtectTax/>

                        <ProtectDeath/>

                    </div>

                    <Aside/>

                </div>

            </div>
        )



    }

    export default withRouter(DIYWealthPres);
