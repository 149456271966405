import React from "react";

function DownArrow({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path
        fill="#303030"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M23.191 9.038a.5.5 0 00-.545.108l-1.147 1.147-4.146-4.146a.5.5 0 00-.707 0L15 7.793l-3.647-3.646a.5.5 0 00-.707 0L9 5.793 3.354.147a.5.5 0 00-.707 0l-2 2a.5.5 0 00.707.707L3 1.207l5.647 5.647a.5.5 0 00.707 0L11 5.207l3.647 3.646a.5.5 0 00.707 0L17 7.207 20.792 11l-1.146 1.146A.503.503 0 0020 13h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.309-.462zM22.5 12h-1.293l.646-.646.647-.647V12zM3.353 3.146a.5.5 0 00-.707 0l-2 2A.504.504 0 00.5 5.5v18a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-18a.5.5 0 00-.147-.354l-2-2zM4.5 23h-3V5.707l1.5-1.5 1.5 1.5V23zm6.691-15.961a.497.497 0 00-.545.108L9 8.793 7.354 7.146A.501.501 0 006.5 7.5v16a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-16a.498.498 0 00-.309-.461zM10.5 23h-3V8.707l1.147 1.147a.5.5 0 00.707 0L10.5 8.707V23zm6.691-13.961a.5.5 0 00-.545.108L15 10.793l-1.646-1.647a.5.5 0 00-.854.354v14a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-14a.498.498 0 00-.309-.461zM16.5 23h-3V10.707l1.147 1.146a.5.5 0 00.707 0l1.146-1.146V23zm6.5-9h-4a.5.5 0 00-.5.5v9a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-9a.5.5 0 00-.5-.5zm-.5 9h-3v-8h3v8z"
        transform="translate(-250 -453) translate(250 453)"
      ></path>
    </svg>
  );
}

export default DownArrow;
