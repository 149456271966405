
import React from 'react';
// import ReactGA from "react-ga";

import ExpandableCard from '../../components/expandableCard/expandableCard'
import ActionCard from '../../components/actionCard/actionCard';
import CardHeader from '../../components/cardHeader/cardHeader';


const ProtectMisfortune = () => {

    // https://www.gov.uk/income-tax

    // https://www.gov.uk/capital-gains-tax 

    // https://www.gov.uk/individual-savings-accounts 

    const illHealth = [

        {
            title: 'Be Healthy',
            description: 'You already know that you need to eat well, do exercise and avoid base jumping (where possible)',
            pros: ['A better chance of a longer healthy life'],
            cons: ['Takes effort and commitment'],
            steps: ['Take small steps to improving your health rather than trying too much too soon. E.g. Replace a small bad habit with a small good habit.'],
            icon: 'Shoe',
        },
            {
                title: 'Private Health Insurance',
                description: 'Provides you with access to private healthcare in the event of ill health',
                pros: ['Quicker access to care and, relatively speaking, a more pleasurable healthcare experience.'],
                cons: ['Relatively expensive premiums that are a luxury while the NHS exists'],
                warnings: ['Terms apply meaning that some care will still have costs.'],
                steps:['Visit a comparison site and find the best insurance policy for you.'],
                icon: 'Medical',
                },
                {
                title: 'Critical Ill Health Insurance',
                description: 'Will help to provide you with income (or lump sum) in the event you are unable to work for a prolonged period',
                pros: [
                    'You will be able to sustain your lifestyle financially in the eventuality of being unable to work due to ill health.'
                ],
                cons:['You have a pay regular premiums to an insurer for a policy that hopefully will not be paid out.'],
                warnings: ['The policy will have terms, clauses and small print that will determine if money will be payed'],
                steps:['Visit a comparison site and find the best insurance policy for you.'],
                icon: 'Ambulance',
                },
        ]


        const unemployment = [

                    {
                    title: 'Lifestyle/ Income Protection Insurance:',
                    description: 'Provides with you with income in the eventuality of redundancy',
                    pros: [
                        'Gives you time to find a suitable job and/or retrain to find a better job.'
                    ],
                    cons:['Expensive and unavailable if you are self employed.'],
                    warnings: ['The policy will have terms, clauses and small print that will determine if money will be payed.'],
                    steps:['Visit a comparison site and find the best insurance policy for you.'],
                    icon: 'Income',
                    },
            ]


            const actsofGod = [

                {
                title: 'Home/Contents Insurance:',
                description: 'Partially covers the cost of repurchasing any lost items.',
                pros: [
                    'Makes misfortune less financially disastrous.'
                ],
                cons:['Will only partially cover the costs and you will still not be able to replace sentimental items.'],
                warnings: ['The policy will have terms, clauses and small print that will determine if money will be payed.'],
                steps:['Visit a comparison site and find the best insurance policy for you.'],
                icon: 'House',
                },
        ]


    




        return (

            <div>
            
                            <ExpandableCard title='Misfortune'>

                                <div className='expandLeftSide Opaceighty'>
                                    <p className=''>3</p>
                                </div>
                                
                                <div>

                                    <CardHeader title='Ill Health' primary='yes'/>

                                    <div className='standardPadding'>

                                        { illHealth.map((action) => {
                                        return <ActionCard option={action}/>
                                        }) }

                                    </div>


                                    <CardHeader title='Unemployment'/>

                                    <div className='standardPadding'>

                                        
                                        { unemployment.map((action) => {
                                        return <ActionCard option={action}/>
                                        }) }

                                    </div>

                                    <CardHeader title='Acts of God, Theft and Fraud'/>

                                    <div className='standardPadding'>


                                        <h4 className='left'></h4>

                                        { actsofGod.map((action) => {
                                        return <ActionCard option={action}/>
                                        }) }
                                    </div>

                                </div>



                            </ExpandableCard>
                        </div>
                
        )



    }

    export default ProtectMisfortune;

