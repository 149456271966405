import React from 'react';
// import ReactGA from "react-ga";

import ExpandableCard from '../../components/expandableCard/expandableCard';
import CardHeader from '../../components/cardHeader/cardHeader';


const WealthProperty = () => {


        return (

            <div>

            <ExpandableCard title='Property'>

                                <div className='expandLeftSide Opacninety'>
                                    <p className=''>5</p>
                                </div>

                                <div>
                                    <CardHeader title='Coming Soon' primary='yes'/>
                                    <div className='standardPadding'>

                                    <p>In the meantime, we recommend the following books for property investing.</p>

                                    <p className='i'>Full disclosure, as an Amazon Associate we earn from qualifying purchases made.</p>

                                    <div className='espace'>
                                        <a target="_blank"  href="https://www.amazon.co.uk/gp/product/1784521280/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=1784521280&linkCode=as2&tag=nicholasg1-21&linkId=02e7924f7a63e426635292a43658f6da"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=1784521280&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=1784521280" width="1" height="1" border="0" alt=""/>
                                
                                        <div className='nMar'>
                                            <br></br>
                                            <p className='bling subHead'>Property Magic</p>
                                            <p className='centre i'>by Simon Zutshi</p>
                                            <br></br>
                                        </div>

                                        <a target="_blank"  href="https://www.amazon.co.uk/gp/product/B00CZA6ZLG/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=B00CZA6ZLG&linkCode=as2&tag=nicholasg1-21&linkId=bd54eec1ad2b7a7c2b85d1d6d7a95344"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=B00CZA6ZLG&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=B00CZA6ZLG" width="1" height="1" border="0" alt=""/>

                                        <div className='nMar'>
                                            <br></br>
                                            <p className='bling subHead'>Property Investing Secrets</p>
                                            <p className='centre i'>by Rob Moore and Mark Homer</p>
                                            <br></br>
                                        </div>

                                    </div>


                                    </div>

                                   


                                </div>

                            </ExpandableCard>
                        </div>  
                
        )



    }

    export default WealthProperty;