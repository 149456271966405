import React from 'react';

import ForSale from '../../icons/forsale';
import UpArrow from '../../icons/upArrow';

import DownArrow from '../../icons/downArrow';

import ResultSummary from '../../components/resultSummary/resultSummary';
import ExpandableCard from '../../components/expandableCard/expandableCard';

import Fade from 'react-reveal/Fade';


import { withRouter } from "react-router-dom";
import PriceTag from '../../icons/pricetag';
import Cost from '../../icons/cost';
import InvestRisk from '../../icons/investRisk';
import Crash from '../../icons/crash';
import IconFinder from '../../components/iconfinder/iconfinder';
import ThumbUp from '../../icons/thumbUp';
import Intermed from '../../icons/intermed';
import Income from '../../icons/income';
import Clock from '../../icons/clock';
import TeaWhite from '../../icons/teawhite';
import CostWhite from '../../icons/costWhite';

// import ReactGA from 'react-ga';





const Results = (props) => {

    let results = props.results
    let score = props.score
    let charges = props.charges

    let goodCharge = charges.goodCharge
    let badCharge = charges.badCharge
    let actionButton;

    let financeSections = [
        {title: 'It\'s about the product', wording: 'Who you choose to manage your money makes a big difference.  Costs are one element you can control. A low charge is typically around 0.5%, while an expensive charge is around to 2%.  Over 10 years, the difference is:'},
        {title: 'It\'s about investment return', wording: 'The difference between the top performing funds and worst performing funds in any given year is around 20%. Even if we quarter that range to 5% to remove the extreme cases, choosing the wrong investments will cost you a lot of money.  Over 10 years, the difference is:'},
        {title: 'It\'s about tax', wording: 'Income Tax, Capital Gains Tax, Inheritance Tax, Dividend Tax will all impact your savings significantly.  Placing your savings in tax wrappers such as Pensions, ISAs and VCTs, and leveraging your annual allowances wisely will reduce the amount of tax you pay.  The higher your level of savings, the more important tax planning becomes.'},
        {title: 'It\'s about cost', wording: 'Advice is not entirely financially beneficial. It costs. Typical charging is an initial 2% fee with ongoing 0.75% fee.  Minimum fees will apply. This will cost:'},
        
      ];


        let timeSections = [
          {title: 'It\'s about base knowledge', Low: {time: 12, unit: 'Hours', wording: 'Given your low level of financial knowledge, it will take you a fair amount of time to learn what needs to be considered and what is available. We estimate it will take you:'},
                                                Ok: {time: 5, unit: 'Hours', wording: 'As you have an ok level of financial knowledge, you’ll need less time to understand what needs to be considered, we estimate it will take you:'},
                                                Good: {time: 2, unit: 'Hours', wording: 'Given your good level of financial knowledge, you’ll only need a quick refresh, we estimate it will take you:'},
          icon: 'Book',
                                              },
          {title: 'It\'s about understanding needs', Low: {time: 5, unit: 'Hours', wording: 'Once you understand what is available, you need to understand what is right for you.  It will take:',icon: 'Person'},
                                                    Ok: {time: 3, unit: 'Hours', wording: 'Once you understand what is available, you need to understand what is right for you.  It will take:',icon: 'Person'},
                                                    Good: {time: 2, unit: 'Hours', wording: 'Once you understand what is available, you need to understand what is right for you.  It will take:',icon: 'Person'},
           icon: 'Scales',
          },
          {title: 'It\'s about finding the right product', Low: {time: 10, unit: 'Hours', wording: 'You\'ll need to visit various comparison and product websites, read the documentation and identify the right investments.  It will take:'},
                                                           Ok: {time: 6, unit: 'Hours', wording: 'You\'ll need to visit various comparison and product websites, read the documentation and identify the right investments.  It will take:'},
                                                            Good: {time: 3, unit: 'Hours', wording: 'You\'ll need to visit various comparison and product websites, read the documentation and identify the right investments.  It will take:'},
            icon: 'ActiveFund',
          },
          {title: 'It\'s about implementing', time: 3, unit: 'Hours', wording: 'Making it happen can be time consuming and frustrating regardless of your level of knowledge. It will take:', icon:'Steps'},
          {title: 'It\'s about ongoing', time: 18, unit: 'Days', wording: 'The one constant in life, is change. Investments, contributions and products will need to be reviewed every year, over 20 years that will consume:', icon:'Calender'},
      ];


    let riskSections = [
        {title: 'It\'s about Investment Risk', wording: 'Markets go up and down, managing this risk inline with your capacity for the loss is key to financial wellbeing.'},
        {title: 'It\'s about managing yourself', wording: 'Behavioural economics demonstrates human nature makes us take actions that are often detrimental to reaching our objectives. '},
        {title: 'It\'s about capacity for loss', wording: 'Murphy\'s Law states that anything that can go wrong, will. So how would you cope if:'},
        ]


        let timeConfiguration = timeSections.map((section)=>{

          if(section.time){
              return  <Fade delay={50}><div className='iconHead standardPadding uBordLine'>
                                
                                <IconFinder icon={section.icon}/>

                                <h4 className='centre bling'>{section.title}</h4>

                                <p className='centre'>{section.wording}</p>

                                <h4 className='centre'>An extra </h4>
                                <h4><span className='blingSec largeNumber'> {section.time}</span> {section.unit} </h4>

                            </div></Fade>
                  

          } else {
           return <Fade delay={50}><div className='iconHead standardPadding uBordLine'>
                                
                      <IconFinder icon={section.icon}/>

                      <h4 className='centre bling'>{section.title}</h4>

                      <p className='centre'>{section[results.knowledge].wording}</p>

                      <h4 className='centre'>An extra </h4>
                      <h4><span className='blingSec largeNumber'> {section[results.knowledge].time}</span> {section[results.knowledge].unit} </h4>

                  </div></Fade>
          }
                
          })

                            

  const linkToAdvice = () => {
    // ReactGA.event({
    //     category: 'link',
    //     action: 'about to home'
    //   });
    window.scrollTo(0, 0);
    props.history.push('/advice')
  }

  const linkToDIYWealth = () => {
    // ReactGA.event({
    //     category: 'link',
    //     action: 'about to home'
    //   });
    window.scrollTo(0, 0);
    props.history.push('/createwealth')
  }

  const linkToDIYWealthProtect = () => {
    // ReactGA.event({
    //     category: 'link',
    //     action: 'about to home'
    //   });
    window.scrollTo(0, 0);
    props.history.push('/protectwealth')
  }

  const linkToDIYRetire= () => {
    // ReactGA.event({
    //     category: 'link',
    //     action: 'about to home'
    //   });
    window.scrollTo(0, 0);
    props.history.push('/retire')
  }



        






    if (score.overall === 'Yes') {
      actionButton = <button className="actionButtonSec b" onClick={linkToAdvice}>Get Advice</button>
    } else if(score.overall  === 'No' && results.objective === 'Retirement'){
        actionButton = <button className="actionButtonSec b" onClick={linkToDIYRetire}>How to Retire</button>
    } else if(score.overall  === 'No' && results.objective === 'Wealth Creation'){
        actionButton = <button className="actionButtonSec b" onClick={linkToDIYWealth}>How to Create Wealth</button>
      } else if(score.overall  === 'No' && results.objective === 'Wealth Protection'){
        actionButton = <button className="actionButtonSec b" onClick={linkToDIYWealthProtect}>How to Protect Wealth</button>
    }else{
      actionButton = <button className="actionButtonSec b" onClick={linkToAdvice}>Get Advice</button>
    
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0
    })

    


  return (
      <React.Fragment>

                <ExpandableCard title='Financial'>
                  <div className='expandLeftSide Opacninety'>
                      <CostWhite/>
                  </div>

                    <div >

                          <div className='standardPadding'>

                           <Fade delay={50}>

                              <div className='iconHead uBordLine'>
                                
                                <PriceTag width='50' height='48'/>

                                <h4 className='centre bling'>{financeSections[0].title}</h4>

                                <p className='centre'>{financeSections[0].wording}</p>

                                <h4 className='centre'>An extra </h4>
                                <h4><span className='blingSec largeNumber'> {formatter.format(charges.chargeDifference)}</span></h4>

                            </div>

                            </Fade>

                            <Fade delay={50}>


                            <div className='iconHead uBordLine'>
                                
                                <UpArrow width='50' height='48'/>
                                
                                
                                <h4 className='centre bling'>{financeSections[1].title}</h4>

                                <p className='centre'>{financeSections[1].wording}</p>

                                <h4 className='centre'>An extra </h4>
                                <h4><span className='blingSec largeNumber'> {formatter.format(charges.investDifference)}</span></h4>

                            </div>

                            </Fade>

                            <Fade delay={50}>

                            <div className='iconHead uBordLine'>
                                
                                <Income width='50' height='48'/>
                                
                                <h4 className='centre bling'>{financeSections[2].title}</h4>

                                <p className='centre'>{financeSections[2].wording}</p>

                                <h4 className='centre'>An extra </h4>
                                <h4><span className='blingSec largeNumber'> £?</span></h4>

                            </div>

                            </Fade>

                            <Fade delay={50}>

                            <div className='iconHead '>
                                
                                <Cost width='50' height='48'/>
                                
                                <h4 className='centre bling'>{financeSections[3].title}</h4>

                                <p className='centre'>{financeSections[3].wording}</p>

                                <h4 className='centre'>An extra </h4>
                                <h4><span className='warningText largeNumber'> {formatter.format(charges.adviserCost)}</span></h4>

                            </div>

                            </Fade>

                            </div>

                           

                            <ResultSummary outcome={score.financial.outcome} wording={score.financial.wording} title={score.financial.title}/>


            

                    </div>
                    
                    
                
                </ExpandableCard>

              

              <ExpandableCard title='Time'>
                
                <div className='expandLeftSide Opacninety'>
                    <Clock/>
                </div>

                <div>

                    <div className='standardPadding'>
                      {timeConfiguration}
                    </div>

                    

                    <ResultSummary outcome={score.time.outcome} wording={score.time.wording} title={score.time.title}/>
                
                </div>

                

            </ExpandableCard>


            <ExpandableCard title='Peace of Mind'>
                <div className='expandLeftSide Opacninety'>
                    <TeaWhite/>
                </div>

                <div >
                  <div className='standardPadding'>

                        <Fade delay={50}>

                            <div className='iconHead uBordLine'>
                                
                                <InvestRisk width='50' height='48'/>        
                                
                                <h4 className='centre bling'>{riskSections[0].title}</h4>

                                <p className='centre'>{riskSections[0].wording}</p>

                            </div>
                       </Fade>

                      <Fade delay={50}>


                      <div className='iconHead uBordLine'>
                                  
                                  <Intermed width='50' height='48'/>  
                                        
                                  
                                  <h4 className='centre bling'>{riskSections[1].title}</h4>

                                  <p className='centre'>{riskSections[1].wording}</p>



                    <div className='resultSummary'>
                      <div className='fteen flexWrap'>
                          <UpArrow/>
                        </div>
                        
                        <p className='eighty'>
                        <span className='blingSec'>Trend Bias</span>, we often believe that past performance predicts future returns.  Past performance has been proven to be a very poor predictor of future returns. Advisers look at the present and future, not the past.
                        </p>
                    </div>

                    <div className='resultSummary'>

                      <div className='fteen flexWrap'>
                          <ThumbUp/>
                        </div>
                        
                        
                        <p className='eighty'>
                        <span className='blingSec'>Confirmation Bias</span>, we pay attention to facts that support our view not contradict it.  Advisers provide balance to our natural bias.
                        </p>
                    
                    </div>

                    <div className='resultSummary'>
                      
                        <div className='fteen flexWrap'>
                          <ForSale/>
                        </div>
                        
                        <p className='eighty'>
                        <span className='blingSec'>Worry</span>, when stocks are crashing we want to sell and when stocks are booming we want to buy.  This is often the exact opposite of what we should be doing.  Advisers provide perspective.
                        </p>
                    </div>

                    <div className='resultSummary'>
                      <div className='fteen flexWrap'>
                        <DownArrow/>
                      </div>
                        
                        <p className='eighty'>
                        <span className='blingSec'>Loss aversion/ Endowment effect</span>, we prefer to avoid loss than make gains and we value assets we own more than identical assets we do not.  This makes onto assets too long. Advisers help you identify opportunity.
                        </p>
                    </div>

                    </div>

                    </Fade>


                    <Fade delay={50}>


                    <div className='iconHead'>
                                
                                <Crash width='50' height='48'/>    
                                    
                                
                                <h4 className='centre bling'>{riskSections[2].title}</h4>

                                <p className='centre'>{riskSections[2].wording}</p>

                                <h4 className='centre'>You wealth halves to </h4>

                                <h4><span className='blingSec'> {formatter.format(results.wealth /2)}</span></h4>
                    
                                <h4 className='centre'>You receive a unexpected bill for</h4>
                                <h4> <span className='blingSec'> {formatter.format(results.wealth /20)}</span></h4>

                                <p>Planning to deal with the unforeseen is about asking ‘What If’.  An adviser, will help to give you the confidence to approach the future, knowing you’ll be able to answer.</p>
                    

                            </div>

                       </Fade>

                      </div>

                      <ResultSummary outcome={score.risk.outcome} wording={score.risk.wording} title={score.risk.title}/>

                
                </div>

            </ExpandableCard>
                            


             <div className='card fixedPadding'>

              <h3 className='centre'>What next?</h3>

              <div className='flexWrapCol'>

                  {actionButton}

                  <button class="actionButtonS" onClick={props.back}>Try again</button>


              </div>

                  

              </div>


             <div className='card standardPadding'>

                <p>Important...the information on this site does not constitute financial advice. Nor can we guarantee the guides and calculators provide all the information you need.  The numbers presented in this tool may vary significantly from actual experience. </p>

                <p>The site has been set up to provide guidance on how to achieve your financial goals.</p>

            </div>

          

     </React.Fragment>
  )

};

export default withRouter(Results);

