import React, {useState} from 'react';

import Header from './header/header';
import SideBar from './SideBar/Sidebar';
import Footer from './footer/footer';

const Layout = (props) => {

    const [sideBarToggle, setSideBarToggle] = useState(false)

    const SideBarClosedHandler = () => {
        setSideBarToggle(false);
      };
    
      const SideBarToggleHandler = () => {
        setSideBarToggle(!sideBarToggle);
      };

    return (
        <React.Fragment>
            <Header toggle={SideBarToggleHandler}/>
            <SideBar open={sideBarToggle} closed={SideBarClosedHandler}/> 
            <React.Fragment>
                {props.children}
            </React.Fragment>
            <Footer/>
        </React.Fragment>
    )
}

export default Layout;



    
