import React from "react";

function Steps({width=20, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 26"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-99 -475)"
      >
        <rect
          width="286"
          height="390"
          x="69"
          y="149"
          stroke="#E8E3E3"
          strokeWidth="2"
          rx="4"
        ></rect>
        <g fillRule="nonzero" stroke="#303030">
          <path
            d="M3.28.13c-.743.036-1.375.347-1.88.922C-.137 2.804.08 6.51.092 6.694c.287 2.457.522 3.328.819 4.431.081.303.167.622.26.998a.494.494 0 00.575.369l3.947-.813a.499.499 0 00.393-.53c-.097-1.27.227-1.96.57-2.69.302-.642.613-1.307.552-2.327C7.08 3.972 6.84-.061 3.28.129zM5.89 12.78l-3.872.797a.489.489 0 00-.314.216.507.507 0 00-.07.379c.466 2.245 1.058 4.25 3.04 4.25.17 0 .351-.015.542-.045.744-.12 1.228-.386 1.52-.838.563-.866.279-2.166-.272-4.39a.495.495 0 00-.575-.37zM16.364 6.464c-.504-.575-1.136-.885-1.88-.922-3.548-.175-3.798 3.842-3.931 6.053-.06.972.253 1.635.554 2.277.343.73.667 1.42.57 2.69-.021.252.148.48.392.53l3.947.813a.493.493 0 00.577-.37c.093-.376.18-.696.26-1 .297-1.103.531-1.974.822-4.456.01-.157.227-3.863-1.31-5.615zM15.747 18.99l-3.872-.798a.487.487 0 00-.576.37l-.057.234c-.48 1.957-.796 3.25-.215 4.141.297.456.794.734 1.52.853.192.03.373.045.543.045 1.982 0 2.575-2.006 3.04-4.25a.51.51 0 00-.07-.38.496.496 0 00-.313-.216z"
            transform="translate(100 476)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Steps;