import React from "react";

function Presentation({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-195 -403)"
      >
        <rect
          width="286"
          height="390"
          x="69"
          y="149"
          stroke="#E8E3E3"
          strokeWidth="2"
          rx="4"
        ></rect>
        <path
          fill="#303030"
          fillRule="nonzero"
          d="M4.4 4.8c1.323 0 2.4-1.077 2.4-2.4S5.723 0 4.4 0A2.403 2.403 0 002 2.4c0 1.323 1.077 2.4 2.4 2.4zm0-4C5.282.8 6 1.518 6 2.4 6 3.282 5.282 4 4.4 4c-.882 0-1.6-.718-1.6-1.6 0-.882.718-1.6 1.6-1.6zm3.726 7.917C7.976 7.417 6.866 6.4 5.6 6.4H3.2C1.934 6.4.823 7.418.674 8.717L.01 14.455c-.053.45.087.899.381 1.229.274.307.643.476 1.042.476l.567 7.47A.4.4 0 002.4 24h4a.4.4 0 00.4-.37l.557-7.47h.009c.398 0 .768-.168 1.042-.475.294-.33.433-.779.38-1.229l-.662-5.74zm-.315 6.435a.595.595 0 01-.445.208h-.38a.4.4 0 00-.4.37L6.03 23.2H2.77l-.557-7.47a.4.4 0 00-.4-.37h-.38a.592.592 0 01-.445-.208.775.775 0 01-.183-.605l.662-5.739C1.572 7.906 2.333 7.2 3.2 7.2h2.4c.867 0 1.628.706 1.732 1.608l.662 5.74a.773.773 0 01-.183.604zM12 12.4v-4a.4.4 0 00-.4-.4H10a.4.4 0 000 .8h1.2V12h-.76a.4.4 0 000 .8h1.16a.4.4 0 00.4-.4zm9.2.4a.4.4 0 00.4-.4V3.6a.4.4 0 00-.4-.4H18a.4.4 0 00-.4.4v8.8c0 .22.18.4.4.4h3.2zM18.4 4h2.4v8h-2.4V4zm-2 8.8a.4.4 0 00.4-.4V5.2a.4.4 0 00-.4-.4h-3.2a.4.4 0 00-.4.4v7.2c0 .22.18.4.4.4h3.2zm-2.8-7.2H16V12h-2.4V5.6zm10-5.6H8.4a.4.4 0 000 .8h14.8v13.6H10.851a.4.4 0 000 .8H23.6a.4.4 0 00.4-.4V.4a.4.4 0 00-.4-.4z"
          transform="translate(195 403)"
        ></path>
      </g>
    </svg>
  );
}

export default Presentation;