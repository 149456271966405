import React from "react";

function Beginner({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 22 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-50 -343)">
          <g transform="translate(50 342)">
            <path d="M21.5 15.48c0-.709-1.461-3.324-2.19-4.585.067-.36.19-1.183.19-2.415 0-2.135-2.826-7.5-9.5-7.5C3.447.98.5 5.951.5 9.48c0 2.496 1.474 5.054 2.659 7.107.69 1.197 1.341 2.327 1.341 2.893 0 1.273-2.07 3.796-2.868 4.661a.5.5 0 00.735.679c.321-.346 3.133-3.448 3.133-5.338 0-.834-.651-1.963-1.475-3.392C2.9 14.138 1.5 11.711 1.5 9.482c0-2.447 2.078-7.5 8.5-7.5 6.375 0 8.5 5.124 8.5 6.5 0 1.53-.202 2.371-.204 2.38a.503.503 0 00.053.372c1.006 1.728 2.115 3.847 2.152 4.24-.046.138-.564.405-1.104.519a.5.5 0 00-.372.647l.402 1.207-.281.281a.492.492 0 00-.127.452.863.863 0 00-.204.13c-.144.127-.315.365-.315.771 0 .4.168.65.291.833.121.181.209.313.209.666 0 .39-.15.691-2.081 1.007-1.321.216-4.419 1.325-4.419 2.493 0 .276.217.539.493.539.277 0 .493-.185.494-.461.177-.357 1.952-1.316 3.594-1.585C18.312 22.772 20 22.496 20 20.98c0-.66-.228-.999-.379-1.224-.101-.151-.121-.188-.183-.28a.495.495 0 00.558-.434.488.488 0 00-.061-.29l.418-.418a.5.5 0 00.121-.512l-.334-1.002c.575-.198 1.36-.606 1.36-1.34z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Beginner;