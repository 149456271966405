import React from "react";

function Cash({width=24, height=14}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 14"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-381 -171)">
          <g transform="translate(381 171)">
            <g transform="translate(0 2)">
              <path d="M20.5 11.5H.5A.5.5 0 010 11V1A.5.5 0 01.5.5h20a.5.5 0 01.5.5v10a.5.5 0 01-.5.5zM1 10.5h19v-9H1v9z"></path>
              <path d="M17.5 9.5h-3a.5.5 0 010-1h2.585c.151-.426.489-.764.915-.915V4.414a1.508 1.508 0 01-.915-.915H14.5a.5.5 0 010-1h3a.5.5 0 01.5.5.5.5 0 00.5.5.5.5 0 01.5.5v4a.5.5 0 01-.5.5.5.5 0 00-.5.5.5.5 0 01-.5.501zM6.5 9.5h-3A.5.5 0 013 9a.5.5 0 00-.5-.5A.5.5 0 012 8V4a.5.5 0 01.5-.5A.5.5 0 003 3a.5.5 0 01.5-.5h3a.5.5 0 010 1H3.915A1.508 1.508 0 013 4.415v3.171c.426.151.764.489.915.915H6.5a.499.499 0 110 .999z"></path>
            </g>
            <path d="M20.5 13.5a.5.5 0 01-.278-.916L23 10.732V1.5H3.651L.777 3.416a.5.5 0 01-.554-.832l3-2A.496.496 0 013.5.5h20a.5.5 0 01.5.5v10a.499.499 0 01-.223.416l-3 2a.494.494 0 01-.277.084z"></path>
            <path d="M20.5 3.5a.5.5 0 01-.278-.916l3-2a.5.5 0 11.554.832l-3 2a.489.489 0 01-.276.084zM8.5 13.5A.5.5 0 018 13V3c0-.167.083-.323.223-.416l3-2a.5.5 0 11.554.832L9 3.268V13a.5.5 0 01-.5.5zM12.5 13.5a.5.5 0 01-.5-.5V3c0-.167.083-.323.223-.416l3-2a.5.5 0 11.554.832L13 3.268V13a.5.5 0 01-.5.5z"></path>
          </g>
          <path
            d="M429.12 146.365c-1.133-1.134-3.104-1.139-4.247.005l-18.732 19.268a.5.5 0 00.717.698l18.728-19.263c.756-.756 2.072-.756 2.828 0 .378.378.586.88.586 1.414 0 .534-.208 1.036-.586 1.414l-1.061 1.061a.5.5 0 00.707.707l1.061-1.061c.567-.566.879-1.32.879-2.121 0-.801-.312-1.555-.88-2.122z"
            transform="scale(-1 1) rotate(-45 0 1165.141)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Cash;