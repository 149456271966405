import React from 'react';

import ActionCard from '../../components/actionCard/actionCard';
import CardHeader from '../../components/cardHeader/cardHeader';



const WealthInvestApp = (props) => {

    const mps = {
        title: 'Managed Portfolios',
        description: 'You will be given a portfolio of investments that is aligned to your risk level.  This portfolio will consist of a number of equity and bond funds.',
        pros: ['A simple and easy way to invest in the stock market.'],
        cons: ['Can be relatively expensive and are unlikely to provide the best returns available in the market.'],
        steps: ['Wealth Simple, Nutmeg and a number of digital advisers provide these'],
        show: [{knowledge: 'Beginner', horizon: 'Between 2 and 10 Years'},{knowledge: 'Beginner', horizon: 'Longer than 10 Years'}],
        icon: 'Pie',
        }

   const indexTrack = {
            title: 'Index Trackers',
            description: 'Regarded by many as the best way to invest in the stock market over the long term.  Trackers invest proportionally in the underlying companies of a stock index such as the S&P 500 and FTSE 100.',
            pros: ['Low cost and likely to provide better returns the majority of funds'],
            cons: ['May not be aligned to your attitude to risk and especially vulnerable to market crashes'],
            steps: ['Vanguard has been leading the way in low cost index trackers'],
            show: [{knowledge: 'Knowledgeable', time: 'Some'}],
            icon: 'CostAverage',
            }

   const cash = {
                title: 'Cash',
                description: 'Cash is safest investment option if you have a short investment horizon. If you need that money within 2 years, investing in the market could be unwise.',
                pros: ['If there is a market crash you will not lose money at a time when you need the money. '],
                cons: ['If you need the capital to grow significantly within this short timeframe. Cash will not meet your objectives. You will need to take on risk.'],
                steps: ['Look for the best interest rate for your investment term'],
                show: [{knowledge: 'Beginner', horizon: 'Less than 2 Years'}],
                icon: 'Cost',
                }

        
   const activeFunds  = {
    title: 'Active Funds',
    description: 'Specialist funds with specific remits, strategies and investing philosophies.',
    pros: ['The right funds will produce the best returns available to you.'],
    cons: ['High costs and high variability in performance.'],
    warnings: ['There are thousands of funds to choose from.  To picking the right fund, you will need time and significant expertise.'],
    steps: ['Morningstar and Financial Express review and rate funds.  Professional opinion should be sort when picking these funds.'],
    show: ['Knowledgeable'],
    icon: 'ActiveFund',
    }

    const stockandshares  ={
        title: 'Individual Stocks and Shares',
        description: 'You pick and choose the stocks and shares you want.',
        pros: ['You are in complete control and you have the opportunity to beat the market.'],
        cons: ['You will need time, skill and significant luck to do better than market and the professionals.'],
        warnings: ['This is high risk and not for beginners.'],
        steps: ['Hargreaves Lansdown provides a simple online platform to trade individual shares.'],
        icon: 'Building',
        }


        return (

            <div>

                <CardHeader title='Consider the following investments to invest in'/>

                <div className='standardPadding'>

                    { props.results.horizon === 'Less than 2 Years'? <ActionCard option={cash}/> : null }

                    { props.results.knowledge === 'Beginner' && props.results.horizon !== 'Less than 2 Years'? <ActionCard option={mps}/> : null }

                    { props.results.knowledge === 'Knowledgeable' && props.results.horizon !== 'Less than 2 Years' ? <ActionCard option={indexTrack}/> : null }

                    { props.results.knowledge === 'Knowledgeable' && props.results.time !== 'Little' ? <ActionCard option={activeFunds}/> : null }

                    { props.results.knowledge === 'Expert' && props.results.time !== 'Little' ? <ActionCard option={stockandshares}/> : null }

                    { props.results.knowledge === 'Expert' ? <ActionCard option={activeFunds}/> : null }

                    { props.results.knowledge === 'Knowledgeable' && props.results.time === 'Lots' ? <ActionCard option={stockandshares}/> : null }

                </div>


            </div>


                
        )



    }

    export default WealthInvestApp;