import React from "react";

function ForSale({width=24, height=24}) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
        >
          <g fill="#303030" fillRule="nonzero" stroke="none" strokeWidth="1">
            <path
              d="M4.156 7.019l-.148-.025C3.131 6.851 3 6.623 3 6.5c0-.199.399-.5 1.001-.5.591 0 .981.281 1.001.483a.494.494 0 00.545.45.5.5 0 00.45-.545C5.921 5.596 5.063 4.999 4 4.999c-1.122 0-2.001.659-2.001 1.5 0 .783.621 1.281 1.847 1.481l.081-.493.067.518c.831.137 1.005.345 1.005.495 0 .199-.399.5-1.001.5-.591 0-.981-.282-1.001-.484a.49.49 0 00-.545-.45.5.5 0 00-.45.545C2.078 9.403 2.936 10 3.999 10 5.121 10 6 9.341 6 8.5c0-.782-.62-1.28-1.844-1.481zM9.469 5.324c-.147-.39-.791-.39-.937 0l-1.5 4a.5.5 0 00.936.352L8.221 9h1.557l.253.676a.503.503 0 00.646.292.5.5 0 00.292-.644l-1.5-4zM8.596 8L9 6.924 9.404 8h-.808zM14.5 9H13V5.5a.5.5 0 00-1 0v4a.5.5 0 00.5.5h2a.5.5 0 000-1zM16 5.5v4a.5.5 0 00.5.5h2a.5.5 0 000-1H17V8h1a.5.5 0 000-1h-1V6h1.5a.5.5 0 000-1h-2a.5.5 0 00-.5.5z"
              transform="translate(-225 -616) translate(225 616)"
            ></path>
            <path
              d="M23.5 0H.5a.5.5 0 000 1H4v2H.5a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h20a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5H17V1h6v22.5a.5.5 0 001 0V.5a.5.5 0 00-.5-.5zM20 11H1V4h19v7zm-4-8H5V1h11v2z"
              transform="translate(-225 -616) translate(225 616)"
            ></path>
          </g>
        </svg>
      );
}

export default ForSale;