import React from "react";

function Trust({width=22, height=27}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 27"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#303030"
        strokeWidth="2"
        transform="translate(-117 -280) translate(118 281)"
      >
        <path d="M9.913 24S0 20.452 0 10.226v-8.4L9.913 0l9.913 1.826v8.4C19.826 20.556 9.913 24 9.913 24z"></path>
        <path d="M5.47826087 10.4347826L9.80869565 14.8173913 14.8695652 6.05217391"></path>
      </g>
    </svg>
  );
}

export default Trust;