import React from "react";

function Ambulance({width=22, height=18}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 22 18"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-164 -313)">
          <g transform="translate(164 313)">
            <path d="M21.5 12.5a.5.5 0 01-.5-.5V5.207l-.707-.707H4.75L2 8.167V12a.5.5 0 01-1 0V8a.5.5 0 01.1-.3l3-4a.501.501 0 01.4-.2h16c.133 0 .26.053.354.146l1 1A.504.504 0 0122 5v7a.5.5 0 01-.5.5z"></path>
            <path d="M6.5 9.5h-3a.498.498 0 01-.44-.264.498.498 0 01.024-.513l2-3A.501.501 0 015.5 5.5h1A.5.5 0 017 6v3a.5.5 0 01-.5.5zm-2.065-1H6v-2h-.232l-1.333 2z"></path>
            <path d="M21.5 14.5h-1a.5.5 0 010-1h.5v-1H5.5a.516.516 0 01-.224-.053l-2-1a.5.5 0 01.447-.895l1.895.948H21.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zM1.5 14.5h-1A.5.5 0 010 14v-2a.5.5 0 01.5-.5h1a.5.5 0 010 1H1v1h.5a.5.5 0 010 1z"></path>
            <path d="M4 17.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3a1 1 0 100 2 1 1 0 000-2zM18 17.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3a1 1 0 100 2 1 1 0 000-2z"></path>
            <path d="M15.5 14.5h-9a.5.5 0 010-1h9a.5.5 0 010 1zM17.5 10.5a.5.5 0 01-.5-.5V6a.5.5 0 011 0v4a.5.5 0 01-.5.5z"></path>
            <path d="M19.232 9.5a.494.494 0 01-.25-.067l-3.465-2a.5.5 0 01.5-.866l3.465 2a.5.5 0 01-.25.933z"></path>
            <path d="M15.768 9.5a.5.5 0 01-.25-.933l3.465-2a.5.5 0 11.5.866l-3.465 2a.497.497 0 01-.25.067z"></path>
            <path d="M13.5 9.5h-4A.5.5 0 019 9V6a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v3a.5.5 0 01-.5.5zm-3.5-1h3v-2h-3v2zM10.5 4.5h-2A.5.5 0 018 4V2C8 1.173 8.673.5 9.5.5S11 1.173 11 2v2a.5.5 0 01-.5.5zM9 3.5h1V2a.5.5 0 00-1 0v1.5z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Ambulance;