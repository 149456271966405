import React from "react";

function Income({width=24, height=22}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 22"
    >
      <g fill="#303030" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path
          d="M3.5 10.516h-3a.5.5 0 01-.5-.5v-9A.5.5 0 01.53.517c1.03.061 4.457.324 5.314 1.136a.498.498 0 01.154.406c-.227 2.6-1.95 7.888-2.023 8.112a.5.5 0 01-.475.345zm-2.5-1h2.135c.372-1.174 1.571-5.065 1.836-7.255-.63-.306-2.364-.582-3.971-.709v7.964z"
          transform="translate(-169 -1004) translate(169 1004) translate(0 9)"
        ></path>
        <path
          d="M13.396 12.484c-.652 0-1.232-.184-2.073-.501L3.842 9.49a.5.5 0 11.316-.948l7.5 2.5c1.802.68 1.948.734 6.152-.988L22.767 7.8a2.279 2.279 0 00-2.315-.756L16.621 8a.5.5 0 01-.242-.97l3.831-.958a3.268 3.268 0 013.738 1.719.501.501 0 01-.24.679l-5.5 2.5c-2.61 1.071-3.798 1.514-4.812 1.514z"
          transform="translate(-169 -1004) translate(169 1004) translate(0 9)"
        ></path>
        <path
          d="M14.427 8.441c-.23 0-.461-.022-.686-.067l-4.339-.868a.5.5 0 01-.392-.588.494.494 0 01.588-.392l4.338.868c.362.073.741.063 1.096-.026l.96-.24c-.061-.913-.506-1.349-1.614-1.626L5.391 3.505a.5.5 0 01-.38-.597.507.507 0 01.597-.38l9 2C16.267 4.942 17 5.814 17 7.358v.158a.5.5 0 01-.379.485l-1.346.336a3.498 3.498 0 01-.848.104z"
          transform="translate(-169 -1004) translate(169 1004) translate(0 9)"
        ></path>
        <path
          d="M18.5 13.516c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5zm0-6a2.503 2.503 0 00-2.5 2.5c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5-1.122-2.5-2.5-2.5z"
          transform="translate(-169 -1004) translate(169 1004)"
        ></path>
        <path
          d="M18.5 11.516a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5zM12.5 7.516c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5zm0-6a2.503 2.503 0 00-2.5 2.5c0 1.378 1.122 2.5 2.5 2.5s2.5-1.122 2.5-2.5-1.122-2.5-2.5-2.5z"
          transform="translate(-169 -1004) translate(169 1004)"
        ></path>
        <path
          d="M12.5 5.516a.5.5 0 01-.5-.5v-2a.5.5 0 011 0v2a.5.5 0 01-.5.5z"
          transform="translate(-169 -1004) translate(169 1004)"
        ></path>
      </g>
    </svg>
  );
}

export default Income;