import React, {useState} from 'react';
import ChevronUp from '../../icons/chevronUp';
import ChevronDown from '../../icons/chevronDown';
import './expandableCard.css';


const ExpandableCard = React.memo(function ExpandableCard(props) {

    const [expanded, setExpanded] = useState(props.expanded);

    const onExpandClick = (e) => {

        let positionY = window.pageYOffset
        let positionX = window.pageXOffset

        
        setExpanded(!expanded)

        window.scrollTo(positionX, positionY);
        

    }

    const classCalc = (highlight, expanded) => {

      if(highlight && expanded){
        return 'expandedSec'
      } else if(expanded){
        return 'expanded'
      } else {
        return 'unexpanded'
      }

    }


    return (
      <div className='card'>
          <div className={classCalc(props.highlight, expanded)}>

            

            
            <div onClick={onExpandClick} className='expandableHeader'>

              
                {props.children[0]}
              

              <div className='expandTitle flexWrapCol'>

                  <h3 className=''>{props.title}</h3>

              </div>

              <div className='expandRightSide'>
                {expanded ? <ChevronUp/>: <div className='expandAction flexWrapCol'><ChevronDown/></div> }

              </div>
  
            </div>

          </div>
          <div className={expanded ? 'expanding':'hide'}>
            {props.children[1]}
          </div>

  
      </div>
    );


})

export default ExpandableCard;