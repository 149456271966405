import React from "react";

function Easy({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
    >
      <path
        fill="#303030"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M13.5 9c.83 0 1.5-.67 1.5-1.5S14.33 6 13.5 6 12 6.67 12 7.5 12.67 9 13.5 9zm-7 0C7.33 9 8 8.33 8 7.5S7.33 6 6.5 6 5 6.67 5 7.5 5.67 9 6.5 9zm3.49-9C4.47 0 0 4.48 0 10s4.47 10 9.99 10C15.52 20 20 15.52 20 10S15.52 0 9.99 0zM10 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-4c-1.475 0-2.75-.81-3.445-2H4.88c.8 2.045 2.79 3.5 5.12 3.5s4.32-1.455 5.12-3.5h-1.675c-.695 1.19-1.97 2-3.445 2z"
        transform="translate(-290 -256) translate(290 256)"
      ></path>
    </svg>
  );
}

export default Easy;