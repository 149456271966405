import React from "react";

function Gift({width=24, height=22}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 22"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-131 -277)">
          <g transform="translate(131 277)">
            <path d="M21.2 4.9h-5.9c1-.7 1.8-1.7 2.1-2.9.2-.5 0-1.1-.4-1.5-.4-.4-.9-.5-1.5-.4-1.6.5-2.8 1.6-3.4 3-.6-1.4-1.9-2.6-3.4-3-.5-.2-1.1 0-1.5.4-.4.4-.5.9-.4 1.5.4 1.2 1.1 2.2 2.1 2.9H3C1.6 4.9.5 6 .5 7.4v3c0 .3.2.5.5.5h.5v8.5c0 1.4 1.1 2.5 2.5 2.5h16c1.4 0 2.5-1.1 2.5-2.5v-8.5h.5c.3 0 .5-.2.5-.5v-3c.2-1.4-.9-2.5-2.3-2.5zM7.9 1.7c0-.2 0-.3.1-.5 0 0 .2-.1.3-.1h.1c1.7.5 3 2 3.2 3.8-1.7-.2-3.2-1.5-3.7-3.2zm3.8 19.2H4.2c-.8 0-1.5-.7-1.5-1.5v-8.5h9v10zm0-11h-10V7.4c0-.8.7-1.5 1.5-1.5H11L8.9 8c-.2.2-.2.5 0 .7.2.2.5.2.7 0l2.1-2.1v3.3zm4.2-8.8c.3 0 .4.1.4.1.1.1.2.3.1.5-.5 1.7-2 3-3.8 3.2.3-1.8 1.6-3.3 3.3-3.8zm5.8 18.3c0 .8-.7 1.5-1.5 1.5h-7.5v-10h9v8.5zm1-9.5h-10V6.6l2.1 2.1c.1.1.2.1.4.1s.3 0 .4-.1c.2-.2.2-.5 0-.7l-2.1-2.1h7.8c.8 0 1.5.7 1.5 1.5l-.1 2.5z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Gift;