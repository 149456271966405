import React from "react";

function Coaster({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 22"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-22 -166)">
          <g transform="translate(22 166)">
            <g>
              <path d="M16 16.002c-3.31 0-15.185-4.833-15.689-5.039a.5.5 0 11.378-.926c.122.05 12.196 4.965 15.311 4.965a6.962 6.962 0 006.51-4.43c1.418-3.59-.349-7.665-3.938-9.083-3.589-1.417-7.665.349-9.083 3.938a.498.498 0 11-.929-.366C10.18.959 14.837-1.062 18.94.56c4.103 1.621 6.122 6.277 4.5 10.38A7.956 7.956 0 0116 16.002z"></path>
              <path d="M23.5 22a.5.5 0 01-.5-.5V8a.5.5 0 011 0v13.5a.5.5 0 01-.5.5zM.5 22a.5.5 0 01-.5-.5v-11a.5.5 0 011 0v11a.5.5 0 01-.5.5zM20.5 22a.5.5 0 01-.5-.5v-7a.5.5 0 011 0v7a.5.5 0 01-.5.5zM17.5 22a.5.5 0 01-.5-.5V16a.5.5 0 011 0v5.5a.5.5 0 01-.5.5zM3.5 22a.5.5 0 01-.5-.5V12a.5.5 0 011 0v9.5a.5.5 0 01-.5.5zM14.5 22a.5.5 0 01-.5-.5v-6a.5.5 0 011 0v6a.5.5 0 01-.5.5zM10.5 22a.5.5 0 01-.5-.5v-7a.5.5 0 011 0v7a.5.5 0 01-.5.5zM6.5 22a.5.5 0 01-.5-.5v-8a.5.5 0 011 0v8a.5.5 0 01-.5.5zM21.5 11a.5.5 0 01-.5-.5V3a.5.5 0 011 0v7.5a.5.5 0 01-.5.5zM18.5 13.5a.5.5 0 01-.5-.5V1a.5.5 0 011 0v12a.5.5 0 01-.5.5zM14.5 14a.5.5 0 01-.5-.5V1a.5.5 0 011 0v12.5a.5.5 0 01-.5.5zM11.5 7a.5.5 0 01-.5-.5v-4a.5.5 0 011 0v4a.5.5 0 01-.5.5zM11.5 13a.505.505 0 01-.171-.03l-11-4a.5.5 0 01.342-.939l10.165 3.696-.16-.801a5.747 5.747 0 00-3.512-4.222l-6.85-2.74a.501.501 0 01.371-.929l6.85 2.74a6.743 6.743 0 014.121 4.954l.334 1.673a.498.498 0 01-.49.598z"></path>
              <path d="M8.5 9.5a.493.493 0 01-.176-.032l-8-3a.5.5 0 01.352-.936l8 3A.5.5 0 018.5 9.5z"></path>
            </g>
            <path d="M3.501 10a.5.5 0 01-.486-.621l1-4a.5.5 0 11.97.242l-1 4a.499.499 0 01-.484.379z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Coaster;