import React from "react";

function Icon({width=19, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 19 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-320 -109)">
          <g transform="translate(320 110)">
            <g
              fill="#303030"
              fillRule="nonzero"
              transform="translate(0 16.696)"
            >
              <path d="M5.217.522A2.09 2.09 0 003.13 2.609a2.09 2.09 0 002.087 2.087 2.09 2.09 0 002.087-2.087A2.09 2.09 0 005.217.522zm0 3.13a1.045 1.045 0 010-2.087 1.045 1.045 0 010 2.087zm-3.652 0C.702 3.652 0 4.354 0 5.217s.702 1.566 1.565 1.566S3.13 6.08 3.13 5.217c0-.863-.702-1.565-1.565-1.565zm0 2.087a.523.523 0 11.002-1.045.523.523 0 01-.002 1.045z"></path>
            </g>
            <circle cx="9.391" cy="8.348" r="8.348" stroke="#303030"></circle>
            <path
              stroke="#303030"
              strokeLinecap="round"
              d="M9.391 2.326c1.766.248 3.176.878 4.229 1.891s1.73 2.39 2.031 4.13"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;