import React from 'react';
import './cardHeader.css';


const CardHeaderSec = ({title}) => {
        
        return (
            <div>

                <h4 className='centre'>{title}</h4>

                <div className='blingSecUnder'></div>

            </div>
            
        )
}


export default CardHeaderSec;