import React from "react";

function Options({width=8, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 8 26"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#303030"
        strokeWidth="2"
        transform="translate(-296 -208) translate(297 209)"
      >
        <circle cx="2.609" cy="2.609" r="2.609"></circle>
        <circle cx="2.609" cy="12" r="2.609"></circle>
        <circle cx="2.609" cy="21.391" r="2.609"></circle>
      </g>
    </svg>
  );
}

export default Options;