import React from "react";

function Cross(props) {


  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 171 171"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#EC3027"
        strokeWidth="1"
        transform="translate(6 6)"
      >
        <path
          strokeLinecap="round"
          strokeWidth="7.8"
          d="M47.701 47.7l63.64 63.64M111.34 47.7l-63.639 63.64"
        ></path>
        <circle cx="79.5" cy="79.5" r="79.5" strokeWidth="10.4"></circle>
      </g>
    </svg>
  );
}

export default Cross;