


import React from "react";

function Coins({width=24, height=25}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 20"
          >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
              <g fill="#303030" fillRule="nonzero" transform="translate(-228 -79)">
                <g transform="translate(228 79)">
                  <path d="M5.5 4C2.851 4 0 3.374 0 2s2.851-2 5.5-2S11 .626 11 2 8.149 4 5.5 4zm0-3C2.574 1 1.075 1.73.999 2.007 1.075 2.27 2.574 3 5.5 3s4.425-.73 4.501-1.007C9.925 1.73 8.426 1 5.5 1z"></path>
                  <path d="M5.5 6C2.851 6 0 5.374 0 4V2a.5.5 0 011 0v2c.075.27 1.574 1 4.5 1s4.425-.73 4.501-1.007L10 2a.5.5 0 011 0v2c0 1.374-2.851 2-5.5 2z"></path>
                  <path d="M5.5 8C2.851 8 0 7.374 0 6V4a.5.5 0 011 0v2c.075.27 1.574 1 4.5 1s4.425-.73 4.501-1.007L10 4a.5.5 0 011 0v2c0 1.374-2.851 2-5.5 2z"></path>
                  <path d="M18.5 14c-2.649 0-5.5-.626-5.5-2s2.851-2 5.5-2 5.5.626 5.5 2-2.851 2-5.5 2zm0-3c-2.926 0-4.425.73-4.501 1.007.076.263 1.575.993 4.501.993s4.425-.73 4.501-1.007C22.925 11.73 21.426 11 18.5 11z"></path>
                  <path d="M18.5 16c-2.649 0-5.5-.626-5.5-2v-2a.5.5 0 011 0v2c.075.27 1.574 1 4.5 1s4.425-.73 4.501-1.007L23 12a.5.5 0 011 0v2c0 1.374-2.851 2-5.5 2z"></path>
                  <path d="M18.5 18c-2.649 0-5.5-.626-5.5-2v-2a.5.5 0 011 0v2c.075.27 1.574 1 4.5 1s4.425-.73 4.501-1.007L23 14a.5.5 0 011 0v2c0 1.374-2.851 2-5.5 2z"></path>
                  <path d="M18.5 20c-2.649 0-5.5-.626-5.5-2v-2a.5.5 0 011 0v2c.075.27 1.574 1 4.5 1s4.425-.73 4.501-1.007L23 16a.5.5 0 011 0v2c0 1.374-2.851 2-5.5 2zM5.5 10C2.851 10 0 9.374 0 8V6a.5.5 0 011 0v2c.075.27 1.574 1 4.5 1s4.425-.73 4.501-1.007L10 6a.5.5 0 011 0v2c0 1.374-2.851 2-5.5 2z"></path>
                  <path d="M5.5 12C2.851 12 0 11.374 0 10V8a.5.5 0 011 0v2c.075.27 1.574 1 4.5 1s4.425-.73 4.501-1.007L10 8a.5.5 0 011 0v2c0 1.374-2.851 2-5.5 2z"></path>
                  <path d="M5.5 14C2.851 14 0 13.374 0 12v-2a.5.5 0 011 0v2c.075.27 1.574 1 4.5 1s4.425-.73 4.501-1.007L10 10a.5.5 0 011 0v2c0 1.374-2.851 2-5.5 2z"></path>
                  <path d="M5.5 16C2.851 16 0 15.374 0 14v-2a.5.5 0 011 0v2c.075.27 1.574 1 4.5 1s4.425-.73 4.501-1.007L10 12a.5.5 0 011 0v2c0 1.374-2.851 2-5.5 2z"></path>
                  <path d="M5.5 18C2.851 18 0 17.374 0 16v-2a.5.5 0 011 0v2c.075.27 1.574 1 4.5 1s4.425-.73 4.501-1.007L10 14a.5.5 0 011 0v2c0 1.374-2.851 2-5.5 2z"></path>
                </g>
              </g>
            </g>
          </svg>
        );
      }
      
      export default Coins;