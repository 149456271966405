import React from "react";

function Cow({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-295 -82)">
          <g transform="translate(295 82)">
            <path d="M9.5 20a.5.5 0 01-.475-.344C8.935 19.389 8.696 19 8.5 19a.5.5 0 110-1c1.005 0 1.43 1.207 1.475 1.344A.5.5 0 019.5 20zM10.5 15.5a.5.5 0 01-.5-.5c0-.478.314-1.084.619-1.672.146-.281.367-.707.382-.839.002-.537.01-1.969-.804-2.592-.638-.487-.864-1.02-1.047-1.449l-.103-.236C8.544 7.135 8.481 7 7.5 7a.5.5 0 110-1c1.618 0 1.927.661 2.438 1.757.055.119.094.208.132.3.164.386.305.719.733 1.046 1.21.925 1.2 2.731 1.197 3.325 0 .392-.188.77-.494 1.36-.201.39-.506.979-.506 1.212a.5.5 0 01-.5.5z"></path>
            <path d="M7 7C2.159 7 1.366 2.58 1.068.916l-.059-.32a.5.5 0 01.953-.287C3.119 3.092 7.218 4 8.5 4a.5.5 0 010 1c-.861 0-3.914-.449-5.959-2.249C3.103 4.345 4.267 6 7 6a.5.5 0 010 1z"></path>
            <path d="M5.5 11C3.115 11 .324 8.99.206 8.904a.5.5 0 01-.128-.672c1.514-2.393 4.07-2.308 5.757-2.248.237.009.461.016.665.016a.5.5 0 010 1 19.8 19.8 0 01-.699-.016c-1.515-.052-3.369-.114-4.595 1.389C2.028 8.901 3.928 10 5.5 10a.5.5 0 010 1z"></path>
            <path d="M17 18a.498.498 0 01-.484-.379l-.5-2A.447.447 0 0116 15.5c0-1.153.64-1.904 1.205-2.566.427-.499.795-.93.795-1.434 0-.625-.215-.981-.463-1.395C17.285 9.688 17 9.215 17 8.5 17 6.268 14.382 4 12.5 4h-1C9.618 4 7 6.268 7 8.5c0 .715-.285 1.188-.537 1.605-.248.414-.463.771-.463 1.395 0 .503.368.935.795 1.434C7.36 13.597 8 14.347 8 15.5a.49.49 0 01-.015.121l-.5 2a.5.5 0 11-.97-.242l.484-1.937c-.022-.754-.48-1.291-.965-1.858C5.526 12.988 5 12.373 5 11.5c0-.902.336-1.461.607-1.91.22-.364.393-.653.393-1.09C6 5.667 9.099 3 11.5 3h1C14.901 3 18 5.667 18 8.5c0 .437.173.725.393 1.09.271.449.607 1.008.607 1.91 0 .872-.526 1.488-1.034 2.083-.484.567-.942 1.104-.965 1.858l.484 1.937A.5.5 0 0117 18z"></path>
            <path d="M14.5 22c-.7 0-1.138-.323-1.49-.582-.318-.233-.568-.418-1.01-.418-.442 0-.692.185-1.01.418-.352.26-.79.582-1.49.582C6.455 22 6 20.434 6 19.5c0-2.533 1.443-2.865 2.398-3.085.32-.074.623-.143.748-.269.176-.176.309-.407.45-.65C9.98 14.83 10.458 14 12 14c1.542 0 2.02.83 2.404 1.496.141.244.274.474.45.65.125.125.428.195.748.269.955.22 2.398.552 2.398 3.085 0 .934-.455 2.5-3.5 2.5zM12 20c.77 0 1.231.34 1.603.613.305.225.525.387.897.387 1.659 0 2.5-.505 2.5-1.5 0-1.737-.716-1.902-1.623-2.11-.443-.102-.902-.208-1.231-.536-.27-.269-.45-.582-.609-.858-.345-.6-.574-.996-1.537-.996s-1.192.396-1.538.996c-.159.276-.339.589-.609.858-.329.329-.788.434-1.231.536C7.716 17.599 7 17.763 7 19.5c0 .996.841 1.5 2.5 1.5.372 0 .592-.163.897-.387C10.769 20.34 11.23 20 12 20z"></path>
            <path d="M12 24c-1.901 0-2.706-.891-3.416-1.677a11.888 11.888 0 00-.438-.469.5.5 0 01.707-.707c.166.166.321.337.473.506C10.006 22.406 10.543 23 12 23c1.457 0 1.994-.595 2.673-1.348.152-.169.307-.34.473-.506a.5.5 0 01.707.707c-.154.154-.297.313-.438.469C14.706 23.109 13.901 24 12 24zM14.5 20a.499.499 0 01-.475-.656c.045-.137.47-1.344 1.475-1.344a.5.5 0 010 1c-.206 0-.448.42-.525.656A.5.5 0 0114.5 20zM17 7a.5.5 0 010-1c2.733 0 3.897-1.655 4.459-3.249C19.414 4.551 16.361 5 15.5 5a.5.5 0 110-1c1.282 0 5.381-.909 6.538-3.692a.5.5 0 01.952.287l-.059.32C22.634 2.58 21.841 7 17 7z"></path>
            <path d="M18.5 11a.5.5 0 010-1c1.572 0 3.472-1.099 4.294-1.627-1.227-1.504-3.083-1.443-4.595-1.389A19.8 19.8 0 0117.5 7a.5.5 0 110-1c.204 0 .428-.008.666-.016 1.685-.063 4.243-.145 5.757 2.248a.5.5 0 01-.128.672C23.676 8.99 20.885 11 18.5 11z"></path>
            <path d="M8.5 13a1.501 1.501 0 010-3c.827 0 1.5.673 1.5 1.5S9.327 13 8.5 13zm0-2a.5.5 0 10.002 1.002A.5.5 0 008.5 11zM15.5 13c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5zm0-2a.5.5 0 100 1 .5.5 0 000-1z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Cow;