import React from 'react';
import { Link } from "react-router-dom";

import './navigation.css';
import House from '../../../icons/house';
import IconSection from '../../../components/iconSection/iconSection';
import Trust from '../../../icons/trust';
import Discussion from '../../../icons/discussion';
import Sunshade from '../../../icons/sunshade';
import Gold from '../../../icons/gold';
import Beginner from '../../../icons/beginner';
import Scales from '../../../icons/scales';

const Navigation = ((props) => {

 return (
     <div className='nav'>
        <ul>
            <li>
                
            <Link to="/" className='wHov'>
                <IconSection>
                    <House/>
                    <p>Home</p>

                </IconSection>    
            
            </Link>
            </li>
            <li>
            <Link to="/why" className='wHov'>
                <IconSection>
                    <Scales/>   
                    <p>Why advice?</p>
                </IconSection> 
            </Link>
            </li>
            <li>
            <Link to="/advice" className='wHov'>
                <IconSection>
                    <Discussion/>
                    <p>Get advice</p>
                </IconSection> 
            </Link>
            </li>
            
            <li>
            <Link to="/createwealth" className='wHov'>
                <IconSection>
                    <Gold/>
                    <p>How to create wealth</p>
                </IconSection> 
            </Link>
            </li>
            <li>
            <Link to="/protectwealth" className='wHov'>
                <IconSection>
                    <Trust/>
                    <p>How to protect wealth</p>

                </IconSection> 
            </Link>
            </li>
            <li>
            <Link to="/retire" className='wHov'>
                <IconSection>
                    <Sunshade/>
                    <p>How to retire</p>
                </IconSection> 
            </Link>
            </li>
            <li>   
            <Link to="/about" className='wHov'>
            <IconSection>
                    <Beginner/>
                    <p>About us</p>

                </IconSection>
                </Link>
            </li>
        </ul>
    </div>
 )

})

export default Navigation;