import React from "react";

function Tick(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 171 171"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#2CC618"
        strokeWidth="1"
        transform="translate(-95 -269) translate(20 163) translate(81 112)"
      >
        <path
          strokeLinecap="round"
          strokeWidth="7.8"
          d="M64.338 109.802l46.725-58.592M63.583 110.779L45.615 97.722"
        ></path>
        <circle cx="79.5" cy="79.5" r="79.5" strokeWidth="10.4"></circle>
      </g>
    </svg>
  );
}

export default Tick;