import React from "react";

function LinkedIn({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-227 -489)"
      >
        <rect
          width="286"
          height="390"
          x="69"
          y="149"
          stroke="#E8E3E3"
          strokeWidth="2"
          rx="4"
        ></rect>
        <g fill="#303030" fillRule="nonzero">
          <path
            d="M3 6.002c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-5c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2zM5.5 24.002h-5a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v15a.5.5 0 01-.5.5zm-4.5-1h4v-14H1v14zM23.5 24.002h-5a.5.5 0 01-.5-.5v-9c0-1.135-.473-1.987-1.299-2.336-.853-.362-1.894-.14-2.701.556v10.78a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5h5a.5.5 0 01.484.375c1.604-1.154 4.276-1.796 6.413-1.064 1.613.553 3.546 2.073 3.603 6.183v10.007a.5.5 0 01-.5.499zm-4.5-1h4v-9.5c-.038-2.785-1.051-4.601-2.927-5.243-2.33-.798-5.266.407-6.183 1.555a.501.501 0 01-.89-.312v-.5H9v14h4v-10.5a.5.5 0 01.151-.358c1.118-1.086 2.667-1.436 3.939-.899 1.214.514 1.91 1.701 1.909 3.257v8.5H19z"
            transform="translate(227 489)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default LinkedIn;