import React from "react";

function Pie({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-610 -251)">
          <g transform="translate(610 251)">
            <path d="M12.5 12h11a.5.5 0 00.5-.5C24 5.159 18.841 0 12.5 0a.5.5 0 00-.5.5v11a.5.5 0 00.5.5zM13 1.012c5.393.254 9.734 4.595 9.988 9.988H13V1.012zM23.5 13h-10a.499.499 0 00-.389.814l6.5 8.047a.497.497 0 00.354.185l.035.001a.503.503 0 00.341-.134A11.545 11.545 0 0024 13.5a.5.5 0 00-.5-.5zm-3.457 7.804L14.547 14h8.441a10.55 10.55 0 01-2.945 6.804zM11 12.329V1.5a.5.5 0 00-.5-.5C4.71 1 0 6.159 0 12.5S5.159 24 11.5 24c2.837 0 4.372-.514 6.318-2.114a.5.5 0 00.077-.693L11 12.329zM11.5 23C5.71 23 1 18.29 1 12.5 1 6.896 4.993 2.303 10 2.014V12.5c0 .111.037.219.106.307l6.696 8.609C15.223 22.622 13.906 23 11.5 23z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Pie;