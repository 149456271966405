import React from "react";

function Threshold({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 26 23"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#303030" transform="translate(-99 -278)">
          <g transform="translate(100 277)">
            <path
              fillRule="nonzero"
              d="M4.33082707 11.1428571L9.7443609 11.1428571 9.7443609 23.1428571 14.075188 23.1428571 14.075188 11.1428571 19.4887218 11.1428571 11.9097744 5.14285714z"
            ></path>
            <path
              fill="#303030"
              strokeLinecap="round"
              d="M0 1.714L23.82 1.714"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Threshold;