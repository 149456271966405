import React from "react";

function Health({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g fill="#303030" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path
          d="M12 18a.503.503 0 01-.464-.343L7.854 7.617 4.39 12.342a.484.484 0 01-.39.204H1c-.276 0-.5-.244-.5-.545 0-.301.224-.545.5-.545h2.76l3.85-5.251a.481.481 0 01.464-.199.51.51 0 01.391.338l3.426 9.342.625-2.727a.508.508 0 01.484-.413h.64l1.886-6.172a.509.509 0 01.46-.373c.205.001.4.13.479.343L20 15.986l1.536-4.188a.501.501 0 01.464-.342h1c.276 0 .5.244.5.545 0 .301-.224.546-.5.546h-.661l-1.875 5.112c-.15.414-.777.414-.928 0l-3.493-9.526-1.568 5.132a.509.509 0 01-.475.371h-.609l-.905 3.95a.516.516 0 01-.451.413L12 18z"
          transform="translate(-119 -73) translate(119 73)"
        ></path>
        <path
          d="M22.395 10.364a.477.477 0 01-.229-.06c-.245-.138-.343-.466-.217-.735a5.786 5.786 0 00.551-2.478c0-3.008-2.243-5.455-5-5.455s-5 2.447-5 5.455c0 .301-.224.545-.5.545s-.5-.244-.5-.545c0-3.008-2.243-5.455-5-5.455s-5 2.447-5 5.455c0 .867.186 1.702.551 2.478.126.269.028.597-.217.735s-.547.032-.674-.237A6.96 6.96 0 01.5 7.09C.5 3.48 3.191.545 6.5.545c2.456 0 4.573 1.62 5.5 3.931.927-2.312 3.044-3.93 5.5-3.93 3.309 0 6 2.935 6 6.545a6.96 6.96 0 01-.66 2.976.497.497 0 01-.445.297zM12 23.455a.48.48 0 01-.376-.187l-7-8.727a.58.58 0 01.047-.77.47.47 0 01.705.05l6.617 8.251 5.125-6.606a.474.474 0 01.705-.065.581.581 0 01.059.77l-5.5 7.09a.502.502 0 01-.382.194z"
          transform="translate(-119 -73) translate(119 73)"
        ></path>
      </g>
    </svg>
  );
}

export default Health;