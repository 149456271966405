import React from "react";

function Chest({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-67 -146)">
          <g transform="translate(67 146)">
            <path d="M23.5 19.715H.5a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h23a.5.5 0 01.5.5v12a.5.5 0 01-.5.5zm-22.5-1h22v-11H1v11z"></path>
            <path d="M21.5 17.715h-19a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h7a.5.5 0 010 1H3v7h18v-7h-6.5a.5.5 0 010-1h7a.5.5 0 01.5.5v8a.5.5 0 01-.5.5z"></path>
            <path d="M12 12.715c-1.654 0-3-1.346-3-3v-2.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v2.5c0 1.654-1.346 3-3 3zm-2-5v2c0 1.103.897 2 2 2s2-.897 2-2v-2h-4z"></path>
            <path d="M23.5 7.715a.5.5 0 01-.5-.5v-2c0-1.93-1.57-3.5-3.5-3.5h-15c-1.93 0-3.5 1.57-3.5 3.5v2a.5.5 0 01-1 0v-2c0-2.481 2.019-4.5 4.5-4.5h15c2.481 0 4.5 2.019 4.5 4.5v2a.5.5 0 01-.5.5z"></path>
            <path d="M2.5 7.715a.5.5 0 01-.5-.5v-1c0-3.033 2.467-5.5 5.5-5.5a.5.5 0 010 1 4.505 4.505 0 00-4.5 4.5v1a.5.5 0 01-.5.5zM21.5 7.715a.5.5 0 01-.5-.5v-1c0-2.481-2.019-4.5-4.5-4.5a.5.5 0 010-1c3.033 0 5.5 2.467 5.5 5.5v1a.5.5 0 01-.5.5zM21.5 13.715h-19a.5.5 0 010-1h19a.5.5 0 010 1z"></path>
            <path d="M20.5 4.715h-17a.5.5 0 010-1h17a.5.5 0 010 1z"></path>
            <circle cx="12" cy="9.215" r="1"></circle>
            <path d="M12 10.715a.5.5 0 01-.5-.5v-1a.5.5 0 011 0v1a.5.5 0 01-.5.5z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Chest;