import React from 'react';
import ReactGA from "react-ga";

import { withRouter } from "react-router-dom";


import BannerHeader from '../../components/bannerHeader/bannerHeader';

import Book from '../../icons/book';
import Pen from '../../icons/pen';
import Discussion from '../../icons/discussion';

const About = (props) => {

    ReactGA.pageview('/about');

    const feedback = () => {
        const url = 'https://www.surveymonkey.co.uk/r/MR97FDW'
        window.open(url, '_blank');
    }


        return (

            <React.Fragment>

                <div className='banner advice'>


                      <BannerHeader title="About" subHeader="Why we exist"/> 
                        

                        


                </div>

                <div className='containerSingle'>

                        <div className='content-area'>

                

                    <div className='card standardPadding'>

                            <div className='widthControl espace'>

                            <Book width='50' height='48'/>

                            <h3>The Creation Story</h3>

                            <p>My view of financial advice a decade ago was highly negative. </p>
                                
                            <p>To paint a picture, it was a stereotypical car salesman pushing a financial product. A product that was neither right or wrong for me but certainly good for them.</p>

                            <p>Since then, I started a career in financial services and it has become increasingly clear to me how beneficial financial advice can be. </p>

                            <p>This site was set up to help individuals learn what took me a decade of working in the industry to learn. </p>
                            
                            <p>That financial advice may be very worthwhile.</p>

                            <p>I hope you find this site useful.</p>

                            </div>

                                
                        </div>


                        <div className='card standardPadding'>

                            <div className='widthControl espace'>

                                <Pen width='50' height='48'/>

                                <h3>How can you help?</h3>

                                <p className='centre'>Please provide feedback, both good and bad, by completing the below survey.</p>

                                <button className='actionButton' onClick={feedback}>Give Feedback</button>

  
                            </div>

                            
                        </div>

                        <div className='card standardPadding'>

                        <div className='widthControl espace'>

                            <Discussion width='50' height='48'/>
    

                            <h3>Contact</h3>

                            <p className='centre'>To contact, please complete the feedback form and provide your contact details. I'll contact you shortly afterwards.</p>
                            

                            <button className='actionButton' onClick={feedback}>Give Feedback</button>


                            </div>

                            
                        </div>

           

                    </div>

                </div>

            </React.Fragment>
        )



    }

    export default withRouter(About);
