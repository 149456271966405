import React from "react";

function Warning({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-222 -199)">
          <g transform="translate(222 199)">
            <path d="M9.62 6.611a1.86 1.86 0 00-.471 1.431L10 16.55a.5.5 0 00.498.45h1a.498.498 0 00.497-.45l.851-8.508a1.86 1.86 0 00-.471-1.431c-.703-.777-2.052-.777-2.755 0zM11.046 16h-.095l-.804-8.058a.843.843 0 01.217-.66.878.878 0 011.271 0 .849.849 0 01.217.66L11.046 16zm10.539 2.861L14.071 1.824A3.454 3.454 0 0010.998 0c-1.301 0-2.45.682-3.091 1.862L.42 18.843a3.47 3.47 0 00.073 3.444A3.472 3.472 0 003.503 24h14.991a3.47 3.47 0 003.009-1.713 3.449 3.449 0 00.082-3.426zm-.942 2.916a2.478 2.478 0 01-2.15 1.224H3.502a2.478 2.478 0 01-2.15-1.224 2.479 2.479 0 01-.026-2.511L8.803 2.303A2.467 2.467 0 0110.998 1c.929 0 1.75.487 2.176 1.266l7.514 17.037c.427.783.41 1.707-.045 2.474zM10.998 18c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5 1.5-.673 1.5-1.5-.673-1.5-1.5-1.5zm0 2A.5.5 0 1111 18.998.5.5 0 0110.998 20z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Warning;