import React from 'react';
import './textInput.css';
import * as ValFn from '../../validations/formValidations'


const TextInput = ({question, withInputChange}) => {

    

        return (
            <div className='inputWrap'>
                <label className="fw5">{question.title}</label>
                <input type='text' id={question.id} value={question.type === 'Currency' ? ValFn.numberToCurrency(question.value): question.value } onChange={withInputChange}></input>
            </div>
        )
}


export default TextInput;