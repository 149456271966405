import React from 'react';

import ActionCard from '../../components/actionCard/actionCard';
import CardHeader from '../../components/cardHeader/cardHeader';




const WealthProduct = (props) => {

    const isa = {
        title: 'ISA',
        description: 'ISAs are special savings wrappers that are exempt from Capital Gains (and Dividend) Tax.',
        pros: ['They allow you to invest in certain investments without paying any tax on gains and returns.'],
        cons: ['The investments may be limited to those available within the investment platform.'],
        warnings: ['Within a tax year you can only invest up to £20,000. If you do not use the allowance in a given tax year, you lose it.'],
        steps: ['If you are looking to invest, find an ISA and use your ISA allowance to do so.'],
        icon: 'TaxFree',
        }


    const lisa = {
        title: 'Lifetime ISA',
        description: 'Lifetime ISAs provide a 25% bonus on all contributions up to £4,000 for those saving for their first property or retirement between the ages of 18 and 39. All investment returns are exempt from Capital Gains (and Dividend) Tax like normal ISAs.',
        pros: ['A free 25% bonus on annual contributions and no dividend or capital gains tax.'],
        cons: ['Only allowed to invest up to £4,000 annually within a LISA.'],
        warnings: ['Significant penalties will be incurred if you do not use the funds to purchase your first property or try to access before your 60th Birthday.'],
        steps: ['Learn if you should find a cash or stocks and shares ISA'],
        show: [{knowledge: 'Knowledgeable', time: 'Some'}],
        icon: 'House',
        }

        const retlisa = {
            title: 'Lifetime ISA',
            description: 'Lifetime ISAs provide a 25% bonus on all contributions up to £4,000 for those saving for retirement between the ages of 18 and 39. All investment returns are exempt from Capital Gains (and Dividend) Tax like normal ISAs. Funds can only be accessed after 55 without incurring heavy penalties.',
            pros: ['A free 25% bonus on annual contributions and no dividend or capital gains tax.'],
            cons: ['You will not receive tax relief on contributions and are only allowed to invest up to £4,000 annually within a LISA.'],
            warnings: ['Significant penalties will be incurred if you do not use the funds to purchase your first property or try to access before your 60th Birthday.'],
            steps: ['Look to invest existing capital into a lisas and income into pensions.'],
            show: [{knowledge: 'Knowledgeable', time: 'Some'}],
            icon: 'Stick',
            }

        const gia = {
            title: 'General Investment Account (GIA)',
            description: 'GIAs offer you a quick and easy way to invest in the stock market.  Most GIAs provide you with ability to invest in both funds and indiviudal stocks and shares.',
            pros: ['GIAs often have a greater range of investment options and do not have restrictions on the amount that can be invested.'],
            cons: ['You will be taxed on any dividends and capital gains received.  This will require a tax return to be completed.'],
            steps: ['Look to use a GIA only once your ISA allowance has been used up'],
            icon: 'Invest',
            }


        const sipp = {
            title: 'Self Invested Personal Pension (SIPP)',
            description: 'A SIPP is pension wrapper with more investment options than your average personal pension. Like a normal personal pension a SIPP provides a tax efficient means to save for the long term.',
            pros: ['If you are a higher rate tax payer, you get a 40% increase in pension contribution for free.  Basic Rate tax payers, get a 20% increase for free.'],
            cons: ['Costs are often higher than typical personal pensions. If you are employed, you may forgo employer contribution by investing in a SIPP. Like all pensions, they must be accessed after 55.'],
            warnings: ['There is a cap on total pension contributions that receive tax relief in a given year called annual allowance.'],
            steps: ['If you are looking to have greater control of your pension investments, look to transfer existing pensions to a SIPP.'],
            show: [{knowledge: 'Knowledgeable', time: 'Some'}],
            icon: 'ActiveFund',
            }

            const personalpension = {
                title: 'Personal Pension',
                description: 'A Personal Pension is a tax efficient means to save for the long term.  Personal pensions are often set up by employers to help their staff provide for their retirement.',
                pros: ['If you are a higher rate tax payer, you get a 40% increase in pension contribution for free.  Basic Rate tax payers, get a 20% increase for free.  Employers often match contributions up to a level.'],
                cons: ['Your money can not be accessed before 55 except under special circumstances.  From 55, once you have taken your tax-free cash, any withdrawals will be taxed as income.'],
                warnings: ['There is a cap on total pension contributions that receive tax relief in a given year called annual allowance.'],
                steps: ['If you are looking to save for the long term and access your savings after 55, look at investing income into a pension instead of an ISA to benefit from income tax relief.'],
                show: [{knowledge: 'Knowledgeable', time: 'Some'}],
                icon: 'Sunshade',
                }



        return (

            <div>

                <CardHeader title='Consider the following products to invest in'/>

                <div className='standardPadding'>

                { props.results.objective === 'House Deposit' ? <ActionCard option={lisa}/> : null }

                { props.results.objective === 'Retirement' && props.results.knowledge !== 'Beginner' ? <ActionCard option={sipp}/> : null }

                { props.results.objective === 'Retirement' && props.results.knowledge === 'Beginner' ? <ActionCard option={personalpension}/> : null }

                { props.results.objective !== 'Retirement' ? <ActionCard option={isa}/> : null }

                { props.results.objective === 'Wealth' ? <ActionCard option={gia}/> : null }

                { props.results.objective === 'Retirement' ? <ActionCard option={retlisa}/> : null }

                </div>


            </div>


                
        )



    }

    export default WealthProduct;