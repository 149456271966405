import React from 'react';
import './iconHead.css';
import Fade from 'react-reveal/Fade';


const IconHead = (props) => {

        let classes;

        if(props.nounderline){
            classes='iconHead'
        } else {
            classes='iconHead uBordLine'
        }


        
        return (
            <Fade delay={50}>
            <div className={classes}>
                
                {props.children}

                <h4 className='centre bling'>{props.title}</h4>

                <p className='centre'>{props.wording}</p>

            </div>
            </Fade>
            
        )
}


export default IconHead;
