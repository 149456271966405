import React from 'react';
import ReactGA from "react-ga";

import { withRouter } from "react-router-dom";

import ExpandableCard from '../../components/expandableCard/expandableCard'

import WealthOpportunity from './WealthOpportunity';
import WealthInvest from './WealthInvest';
import WealthPrinciples from './WealthPrinciples';
import WealthYou from './WealthYou';
import WealthProperty from './WealthProperty';
import Aside from '../Aside/Aside';
import CardHeader from '../../components/cardHeader/cardHeader';
import BannerHeader from '../../components/bannerHeader/bannerHeader';


const DIYWealth = (props) => {

    ReactGA.pageview('/CreateWealth');


    const linkToDIYWealthProtect = () => {
        // ReactGA.event({
        //     category: 'link',
        //     action: 'about to home'
        //   });
        window.scrollTo(0, 0);
        props.history.push('/protectwealth')
      }


        return (

            <div>

                <div className='banner coins cover'>

                        <BannerHeader title="How to Create Wealth" subHeader="6 steps to financial freedom"/>

                </div>

                <div className='container'>

                    <div className='content-area'>
                        
                        <WealthPrinciples/>
                        <WealthOpportunity/>
                        <WealthYou/>
                        <WealthInvest/>
                        <WealthProperty/>
                        <div>

                            <ExpandableCard title='Protect'>

                                <div className='expandLeftSide'>
                                    <p className=''>6</p>
                                </div>

                                <div>

                                    <CardHeader title='Protect the wealth you have built by' primary='yes'/>
                                    
                                    <div className='standardPadding'>

                                        <p className='centre'>Viewing our 'How to Protect Wealth' section</p>

                                        <button className='actionButton b' onClick={linkToDIYWealthProtect}>How to Protect Wealth</button>
                                        
                                    </div>
                                </div>

                            </ExpandableCard>
                        </div>
                    </div>

                    <Aside/>



                    
                </div>


                {/* <div className='content'>

                </div> */}

            </div>
        )



    }

    export default withRouter(DIYWealth);
