
import React from 'react';
// import ReactGA from "react-ga";

import ExpandableCard from '../../components/expandableCard/expandableCard'
import ActionCard from '../../components/actionCard/actionCard';
import CardHeader from '../../components/cardHeader/cardHeader';

const ProtectTax = () => {

    // https://www.gov.uk/income-tax

    // https://www.gov.uk/capital-gains-tax 

    // https://www.gov.uk/individual-savings-accounts 


    const incomeTax = [

        {
        title: 'Pensions',
        description: 'Pension Contributions receive income tax relief',
        pros: ['If you are a higher rate tax payer, you get a 40% increase in pension contribution for free.  Basic Rate tax payers, get a 20% increase for free.'],
        cons: ['You reduce the net income you receive by paying into your pension.'],
        warnings: ['There is a cap on total pension contributions that receive tax relief in a given year called annual allowance.  There is also a cap on the total amount you can have in a pension called lifetime allowance.  Exceeding these allowances will result in more tax.'],
        steps: ['Pay more into your pension'],
        icon: 'Sunshade',
        },
        {
        title: 'Dividends',
        description: 'If you are a director of a limited company, you can choose to receive dividends from your company instead of income.  This may reduce your tax liability',
        pros: ['Dividends have a lower tax rate than income'],
        cons: ['Dividends have lower tax-free allowance'],
        steps: ['Speak to an accountant'],
        icon: 'Income',
        },
        ]

        const capitalGainsTax = [

            {
            title: 'Timing',
            description: 'In any given tax year, CGT is only applied to gains above your annual allowance.  Timing the selling, or partial selling of assets will allow for the effective use of this allowance',
            pros: ['By spreading the selling of assets over multiple tax years, you pay less CGT by making full use of your annual allowance of £12,000. '],
            cons: ['The CGT tax rate is also tiered to your income tax rate. Meaning you may pay more CGT if you delay to a year in which you have a higher tax rate.'],
            warnings: ['If you do not use your allowance, you lose it.'],
            steps: ['Before selling an asset, ask if you would benefit from delaying the sale until the next tax year'],
            icon: 'Clock',
            },
            {
            title: 'Spouse',
            description: 'If your spouse has an untouched allowance or pays a lower rate of income tax than you, you can reduce the overall CGT liability by transferring and selling the assets through them',
            pros: ['Lower CGT liability if you spouse has an unused allowance and a lower income tax rate'],
            cons: ['You have to transfer your assets to your spouse'],
            steps: ['Transfer ownership of assets to your spouse and sell them through them if appropriate.'],
            icon: 'Family',
            },
            {
            title: 'ISAs',
            description: 'ISAs are special savings wrappers that are exempt from Capital Gains (and Dividend) Tax.',
            pros: ['They allow you to invest in certain investments without paying any tax on gains and returns.'],
            cons: ['The investments are limited to those available within the investment platform'],
            warnings: ['Within a tax year you can only invest up to £20,000. If you do not use the allowance in a given tax year, you lose it.'],
            steps: ['If you are looking to invest, find an ISA and use your ISA allowance to do so.'],
            icon: 'TaxFree',
            },
            ]




        return (

            <div>
            
                                <ExpandableCard title='Tax'>

                                <div className='expandLeftSide Opacninety'>
                                    <p className=''>4</p>
                                </div>
                                
                    
                                <div>

                                    <CardHeader title='Income Tax' primary='yes'/>

                                    <div className='standardPadding'> 

                                        { incomeTax.map((action) => {
                                            return <ActionCard option={action}/>
                                        }) }

                                    </div>

                                <CardHeader title='Capital Gains Tax'/>

                                    <div className='standardPadding'> 

                                    { capitalGainsTax.map((action) => {
                                        return <ActionCard option={action}/>
                                    }) }

                                </div>

                                </div>

                            </ExpandableCard>
                        </div>  
                
        )



    }

    export default ProtectTax;



