import React from "react";

function Coach({width=24, height=20}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 20"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="2"
        transform="translate(-177 -520)"
      >
        <g fill="#303030" fillRule="nonzero">
          <path
            d="M6.5 19.5a6.45 6.45 0 01-4.666-1.974A6.453 6.453 0 01.003 12.8c.104-3.474 3.132-6.3 6.749-6.3H8.5A.5.5 0 019 7v1.5h2V7a.5.5 0 01.5-.5h12c.18 0 .347.097.436.253a.502.502 0 01-.007.504l-3 5a.499.499 0 01-.367.239l-6.455.807c-.625.079-1.131.52-1.258 1.099C12.198 17.356 9.528 19.5 6.5 19.5zm.252-12c-3.082 0-5.661 2.391-5.749 5.331a5.46 5.46 0 001.549 3.999A5.458 5.458 0 006.5 18.5c2.563 0 4.821-1.814 5.372-4.313.217-.992 1.065-1.746 2.11-1.877l6.214-.776L22.617 7.5H12V9a.5.5 0 01-.5.5h-3A.5.5 0 018 9V7.5H6.752zM9.5 5.5a.502.502 0 01-.354-.146l-2-2a.5.5 0 01.707-.707l2 2A.5.5 0 019.5 5.5zM10.5 3.5A.5.5 0 0110 3V1a.5.5 0 011 0v2a.5.5 0 01-.5.5z"
            transform="translate(177 520)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Coach;