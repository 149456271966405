import React from "react";

function CostAverage({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-487 -288)">
          <g transform="translate(487 288)">
            <path
              fill="#303030"
              fillRule="nonzero"
              d="M23.5 21H3V.5a.5.5 0 00-1 0V21H.5a.5.5 0 000 1H2v1.5a.5.5 0 001 0V22h20.5a.5.5 0 000-1z"
            ></path>
            <path
              fill="#303030"
              fillRule="nonzero"
              d="M6.174 15c1.198 0 2.174-1.01 2.174-2.25 0-.206-.036-.402-.086-.592l4.047-2.513c.398.517 1.004.855 1.691.855.856 0 1.591-.52 1.945-1.266l3.718 1.154c-.002.038-.01.074-.01.112 0 1.24.975 2.25 2.173 2.25S24 11.74 24 10.5s-.976-2.25-2.174-2.25c-.856 0-1.591.52-1.945 1.266l-3.718-1.154c.002-.038.01-.074.01-.112C16.174 7.01 15.199 6 14 6s-2.174 1.01-2.174 2.25c0 .206.036.402.086.591l-4.047 2.514c-.398-.517-1.004-.855-1.691-.855C4.976 10.5 4 11.51 4 12.75S4.976 15 6.174 15zm15.652-5.85c.72 0 1.304.606 1.304 1.35 0 .744-.585 1.35-1.304 1.35-.719 0-1.304-.606-1.304-1.35 0-.744.585-1.35 1.304-1.35zM14 6.9c.72 0 1.304.606 1.304 1.35 0 .744-.585 1.35-1.304 1.35-.72 0-1.304-.606-1.304-1.35 0-.744.585-1.35 1.304-1.35zm-7.826 4.5c.719 0 1.304.606 1.304 1.35 0 .744-.585 1.35-1.304 1.35-.72 0-1.304-.606-1.304-1.35 0-.744.585-1.35 1.304-1.35z"
            ></path>
            <path stroke="#303030" strokeLinecap="round" d="M6 17L6 21"></path>
            <path
              stroke="#303030"
              strokeLinecap="round"
              d="M22 14L22 21"
            ></path>
            <path
              stroke="#303030"
              strokeLinecap="round"
              d="M14 12L14 21"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CostAverage;