import React from "react";

function Surf({width=22, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 22 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-337 -171)">
          <g transform="translate(337 171)">
            <path d="M21.423 5.573c-1.911-1.908-7.212 1.273-11.334 5.038a.5.5 0 00.675.739c4.296-3.923 7.992-5.661 9.484-5.306l-9.525 9.525a.5.5 0 00.707.707l9.524-9.524c.43 1.7-1.913 6.156-6.601 10.843-1.664 1.664-3.44 3.155-5.135 4.312a.5.5 0 00.563.825c1.746-1.192 3.571-2.724 5.278-4.431 4.629-4.627 8.431-10.661 6.364-12.728zM4.499 0c-2.923 0-4.5 6.956-4.5 13.5 0 3.93.538 7.638 1.476 10.173a.5.5 0 00.469.327h5.11a.497.497 0 00.28-.088c.057-.039.092-.101.129-.161.016-.025.043-.039.054-.068l.001-.003.005-.007c.938-2.535 1.476-6.243 1.476-10.173 0-6.544-1.577-13.5-4.5-13.5zM2.297 23C1.47 20.583.999 17.149.999 13.5c0-3.36.386-6.247.961-8.403C3.713 6.36 3.999 8.684 3.999 10c0 .858-.454 1.671-.936 2.531-.512.916-1.042 1.864-1.065 2.958-.041 1.935-.067 3.214 2.266 4.452.678.36.688.742.705 1.375.013.501.032 1.115.48 1.684H2.297zm4.464-.192c-.755-.427-.776-.9-.793-1.519-.018-.674-.042-1.598-1.235-2.231-1.789-.949-1.774-1.656-1.734-3.547.018-.845.465-1.644.938-2.491C4.46 12.085 5 11.119 5 10c0-1.581-.355-4.452-2.734-5.923C2.914 2.132 3.726 1 4.499 1c1.655 0 3.5 5.134 3.5 12.5 0 3.557-.45 6.902-1.238 9.308z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Surf;