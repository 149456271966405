import React from 'react';
import Tick from '../../icons/tick';
import Maybe from '../../icons/maybe';
import Cross from '../../icons/cross';




const ScoreCard = (props) => {

    let score = props.score
    let picture;


    if (score.overall === 'Yes') {
        picture = <div className='scoreImageWrap animate-pulse anim-delay-first'><Tick/></div>
    } else if(score.overall  === 'No'){
        picture = <div className='scoreImageWrap animate-pulse anim-delay-first'><Cross/></div>
    }else{
        picture = <div className='scoreImageWrap animate-pulse anim-delay-first'><Maybe/></div>
    }




  return (
          <div className=''>

                <h1 className='bannerTitle white animate-pop-in'>It's a {score.overall}!</h1>
            
                {picture}

                <p className='centre bannerSecTitle animate-fade-in anim-delay-seventh'>Here's why</p>

            
          
          </div>
  )

};

export default ScoreCard;

