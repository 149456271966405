import React from 'react';
import ReactGA from "react-ga";

import './home.css';

import { withRouter } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import IconFinder from '../../components/iconfinder/iconfinder';


const Home = React.memo((props) => {

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    ReactGA.pageview('/home');

    const linkToWhy = () => {
        window.scrollTo(0, 0);
        props.history.push('/why')
    }

    const linkToRetire= () => {
        window.scrollTo(0, 0);
        props.history.push('/retire')
    }

    const linkToProtect= () => {
        window.scrollTo(0, 0);
        props.history.push('/protectwealth')
    }

    const linkToCreate= () => {
        window.scrollTo(0, 0);
        props.history.push('/createwealth')
    }


        return (

                <div>

                

                <div className='homeWrap flexWrapCol homeCover'>

                    <div className='homeContainer'>

                        <h1 className='homeText animate-pop-in'>
                            Should you get financial advice?
                        </h1>


                        <div className='standardPadding'>

                        

                            <h2 className='secWhite animate-pop-in anim-delay-second'>
                                Learn if the benefits of advice outweigh the costs
                            </h2>

                            <br></br>

                            <button className='actionButton b animate-fade-in anim-delay-fifth' onClick={linkToWhy}> Find Out More</button>


                        </div>

                    </div>


                    </div>

                    <div className='threeColWrap'>
                        <div className='threeCol'>

                            <Fade delay={50}>

                                <div className='iconHead standardPadding'>
                                    
                                    <IconFinder icon='Gold'/>

                                    <h4 className='centre bling'>How to Create Wealth</h4>

                                    <p className='centre'>A step by step guide to creating wealth</p>

                                    <button className='actionButtonSec b' onClick={linkToCreate}> Find Out More</button>

                                </div>

                             </Fade>
                        </div>

                        <div className='threeCol'>

                            <Fade delay={50}>

                            <div className='iconHead standardPadding'>
                                
                                <IconFinder icon='Trust'/>

                                <h4 className='centre bling'>How to Protect Wealth</h4>

                                <p className='centre'>A guide to protecting the wealth you have</p>

                                <button className='actionButtonSec b' onClick={linkToProtect}> Find Out More</button>

                            </div>

                            </Fade>
                        </div>

                        <div className='threeCol'>

                            <Fade delay={50}>

                                <div className='iconHead standardPadding'>
                                    
                                    <IconFinder icon='Sunshade'/>

                                    <h4 className='centre bling'>How to Retire</h4>

                                    <p className='centre'>A step by step guide to accessing your pension</p>

                                    <button className='actionButtonSec b' onClick={linkToRetire}> Find Out More</button>

                                </div>

                            </Fade>
                        </div>


                        
                    </div>

                    
                    

                </div >

        )



    })

    export default withRouter(Home);
