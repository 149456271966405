


import React from 'react';
import {Radar} from 'react-chartjs-2';


const SpiderGraph = (props) => {

    const data = {
        labels: ['Certainty', 'Effort','Flexibility', 'Tax', 'CFL'],
        datasets: [
          {
            label: props.mainName,
            backgroundColor: 'rgba(254,177,22,0.5)',
            borderColor: 'rgba(254,177,22)',
            pointBackgroundColor: 'rgba(179,181,198,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(179,181,198,1)',
            data: props.mainData
          },
          {
            label: props.optionalName,
            backgroundColor: 'rgba(48,48,48,0.5)',
            borderColor: 'rgba(48,48,48)',
            pointBackgroundColor: 'rgba(179,181,198,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(179,181,198,1)',
            data: props.optionalData
          },

        ]
      };

      const legendOpts = {
        display: false,
      };


      const options = {
        
        scale:{
            color: 'blue',    
            ticks:{
              beginAtZero: true,
              min:0,
              max:5,
              display:false,
            },
            gridLines:{
              display: false,
          },
        },
  
      };


    return (
      <div className="graphWrap">
        <Radar
          data={data}
          width={100}
          height={100}
          legend={legendOpts}
          options={options}
        />
        <div className='canvasOverlay'></div>
      </div>
    );

};

export default SpiderGraph;




