import React from "react";

function Twitter({width=24, height=20}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 20"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-268 -487)"
      >
        <rect
          width="286"
          height="390"
          x="69"
          y="149"
          stroke="#E8E3E3"
          strokeWidth="2"
          rx="4"
        ></rect>
        <path
          fill="#303030"
          fillRule="nonzero"
          d="M8.145 19.449c-2.15 0-4.638-.552-7.39-2.097a.499.499 0 01-.23-.59.495.495 0 01.533-.342c1.814.208 3.795-.217 5.275-1.086-1.695-.398-3.065-1.499-3.788-3.098a.499.499 0 01.602-.684c.218.067.435.113.644.138-1.287-.768-2.665-2.238-2.665-4.441a.5.5 0 01.814-.389c.16.13.331.239.508.325a5.362 5.362 0 01-.869-1.817c-.332-1.282-.139-2.582.557-3.756a.501.501 0 01.821-.057c1.472 1.839 4.088 4.061 8.294 4.466-.078-1.331.322-3.512 2.16-4.585 2.261-1.32 4.436-1.15 6.305.487.828-.184 2.135-.752 2.406-.941a.5.5 0 01.77.538c-.125.471-.408.995-.76 1.463.279-.083.514-.167.639-.231a.5.5 0 01.652.711c-.588.93-1.539 1.796-2.174 2.266.343 4.225-2.126 8.946-6.089 11.577-1.47.975-3.886 2.143-7.015 2.143zM3.15 17.406c5.351 2.195 9.403.428 11.456-.935 3.756-2.494 6.067-6.988 5.62-10.929a.498.498 0 01.222-.474c.269-.177.68-.502 1.094-.905-.407.088-.802.147-1.089.148-.276-.033-.447-.169-.492-.405a.498.498 0 01.306-.559c.283-.113.665-.454.995-.872-.566.219-1.178.413-1.628.478a.497.497 0 01-.413-.131c-1.578-1.482-3.362-1.658-5.306-.524-1.745 1.02-1.784 3.397-1.6 4.148a.499.499 0 01-.513.619C7.331 6.829 4.427 4.719 2.68 2.773a3.808 3.808 0 00-.132 2.344c.294 1.133 1.034 2.002 1.596 2.33a.5.5 0 01-.153.922 3.106 3.106 0 01-1.752-.185c.521 2.085 2.689 3.067 3.299 3.135a.501.501 0 01.268.879c-.437.369-1.091.547-1.828.51.836 1.114 2.132 1.754 3.681 1.788a.5.5 0 01.323.872c-1.228 1.1-2.99 1.823-4.832 2.038z"
          transform="translate(268 487)"
        ></path>
      </g>
    </svg>
  );
}

export default Twitter;