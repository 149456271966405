import React from "react";

function Expert({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 22 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-100 -343)">
          <g transform="translate(100 342)">
            <path d="M13.5 8.48a3.497 3.497 0 00-1.258-2.689 3.527 3.527 0 00-2.895-.752 3.489 3.489 0 00-2.779 2.745 3.497 3.497 0 00.985 3.187c.184.181.348.318.49.436.388.322.457.38.457.914v1.659a1 1 0 001 1 .5.5 0 001 0 1 1 0 001-1v-1.655c0-.527.1-.611.469-.921.175-.148.378-.32.604-.562A3.46 3.46 0 0013.5 8.48zm-3 5.5h-1v-1h1v1zm1.34-3.818c-.192.206-.365.351-.514.476-.371.312-.725.609-.808 1.342H9.484c-.068-.694-.36-.975-.8-1.343a6.906 6.906 0 01-.434-.384 2.464 2.464 0 01-.7-2.277 2.511 2.511 0 011.977-1.952 2.489 2.489 0 012.074.536c.571.477.899 1.177.899 1.92 0 .625-.234 1.223-.66 1.682zm9.66 5.318c0-.709-1.461-3.324-2.19-4.585.068-.36.19-1.183.19-2.415 0-2.135-2.826-7.5-9.5-7.5C3.447.98.5 5.951.5 9.48c0 2.496 1.475 5.054 2.659 7.107.69 1.197 1.341 2.327 1.341 2.893 0 1.273-2.07 3.796-2.867 4.661a.499.499 0 10.734.678c.321-.347 3.133-3.447 3.133-5.339 0-.834-.65-1.963-1.475-3.392C2.9 14.138 1.5 11.709 1.5 9.48c0-2.447 2.078-7.5 8.5-7.5 6.374 0 8.5 5.124 8.5 6.5 0 1.53-.202 2.371-.203 2.381a.508.508 0 00.053.372c1.006 1.728 2.115 3.847 2.152 4.24-.046.138-.564.405-1.104.519a.5.5 0 00-.372.647l.402 1.207-.281.281a.492.492 0 00-.127.452.868.868 0 00-.205.13c-.143.127-.315.365-.315.771 0 .4.168.65.29.833.122.182.21.314.21.667 0 .39-.149.69-2.081 1.007-1.321.216-4.419 1.325-4.419 2.493 0 .276.217.539.493.539.277 0 .493-.185.493-.461.177-.357 1.952-1.316 3.595-1.585 1.231-.2 2.919-.476 2.919-1.993 0-.659-.229-.999-.379-1.224-.102-.151-.121-.188-.184-.28a.504.504 0 00.559-.434.492.492 0 00-.061-.289l.419-.419a.5.5 0 00.121-.512l-.334-1.002c.574-.198 1.359-.606 1.359-1.34z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Expert;