import React from 'react';

import './SideBarToggle.css';

const SideBarToggle = (props) => (
    <div className='SideBarToggle' onClick={props.clicked}>
        <div></div>
        <div></div>
        <div></div>
    </div>
);

export default SideBarToggle;