
import React, {useState} from 'react';
import ReactGA from "react-ga";

import { withRouter } from "react-router-dom";

import RetireKYS from './RetireKYS'
import RetireOptions from './RetireOptions'
import ExpandableCard from '../../components/expandableCard/expandableCard'

import IncomeCalc from './IncomeCalc';
import Cost from '../../icons/cost';
import Trust from '../../icons/trust';
import Options from '../../icons/options';
import Easy from '../../icons/easy';
import Aside from '../Aside/Aside';
import CardHeader from '../../components/cardHeader/cardHeader';
import IconHead from '../../components/iconHead/iconHead';
import Warning from '../../icons/warning';
import Health from '../../icons/health';
import Steps from '../../icons/steps';
import BannerHeader from '../../components/bannerHeader/bannerHeader';

const DIYRetirement = (props) => {

    ReactGA.pageview('/Retire');

    const mas = () => {
        const url = 'https://www.moneyadviceservice.org.uk/en/tools/annuities'
        window.open(url, '_blank');
    }

    const money = () => {
        const url = 'http://www.money.co.uk'
        window.open(url, '_blank');
    }


    const initialQuestions = [
        {id: 1, code:'certainty', title: 'On a scale of 1 to 5 (with 1 being no certainty and 5 being absolute certainty), how much certainty do you want that you\'ll money will last for the rest of your life?', type: 'Radio', value: '', options: ['1', '2','3','4','5']},
        {id: 2, code:'effort', title: 'On a scale of 1 to 5 (with 1 being not prepared and 5 being prepared), how prepared are you to manage your wealth over your retirement?', type: 'Radio', value: '', options: ['1', '2','3','4','5']},
        {id: 3, code:'flexibility', title: 'On a scale of 1 to 5 (with 1 being a consistent income and 5 being a completely flexibile income), what type of income do you want in retirement?', type: 'Radio', value: '', options: ['1', '2','3','4','5']},
        {id: 4, code:'tax', title: 'On a scale of 1 to 5 (with 1 not important and 5 being very important), how important do you think tax optimisation will be when making a decision?', type: 'Radio', value: '', options: ['1', '2','3','4','5']},
        {id: 5, code:'cfl', title: 'On a scale of 1 to 5 (with 1 being unable and 5 being very able),  would you be able to cope if your money halved in a years time?', type: 'Radio', value: '', options: ['1', '2','3','4','5']},
      ];


    const [questions, setQuestions] = useState(initialQuestions);
    const [profileGraphData, setProfileGraphData] = useState([0,0,0,0,0]);
    const [optionalToggle, setOptionalToggle] = useState(true);



    const onInputChange = (event) => {

            const newValue = event.target.value
            const id = questions.findIndex((question) => {
              return question.id === +event.target.id;
            });
  
            const allQuestions = questions.map((question) => {
                if (question.id !== (id + 1)) {
                  return question
                } else {
                  return {
                      ...question,
                      value: newValue
                      }
  
                }
            })

            let tempProfileData = [...profileGraphData];
            tempProfileData[id] = newValue;

            setQuestions(allQuestions)
            setProfileGraphData(tempProfileData)
  
        };

       const onProfileToggle = () => {

            setOptionalToggle(!optionalToggle);

        }

        const linkToDIYWealthProtect = () => {

            window.scrollTo(0, 0);
            props.history.push('/protectwealth')
          }
    


        return (

            

            <div>

                <div className='banner retire'>

                        <BannerHeader title="How to Retire" subHeader="5 steps to accessing your pension"/> 

                        


                </div>

                <div className='container'>

                    <div className='content-area'>




                         <ExpandableCard title='Had enough?'>

                            <div className='expandLeftSide Opacsixty'>
                                <p className=''>1</p>
                            </div>

                                    
                              <IncomeCalc/>
     

                        </ExpandableCard>

                        <ExpandableCard title='Needs'>

                            <div className='expandLeftSide Opacseventy'>
                                <p className=''>2</p>
                            </div>


                            <RetireKYS questions={questions} onInputChange={onInputChange} profileData={profileGraphData}/>

                        </ExpandableCard>

                        <ExpandableCard title='Options'>

                            <div className='expandLeftSide Opaceighty'>
                                <p className=''>3</p>
                            </div>


                            <RetireOptions profileData={optionalToggle ? profileGraphData : null} toggleProfile={optionalToggle} onProfileToggle={onProfileToggle}/>

                         </ExpandableCard>


                        <ExpandableCard title='Risks'>

                                <div className='expandLeftSide Opacninety'>
                                    <p className=''>4</p>
                                </div>
                                
                                <div>

                                <CardHeader title='Prepare for the worst' primary='yes'/>

                                <div className='standardPadding widthControl'>
                                    
                                    <p>View our 'How to Protect Wealth' guide to better understand the risks you'll have in retirement and how to control them. </p>

                                    <button className='actionButton b' onClick={linkToDIYWealthProtect}>How to Protect Wealth</button>

                                
                                </div>

                                </div>

                        </ExpandableCard>

                        <ExpandableCard title='Make it happen'>

                                <div className='expandLeftSide'>
                                    <p className=''>5</p>
                                </div>
                                <div>

                                <CardHeader title='For an Annuity' primary='yes'/>

                                <div className='standardPadding widthControl'>

                                    <IconHead title='Do not go directly to providers' wording='An annuity is an insurance product.  Like car and travel insurance,  sites/ brokers search the market on your behalf to get you the best deal.'>
                                        <Warning width='50' height='48'/>

                                    </IconHead>

                                    <IconHead title='Use your health' wording='Health conditions such a high blood pressure and diabetes may give you a higher annuity income.  If you have a health condition, Look to get an Enhanced Annuity.'>
                                        <Health width='50' height='48'/>
                                    </IconHead>

                                    <div className='iconHead standardPadding'>
                                
                                        <Steps width='50' height='48'/>

                                        <h4 className='centre bling'>Make it happen</h4>

                                        <p className='centre'>If you think an annuity is best for you, the Money Advice Service has a good annuity comparison tool.</p>

                                        <button className='actionButton b' onClick={mas}>Money Advice Service</button>

                                    </div>

                                    
                                </div>

                                <CardHeader title='For Flexible Access Drawdown or UFPLS'/>

                                <div className='standardPadding widthControl'>

                                    <IconHead title='Check costs' wording='Drawdown/ ULPLS providers have many ways of charge you. These include annual platform fees, annual management charges, withdrawal fees and various penalties. Look for low costs. '>
                                        <Cost width='50' height='48'/>
                                        
                                    </IconHead>

                                    <IconHead title='Know your options' wording='Investment options are critical.  Make sure your providers has the range of investments and support you need to manage your money.'>
                                        <Options width='50' height='48'/>
                                        
                                    </IconHead>

                                    <IconHead title='Ensure Reliability' wording='The provider you choose needs to be reliable enough to look after your money. Regulatory accreditation is key.'>
                                        <Trust width='50' height='48'/>
                                        
                                    </IconHead>

                                    <IconHead title='Easy to use' wording='You will be engaging with your provider on a regular basis to manage your money.  You need to ensure they have the tools to make this easy.'>
                                        <Easy width='50' height='48'/>
                                        
                                    </IconHead>

                                    <div className='iconHead standardPadding'>
                                
                                        <Steps width='50' height='48'/>

                                        <h4 className='centre bling'>Make it happen</h4>

                                        <p className='centre'>To find a provider, here's a link to 'Money.co.uk' list</p>

                                        <button className='actionButton b' onClick={money}>Money</button>

                                    </div>



                                </div>

                                    
                                </div>

                    </ExpandableCard>



                    </div>

                    <Aside section='Retire'/>
                </div>

                

            </div>
        )



    }

    export default withRouter(React.memo(DIYRetirement));
