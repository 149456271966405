import React from 'react';
import SideBarToggle from '../SideBarToggle/SideBarToggle';
import { Link } from "react-router-dom";
import './header.css';

const Header = ((props) => {
 return (
     <div className="header">
        <SideBarToggle clicked={props.toggle}/>
        <Link to={'/'}>
            <p className='logo'>Why<span className='bling'>Advice?</span></p>
         </Link>
         <div className='headSpace'></div>
     </div>
 )

})

export default Header;



