import React from "react";

function LifeInsure({width=24, height=22}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 20 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-328 -286)">
          <g transform="translate(328 286)">
            <path d="M10 24a.488.488 0 01-.155-.025C5.851 22.672.5 17.255.5 13.5v-10A.5.5 0 011 3C3.672 3 7.54 2.252 9.646.146a.5.5 0 01.707 0C12.46 2.252 16.328 3 19 3a.5.5 0 01.5.5v10c0 3.755-5.351 9.172-9.345 10.475A.488.488 0 0110 24zM1.5 3.992V13.5c0 2.966 4.646 8.119 8.5 9.472 3.854-1.353 8.5-6.506 8.5-9.472V3.992c-2.684-.085-6.269-.861-8.5-2.808-2.231 1.947-5.816 2.722-8.5 2.808z"></path>
            <path d="M10 22a.499.499 0 01-.2-.042c-3.343-1.461-7.3-6.046-7.3-8.458v-8a.5.5 0 01.447-.497c2.356-.251 4.889-1.155 6.775-2.418a.504.504 0 01.557 0c1.886 1.263 4.419 2.167 6.775 2.418a.5.5 0 01.446.497v8c0 2.412-3.957 6.997-7.3 8.458A.499.499 0 0110 22zM3.5 5.943V13.5c0 1.782 3.441 5.998 6.5 7.451 3.059-1.453 6.5-5.668 6.5-7.451V5.943C14.209 5.63 11.88 4.79 10 3.597 8.12 4.79 5.791 5.63 3.5 5.943z"></path>
            <g transform="translate(5 6)">
              <path d="M5 5a2.503 2.503 0 01-2.5-2.5C2.5 1.122 3.622 0 5 0s2.5 1.122 2.5 2.5S6.378 5 5 5zm0-4c-.827 0-1.5.673-1.5 1.5S4.173 4 5 4s1.5-.673 1.5-1.5S5.827 1 5 1z"></path>
              <path d="M9 9H1a.5.5 0 01-.5-.5C.5 6.019 2.519 4 5 4s4.5 2.019 4.5 4.5A.5.5 0 019 9zM1.536 8h6.929a3.505 3.505 0 00-3.464-3 3.506 3.506 0 00-3.465 3z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LifeInsure;