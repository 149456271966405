import React, {useState} from 'react';
// import ReactGA from "react-ga";

import ExpandableCard from '../../components/expandableCard/expandableCard';
import CardHeader from '../../components/cardHeader/cardHeader';
import IconFinder from '../../components/iconfinder/iconfinder';
import IconHead from '../../components/iconHead/iconHead';
import CardHeaderSec from '../../components/cardHeader/cardHeaderSec';


const WealthShares = () => {

        const [showInfo, setShowInfo ] = useState(true);

        const shareInfo = [

                {
                    icon: 'diploma',
                    title: 'Shares',
                    wording: 'You can own companies such as Apple and Google by purchasing shares on the stock market.  A share gives you a share of the ownership. As you own part the company, you may receive earnings from profits (called dividends).  Depending on the fortunes of the company, your shares may grow in value.  Although the opposite is also true.  Shares are the building blocks of the stock market.',
                },
                {
                    icon: 'cash',
                    title: 'Making Money',
                    wording: 'To make money in the stock market the value of your shares not only need to increase, they need to increase more than fees it costs you to buy and subsequently sell the shares. As such making money is hard. You start from a losing position. To overcome this handicap, you\'ll need a strategy.  Strategies begin with the big picture.',
                },
                {
                    icon: 'CostAverage',
                    title: 'Big Picture',
                    wording: 'You can see the big picture by looking at Market Indices over the last few decades.  Indices such as the FTSE 100 and S&P 500 look to show the performance of the stock market.  The FTSE 100 tracks the performance of the top 100 companies on the London Stock Exchange. While the S&P 500 tracks 500 largest companies in America. Indices are great way to understand how the stock market is doing.',
                },
                {
                    icon: 'cycle',
                    title: 'Market Cycle',
                    wording: 'Most market indices follow a cycle of growth and decline over and over again. With a trend towards growth over time.  The cause of this cycle is the same thing that causes individual shares to rise and fall. Demand. ',
                },
                {
                    icon: 'auction',
                    title: 'Demand',
                    wording: 'The stock market is an auction. Like all auctions, the value of something depends upon the number of willing buyers and how deep their pockets are.  To understand demand, we need to understand how much money we have to spend and why we want something.',
                },
                {
                    icon: 'coins',
                    title: 'Spending Money',
                    wording: 'When everyone is spending, everyone is happy. Companies earn more money, more people are employed and share values go up.  The problem is that most of the spending money in a capitalist economy comes from debt.  If the cost of debt (called interest) becomes prohibitive to future spending. Demand will fall.',
                },
                {
                    icon: 'cow',
                    title: 'Irrational Herds',
                    wording: 'Behavioural economics looks at how and why we act the way we do. It turns out we\'re irrational and have a tendency to follow the herd. That means if someone else does something, we\'re more likely to  do it too.  It also means we\'ll do it regardless of whether it is logical to do it. Our behaviour sees momentum reinforce current trends in the stock market longer than its rational to do so. The result is bubbles. ',
                },
                {
                    icon: 'bubble',
                    title: 'Bubbles',
                    wording: 'A bubble is when the value of shares escape the reality of the underlying businesses.  Bubbles appear within individual shares and the market as a whole.  Bubbles eventually pop.  If you invest at the wrong time, you will lose money.  Timing is everything. ',
                },
                {
                    icon: 'Clock',
                    title: 'Time in the Market',
                    wording: 'Timing the market is difficult. Many of the worlds best investors and economists say it\'s near impossible.  As such conventional wisdom suggests it is time in the market that counts.  A long investment horizon will allow you to ride out the ups and downs of the markets and enjoy the gradual climb.  A short investment horizon will not.',
                    nounderline: 'yes'
                },
                
        ]

        let investStrat = [
            {
                icon: 'coaster',
                title: 'Ride the Market',
                wording: 'A fund is a type of investment where your money is pooled with other investors and allocated across a range of different investments.  Index Tracker funds invest your money in the underlying companies of a market index. As such you ride the ups and downs of the market.  These funds have the lowest costs.',
            },
            {
                icon: 'Invest',
                title: 'Beat the Market?',
                wording: 'Other investment funds exist with different investment strategies.  These funds are run by professional fund managers who aim to out perform the market, provide specific returns or provide returns even during market crashes. One of the best investment strategies is to find the best fund managers to invest for you.',
            },
            {
                icon: 'surf',
                title: 'Share Surfing',
                wording: 'Desirable fast growing companies are often referred to as growth shares.  The demand is high which increases their value. Investing in these companies is like surfing. You need to catch the wave early, ride the wave and then get off before the wave hits the rocks. These shares hit the rocks when their expected growth does not materialise. ',
            },
            {
                icon: 'chest',
                title: 'Share Treasure Wrecks',
                wording: 'Less desirable companies are often referred to as value shares.  The market does not expect much growth, if any, and so their value is low relative to their earnings and assets.  Investing in these companies is like treasure hunting.  You are looking for good companies that have been written off or forgotten about.  In which case you can find a treasure.',
            },
            {
                icon: 'bin',
                title: '"Don\'t Lose Money"',
                wording: 'One of Warren Buffett\'s top tips for investors is "Don\'t lose money". Good investing is good risk management.  Risk management protects you from losing money by investing in range of investments, having escape plans and being methodical in what you invest in.  This is an essential strategy',
            },
            {
                icon: 'Scales',
                title: 'The Right Way',
                wording: 'The right way depends on your knowledge, your objective and how much time you have to research.  To invest you will need a product and an investment(s) to invest in and a platform to invest with.',
                nounderline: 'yes'
            },
        ]

        let content = <div>

                        {shareInfo.map((section) => {
                                    return  <IconHead title={section.title} wording={section.wording}  nounderline={section.nounderline}>
                                                <IconFinder icon={section.icon}/>
                                            </IconHead>
                                })}


                                <CardHeaderSec title='Investing Strategies'/>

                                {investStrat.map((section) => {
                                    return  <IconHead title={section.title} wording={section.wording} nounderline={section.nounderline}>
                                                <IconFinder icon={section.icon}/>
                                            </IconHead>
                                })}




                        </div>
        
        


        const toggleInfo = () => {

            setShowInfo(!showInfo)
        }




        return (


            <div>

                    <CardHeader title='What you need to know' primary='true'/>

                    <div>

                        {showInfo ? <div className='standardPadding'>

                            <IconHead title={shareInfo[0].title} wording={shareInfo[0].wording} nounderline='true'>
                                <IconFinder icon={shareInfo[0].icon}/>
                            </IconHead>

                            <button className='actionButtonS' onClick={toggleInfo}>Read on</button> 
                            
                            </div>: 
                            
                            <div className='standardPadding'>

                                {content}
                            
                            </div>}

                    </div>

            </div>  
                
        )



    }

    export default WealthShares;