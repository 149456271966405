import React from 'react';
import Gift from '../../icons/gift';
import GiftInc from '../../icons/giftinc';
import Cost from '../../icons/cost';
import Income from '../../icons/income';
import UpArrow from '../../icons/upArrow';

import Trust from '../../icons/trust';
import ClockYel from '../../icons/clockYel';
import Shoe from '../../icons/shoe';
import Gold from '../../icons/gold';
import House from '../../icons/house';
import Ambulance from '../../icons/ambulance';
import Threshold from '../../icons/threshold';
import Medical from '../../icons/medical';
import Stick from '../../icons/stick';
import Family from '../../icons/family';
import Scales from '../../icons/scales';
import Calender from '../../icons/calender';
import TaxFree from '../../icons/taxfree';
import LifeInsure from '../../icons/lifeinsure';
import CostAverage from '../../icons/costaverage';
import Diversify from '../../icons/diversify';
import Sunshade from '../../icons/sunshade';
import ActiveFund from '../../icons/activeFund';
import Pie from '../../icons/pie';
import Building from '../../icons/building';
import Expert from '../../icons/expert';
import Beginner from '../../icons/beginner';
import Intermed from '../../icons/intermed';
import PriceTag from '../../icons/pricetag';
import Book from '../../icons/book';
import Steps from '../../icons/steps';
import Diploma from '../../icons/diploma';
import Auction from '../../icons/auction';
import Coaster from '../../icons/coaster';
import Surf from '../../icons/surf';
import Cash from '../../icons/cash';
import Cycle from '../../icons/cycle';
import Chest from '../../icons/chest';
import Bubble from '../../icons/bubble';
import Cow from '../../icons/cow';
import Coins from '../../icons/coins';
import Bin from '../../icons/bin';


const IconFinder = ({icon='Savings'}) => {

    const iconLookUp = (icon) => {

        if(icon === 'Gift'){
            return <Gift width='50' height='48'/>
        } else if (icon === 'GiftInc') {
            return <GiftInc width='50' height='48'/>
        } else if (icon === 'Gold') {
            return <Gold width='50' height='48'/>
        } else if (icon === 'Wealth') {
            return <Gold width='50' height='48'/>
        } else if (icon === 'diploma') {
            return <Diploma width='50' height='48'/>
        } else if (icon === 'auction') {
            return <Auction width='50' height='48'/> 
        } else if (icon === 'coaster') {
            return <Coaster width='50' height='48'/>
        } else if (icon === 'cash') {
            return <Cash width='50' height='48'/>
        } else if (icon === 'surf') {
            return <Surf width='50' height='48'/>
        } else if (icon === 'cycle') {
            return <Cycle width='50' height='48'/>
        } else if (icon === 'chest') {
            return <Chest width='50' height='48'/>   
        } else if (icon === 'bin') {
            return <Bin width='50' height='48'/>   
        } else if (icon === 'House') {
            return <House width='50' height='48'/>
        } else if (icon === 'House Deposit') {
            return <House width='50' height='48'/>
        } else if (icon === 'Medical') {
            return <Medical width='50' height='48'/>
        } else if (icon === 'Ambulance') {
            return <Ambulance width='50' height='48'/>
        } else if (icon === 'Threshold') {
            return <Threshold width='50' height='48'/>
        } else if (icon === 'Shoe') {
            return <Shoe width='50' height='48'/>
        } else if (icon === 'Invest') {
            return <UpArrow width='50' height='48'/>
        } else if (icon === 'coins') {
            return <Coins width='50' height='48'/>
        } else if (icon === 'Income') {
            return <Income width='50' height='48'/>
        } else if (icon === 'Book') {
            return <Book width='50' height='48'/>
        } else if (icon === 'Clock') {
            return <ClockYel width='50' height='48'/>
        } else if (icon === 'Calender') {
            return <Calender width='50' height='48'/>
        } else if (icon === 'Scales') {
            return <Scales width='50' height='48'/>
        } else if (icon === 'Trust') {
            return <Trust width='50' height='48'/>
        } else if (icon === 'Family') {
            return <Family width='50' height='48'/>
        } else if (icon === 'Stick') {
            return <Stick width='50' height='48'/>
        } else if (icon === 'cow') {
            return <Cow width='50' height='48'/>     
        } else if (icon === 'bubble') {
            return <Bubble width='50' height='48'/>
        } else if (icon === 'TaxFree') {
            return <TaxFree width='50' height='48'/>
        } else if (icon === 'LifeInsure') {
            return <LifeInsure width='50' height='48'/>
        } else if (icon === 'Diversify') {
            return <Diversify width='50' height='48'/>
        } else if (icon === 'CostAverage') {
            return <CostAverage width='50' height='48'/>
        } else if (icon === 'Sunshade') {
            return <Sunshade width='50' height='48'/>
        } else if (icon === 'Retirement') {
            return <Sunshade width='50' height='48'/>
        } else if (icon === 'PriceTag') {
            return <PriceTag width='50' height='48'/>
        } else if (icon === 'Cost') {
            return <Cost width='50' height='48'/>
        } else if (icon === 'ActiveFund') {
            return <ActiveFund width='50' height='48'/>
        } else if (icon === 'Steps') {
            return <Steps width='50' height='48'/>
        } else if (icon === 'Pie') {
            return <Pie width='50' height='48'/>
        } else if (icon === 'Building') {
            return <Building width='50' height='48'/>
        } else if (icon === 'Beginner') {
            return <Beginner width='50' height='48'/>
        } else if (icon === 'Knowledgeable') {
            return <Intermed width='50' height='48'/>
        } else if (icon === 'Expert') {
            return <Expert width='50' height='48'/>
        } else {
            return <Income width='50' height='48'/>
        }



    }


 return (
    <div>{iconLookUp(icon)}</div>
    
    
 )

}

export default IconFinder;