import React  from 'react';
import AffiliateWealth from '../AffiliateWealth/affiliatewealth';
import ExpandableCard from '../../components/expandableCard/expandableCard';
import { withRouter } from "react-router-dom";
import AffliateRetire from '../AffiliateRetire/affiliateretire';
import BookWhite from '../../icons/bookWhite';



const Aside = ((props) => {


    const linkToAdvice = () => {
        // ReactGA.event({
        //     category: 'link',
        //     action: 'about to home'
        //   });
        window.scrollTo(0, 0);
        props.history.push('/why')
    }





 return (
    <div className='aside'>
            

            <div className='card fixedPadding'>


                        <h3 className='centre'>Need Advice?</h3>

                        <button className='actionButtonSec' onClick={linkToAdvice}>Find Out More</button>

                    

            </div>

            <div className='card fixedPadding'>

                <p>Important...The information on this site does not constitute financial advice. Nor can we guarantee the guides provide all the information you need. </p>

                <p>The site has been set up to provide guidance on how to achieve your financial goals.</p>

            </div>


                <ExpandableCard highlight='true' title='Reading'>

                    <div className='expandLeftSideSec'>
                        <BookWhite/>

                    </div>

                    <div className='fixedPadding'>

                        {props.section === 'Retire' ? <AffliateRetire/> : <AffiliateWealth/>}

                        

                    </div>

                </ExpandableCard>

     </div>
 )

})

export default withRouter(Aside);