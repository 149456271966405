
import React, {useState} from 'react';
// import ReactGA from "react-ga";

import { withRouter } from "react-router-dom";
import NumberSelect from '../../components/numberSelect/numberSelect'

import Fade from 'react-reveal/Fade';

import Income from '../../icons/income';
import SpiderGraph from '../../components/SpiderGraph/SpiderGraph';
import Family from '../../icons/family';
import Coins from '../../icons/coins';

import IconSection from '../../components/iconSection/iconSection';
import DownArrow from '../../icons/downArrow';
import InvestRisk from '../../icons/investRisk';

import CardHeader from '../../components/cardHeader/cardHeader';


const RetireKYS = (props) => {

      const lifeRates = [5,10,13,24,25,6];
      const lifeLabels = [
                          '65 to 69',
                          '70 to 74',
                          '75 to 79',
                          '80 to 84',
                          '85 to 89',
                          '90+'];

      const labels = ['Certainty', 'Effort','Flexibility', 'Tax', 'CFL'];


     

      const onInputChange = (event) => {

        props.onInputChange(event);
      }




        return (

              <div>     

                  <CardHeader title='The 5 key questions are' primary='yes'/>

                  <div className='standardPadding'>


                                <Fade delay={50}>
                        

                                  <div className='uBordLine iconHead'>

                                        <p className='largeNumber'>1</p>
                                        <h4 className='centre bling'>Certainty</h4>

                                  
                                        
                                        <p>Most of us have no idea how long we will live and by association how long we need our money to last.</p>

                                        <p>To be certain our money will last for the rest of our life, we need to err on the side of caution. </p>
                                        
                                        <p>To maximise our income, we need to take on the risk that we may run out of money.</p>

                                        
                                        
                                        <NumberSelect id={props.questions[0].id} question={props.questions[0]} withInputChange={onInputChange}/>
                                        
                                  </div>

                                </Fade>

                                <Fade delay={50}>

                                <div className='uBordLine iconHead'>

                                      <p className='largeNumber'>2</p>
                                      <h4 className='centre bling'>Effort</h4>
                                    
                                    
                                    <p className='left'>Managing our money takes effort.   We have to ensure:</p>

                                    <IconSection>
                                      <DownArrow/>
                                        <p>
                                            <span className='bling'>Inflation</span> does not erode our money in real terms.
                                        </p>

                                    </IconSection>

                                    <IconSection>
                                        <InvestRisk/>
                                        <p>
                                            <span className='bling'>Investments</span> are managed inline with our needs and comfort levels.
                                        </p>

                                    </IconSection>

                                    <IconSection>
                                        <Income/>

                                        <p>
                                            <span className='bling'>Income withdrawals</span> do not result in high tax bills and running out of money . 
                                        </p>

                                    </IconSection>

                              
                              

                                    <NumberSelect id={props.questions[1].id} question={props.questions[1]} withInputChange={onInputChange}/>

                                  </div>

                                  </Fade>


                                  <Fade delay={50}>

                                  <div className='uBordLine iconHead'>

                                  <p className='largeNumber'>3</p>
                                  <h4 className='centre bling'>Flexibility</h4>
                                
                                  

                                  <p className='left'>We have the option to access our pension as and when we want. </p>
                                  
                                  <p>While this flexibility may sound attractive.  The security and the stability of a consistent income, like a regular salary, may be preferential.</p>
                                  <NumberSelect id={props.questions[2].id} question={props.questions[2]} withInputChange={onInputChange}/>

                                </div>

                                </Fade>


                                <Fade delay={50}>

                                <div className='uBordLine iconHead'>

                                  <p className='largeNumber'>4</p>
                                  <h4 className='centre bling'>Tax Optimisation</h4>
                  
                                
                                <p className='left'>Pensions have a special tax status. Our tax position in retirement will play a critical role in determining how best to access our pension.  There are 3 main tax considerations:</p>
                                <div className='resultSummary'>
                                  <div className='fteen flexWrap'>
                                    <Coins/>
                                  </div>
                                  <p className='eighty'>
                                  <span className='bling'>Tax-Free Cash</span> We can take an element of our pension tax-free. This is up to 25%.  Should we access the tax-free cash, when should it be taken and what we do with the tax-free cash are all considerations.
                                  </p>
                                </div>
                                <div className='resultSummary'>
                                  <div className='fteen flexWrap'>
                                    <Income/>
                                  </div>
                                  <p className='eighty'>
                                  <span className='bling'>Income Tax</span> Once we take tax-free cash, any money we access from our pension will be treated as income and taxed accordingly.  Thus if we access more income in a given year, we may have to pay more tax.
                                  </p>
                                </div>
                                <div className='resultSummary'>
                                  <div className='fteen flexWrap'>
                                     <Family/>
                                  </div>
                                  
                                  <p className='eighty'>
                                  <span className='bling'>Inheritance Tax</span>  When we die, money left in our pension does not form part of our estate.  It will not incur inheritance tax (IHT).  Money in our property, cash accounts and investments in most cases will.  If our net wealth is over and above the IHT threshold, we may want to consider whether we take income from our pension at all.
                                  </p>
                                </div>
                                <NumberSelect id={props.questions[3].id} question={props.questions[3]} withInputChange={onInputChange}/>
                                
                                </div>

                                </Fade>
                              
                                <Fade delay={50}>

                                    <div className='iconHead'>

                                      <p className='largeNumber'>5</p>
                                      <h4 className='centre bling'>Capacity for Loss</h4>
      
                                      
                                      <p className='left'> As life often does not work out the way we want, we need to consider the worst case and our ability to cope.</p>
                                      <NumberSelect id={props.questions[4].id} question={props.questions[4]} withInputChange={onInputChange}/>


                                    </div>

                                </Fade>

                                <Fade delay={50}>

                                    <div className='topCard espace'>
                                    <h4 className='bling'>Your Profile</h4>

                                    <div>
                                      <SpiderGraph mainData={props.profileData}/>

                                    </div>

                                    <div className='flexWrap'>

                                        <div className='lightPadding sixty'>
                                            <p className='b'>Key Question: </p>
                                            <p>Which option best matches your profile?</p>
                                        </div>

                                          <div className='borderedGraphic forty'>
                                            {props.profileData.map((option, i) => {
                                                return  <div key={i} className='flexItem'>
                                                            <p className='b'>{labels[i]}</p> 
                                                            <p>{option}</p>
                                                        </div>
                                            })}
                                          </div>

                                                                      
                                    </div>


                                    </div>

                                </Fade>

                                </div>
                                

                                


            </div>
        )



    }

    export default withRouter(RetireKYS);
