import React from 'react';

import CardHeader from '../../components/cardHeader/cardHeader';
import IconFinder from '../../components/iconfinder/iconfinder';
import IconHead from '../../components/iconHead/iconHead';



const WealthInvestYou = (props) => {

        let objectiveWording = 'Your investing objective largely determines which product you should invest in.';
        let expertWording;
        let horizon = 'Your investment horizon is used with your level of knowledge to determine how much investment risk is suitable for you.';
        let timeWording;

        if(props.results.knowledge === 'Beginner'){

            expertWording = 'As a beginner, you will need help choosing investments and managing investment risk.  The investment approach below takes this into account.'

        }else{

            expertWording = 'Given your level of expertise, we have assumed you do not need help managing investment risk.  The investment approach below is largely determined by the time you have available to research.'

        }

        if(props.results.time === 'Little'){

            timeWording = 'As you do not have much time, we have assumed you need a simple managed solution.'

        }else if(props.results.time != 'Little' && props.results.knowledge === 'Beginner'){

            timeWording = 'As you have time to research, you may wish to explore more advanced investment options.  The reading list suggests a number of books to help. As a beginner, we have assumed you still need a simple investment solution.'

        } else {
            timeWording = 'As you have time to research, more advanced investment options are open to you. '

        }

        return (

            <div>

                <CardHeader title='About You'/>

                <div className='standardPadding'>

                        <IconHead title={props.results.objective} wording={objectiveWording}>
                            <IconFinder icon={props.results.objective}/>
                        </IconHead>

                        <IconHead title={props.results.knowledge} wording={expertWording}>
                            <IconFinder icon={props.results.knowledge}/>
                        </IconHead>

                        

                        <IconHead title={props.results.time} wording={timeWording}>
                            <IconFinder icon='Clock'/>
                        </IconHead>

                        <IconHead title={props.results.horizon} wording={horizon} nounderline='true'>
                            <IconFinder icon='Calender'/>
                        </IconHead>

                        {props.results.knowledge !== 'Beginner' ? <p className='centre' onClick={props.toggleResults}> If you are not comfortable managing risk, you may wish to change your level of expertise to beginner.</p> : null}

                        <button className='actionButton' onClick={props.toggleResults}> Update Details</button>

                        

                        

                </div>


            </div>


                
        )



    }

    export default WealthInvestYou;