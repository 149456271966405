import React from 'react';
// import ReactGA from "react-ga";

import ExpandableCard from '../../components/expandableCard/expandableCard'

import Coins from '../../icons/coins';
import Family from '../../icons/family';
import Income from '../../icons/income';
import Crash from '../../icons/crash';
import InvestRisk from '../../icons/investRisk';
import Theft from '../../icons/theft';
import Lightning from '../../icons/lightning';
import Unemployment from '../../icons/unemployment';
import Health from '../../icons/health';
import ForSale from '../../icons/forsale';
import DownArrow from '../../icons/downArrow';
import CardHeader from '../../components/cardHeader/cardHeader';
import IconHead from '../../components/iconHead/iconHead';


const ProtectRisks = () => {


    // https://www.gov.uk/inheritance-tax

        const moneyCat = [
            {title: 'Inflation', wording: 'Inflation erodes your wealth in real terms over time. To maintain your wealth, you have to achieve returns at, or above the level of inflation.'},
            {title: 'Investment Risk', wording: 'With every investment, there is uncertainty. Investments can go up and down in value and investment risk is the level of uncertainty.'},
            {title: 'Market Crashes', wording: 'Stock markets experience sharp and significant falls in value periodically. If you have a short investment horizon, market crashes can be particularly devastating. '}
        
        ]

        const misfortuneCat = [
            {title: 'Ill Health', wording: 'May reduce your ability to earn income and the cost of care could wipe out any wealth you have.'},
            {title: 'Unemployment', wording: 'May force you to sell your assets to make ends meet or change your lifestyle.'},
            {title: 'Acts of God', wording: 'Unpredictable and unpreventable, these events include fire, flooding and extreme weather events. Property is particularly vulnerable.'},
            {title: 'Theft and Fraud', wording: 'Includes physical theft such as burglary and digital theft such as the fraudulent use of credit cards. '}
        
        ]

        const taxCat = [
            {title: 'Income Tax', wording: 'Reduces the amount of income you earn in a given year.  The amount of tax you pay is tiered based on the amount you earn, the more you earn, the more tax you pay.'},
            {title: 'Capital Gains Tax', wording: 'Reduces any profit you keep from selling assets in given tax year. While most assets are liable to CGT, the notable exception is your main house.'},
            {title: 'Dividend Tax', wording: 'Reduces the amount of income you earn from dividends paid by the shares of companies you hold.'},
    
        ]


        const deathCat = [
            {title: 'Inheritance Tax (IHT)', wording: 'Reduces the amount of wealth you pass to your beneficiaries on death. There is no inheritance tax if you plan to pass your estate onto a surviving spouse. '},
            {title: 'Loss of Income', wording: 'If you currently have dependants who rely on your income, this income will need to be replaced. '},

        ]


        


        return (


            <div>
            
                    <ExpandableCard title='Risks'>

                    <div className='expandLeftSide Opacsixty'>
                        <p className=''>1</p>
                    </div>
                
                    <div>


                    <CardHeader title='Money Risks' primary='yes'/>

                    <div className='standardPadding'> 

                        <IconHead title={moneyCat[0].title} wording={moneyCat[0].wording}>
                            <DownArrow width='50' height='48'/>

                        </IconHead>

                        <IconHead title={moneyCat[1].title} wording={moneyCat[1].wording}>
                            <InvestRisk width='50' height='48'/>

                        </IconHead>

                        <IconHead title={moneyCat[2].title} wording={moneyCat[2].wording} nounderline='true'>
                            <Crash width='50' height='48'/>

                        </IconHead>




                    </div>

                    <CardHeader title='Misfortune'/>

                    <div className='standardPadding'> 

                        <IconHead title={misfortuneCat[0].title} wording={misfortuneCat[0].wording}>
                            <Health width='50' height='48'/>

                        </IconHead>

                        <IconHead title={misfortuneCat[1].title} wording={misfortuneCat[1].wording}>
                            <Unemployment width='50' height='48'/>

                        </IconHead>

                        <IconHead title={misfortuneCat[2].title} wording={misfortuneCat[2].wording}>
                            <Lightning width='50' height='48'/>

                        </IconHead>

                        <IconHead title={misfortuneCat[3].title} wording={misfortuneCat[3].wording} nounderline='true'>
                            <Theft width='50' height='48'/>

                        </IconHead>


                

                    </div>

                    <CardHeader title='Tax'/>

                    <div className='standardPadding'> 

                        <IconHead title={taxCat[0].title} wording={taxCat[0].wording}>
                            <Income width='50' height='48'/>

                        </IconHead>

                        <IconHead title={taxCat[1].title} wording={taxCat[1].wording}>
                            <ForSale width='50' height='48'/>

                        </IconHead>

                        <IconHead title={taxCat[2].title} wording={taxCat[2].wording} nounderline='true'>
                            <Coins width='50' height='48'/>

                        </IconHead>
                       

                    </div>

                    <CardHeader title='Death'/>

                    <div className='standardPadding'> 

                        <IconHead title={deathCat[0].title} wording={deathCat[0].wording}>
                            <Family width='50' height='48'/>

                        </IconHead>

                        <IconHead title={deathCat[1].title} wording={deathCat[1].wording} nounderline='true'>
                            <Income width='50' height='48'/>

                        </IconHead>
                        

                    </div>

                </div>

            </ExpandableCard>
        </div>

                
        )



    }

    export default ProtectRisks;



