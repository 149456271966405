import React from "react";

function TaxFree({width=20, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 26 26"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#303030" transform="translate(-34 -242)">
          <g transform="translate(35 243)">
            <rect width="24" height="24" x="0" y="0" rx="3"></rect>
            <g strokeLinecap="round" transform="translate(3 5)">
              <path
                strokeLinejoin="round"
                d="M7 5l.598-1.821L8.64 0l1.197 3.085L10.58 5"
              ></path>
              <path
                strokeLinejoin="round"
                d="M3.554 5V0H1h5.147m10.031 5L12 0m0 5l4.178-5M9 3H8"
              ></path>
              <g transform="translate(0 8)">
                <path
                  strokeLinejoin="round"
                  d="M0 5L0 2.52040655 0 0 3 0"
                ></path>
                <path
                  d="M1.5 1.5L1.5 3.5"
                  transform="rotate(90 1.5 2.5)"
                ></path>
                <path
                  strokeLinejoin="round"
                  d="M5 5L5 3.41696599 5 0 8 0 8 2.5 5.22921141 2.5 8 5"
                ></path>
                <path
                  strokeLinejoin="round"
                  d="M17.5284276 5L15 5 15 0 17.5284276 0"
                ></path>
                <path
                  strokeLinejoin="round"
                  d="M12.5284276 5L10 5 10 0 12.5284276 0"
                ></path>
                <path d="M11 2L11 3" transform="rotate(90 11 2.5)"></path>
                <path d="M16 2L16 3" transform="rotate(90 16 2.5)"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default TaxFree;