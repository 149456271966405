
import React from 'react';
// import ReactGA from "react-ga";

import { withRouter } from "react-router-dom";
import TopCard from '../../components/topCard/topCard'
import SpiderGraph from '../../components/SpiderGraph/SpiderGraph';
import CardHeader from '../../components/cardHeader/cardHeader';

const RetireOptions = (props) => {

  // <RetireOptions profileData={optionalToggle ? profileGraphData : null} toggleProfile={optionalToggle} onProfileToggle={onProfileToggle}/>

     const pensionOptions = [
        {title: 'Drawdown', 
        wording: 'Drawdown allows you to access your pension as and when you want, much like a bank account.  Yet, all withdrawals in a given year are eligible for income tax.',
        question: 'Are you happy to take on the stress of having to manage your money? To ensure your money is not eroded by inflation, over taxed, lost to market corrections or used up early?',
        pros: 'Drawdown provides the opportunity to get more income than you could get from options providing guarantees. ',
        cons: 'There is a real risk of running out of money in retirement. You will need to manage your investments and withdrawals carefully to ensure this does not happen.',
        ratings: [{ name:'Certainty', value: 2},
                  { name:'Effort', value: 4},
                  { name:'Flexibility', value: 4},
                  { name:'Tax', value: 3},
                  { name:'CFL', value: 4},
                  ],
        colour: 'blingBlue',
        graphData: [1,4,4,3,4]

        },
        {title: 'Annuity', 
        wording: 'An annuity provides a guaranteed income for life.  With an annuity you can specity whether your income increases with inflation and whether your spouse is gets an income on your death.  ',
        question: 'Is lack of worry more important to you than from trying to design and manage your highest income strategy?',
        pros: 'You do not have worry about running out money or managing your money.',
        cons: 'Unless you live beyond average life expectancy, the income you receive is unlikely to be the best solution.  There is also little inheritance tax planning benefit to purchasing an annuity.',
        ratings: [{ name:'Certainty', value: 5},
                  { name:'Effort', value: 1},
                  { name:'Flexibility', value: 2},
                  { name:'Tax', value: 3},
                  { name:'CFL', value: 1},
                  ],
        colour: 'blingRed',
        graphData: [5,1,2,2,1]

        },
        {title: 'Cash', 
        wording: 'You take all your pension as income.  Some of the amount will be tax-free, but the rest will be taxed as income.',
        question: 'Do you have other sources of income to fund your retirement and will taking your pension as cash result in a large tax bill?',
        pros: 'None.',
        cons: 'You may pay more income tax than you would by staggering withdrawals over multiple years.',
        ratings: [{ name:'Certainty', value: 1},
                  { name:'Effort', value: 1},
                  { name:'Flexibility', value: 3},
                  { name:'Tax', value: 1},
                  { name:'CFL', value: 5},
                  ],
        colour: 'blingRed',
        graphData: [1,1,3,1,5]

        },
        {title: 'UFPLS', 
        wording: 'UFPLS allows you to access your pension as and when you want. Unlike drawdown, it allows you to access your tax-free cash in segments with each withdrawal over the course of their retirement.',
        question: 'Do you want to spread your tax-free cash over your retirement to reduce the amount of income tax your pay?',
        pros: 'UPLS provides the same benefits as drawdown and the opportunity to reduce income tax by staggering when you access your tax-free cash. ',
        cons: 'There is a real risk of running out of money in retirement. You will need to manage your investments and withdrawals carefully to ensure this does not happen.',
        ratings: [{ name:'Certainty', value: 2},
                  { name:'Effort', value: 5},
                  { name:'Flexibility', value: 5},
                  { name:'Tax', value: 5},
                  { name:'CFL', value: 4},
                  ],
        colour: 'blingRed',
        graphData: [2,5,5,5,3]

        },
        {title: 'Defined Benefit Scheme Pension', 
        wording: 'A Scheme Pension is considered the gold standard of pension options.  Like an annuity it provides a guaranteed income for life and may include other benefits such a spouses pension. Your default position should be to take the annual pension within the scheme.',
        question: 'Are you in poor health, have significant other assets and/or do not need a spouses pension? If so, do any of the options seem preferential?',
        pros: 'Scheme Pensions provide benefits in excess of what you could get with most other pension options. You do not have worry about running out money or managing your money.',
        cons: 'If you are in poor health, have significant other assets and/or do not need a spouses pension, the other options may be provide better income and tax optimisation solutions',
        warning: 'Transferring out is high risk. Currently you are required by legislation to seek advice before doing so.',
        ratings: [{ name:'Certainty', value: 5},
                  { name:'Effort', value: 1},
                  { name:'Flexibility', value: 1},
                  { name:'Tax', value: 2},
                  { name:'CFL', value: 1},
                  ],
        colour: 'blingRed',
        graphData: [5,1,1,2,1]

        },
        
      ]


        return (

          <div>     

                  <CardHeader title='Compare your profile against each option' primary='yes'/>

                  <div className='standardPadding widthControl'>


                    <TopCard option={pensionOptions[0] } toggleStatus={props.toggleProfile} toggleProfile={props.onProfileToggle}>
                      <SpiderGraph optionalName={pensionOptions[0].title} optionalData={pensionOptions[0].graphData} mainData={props.profileData} mainName='Profile'/>
                    </TopCard>

                    <TopCard option={pensionOptions[1] } toggleStatus={props.toggleProfile} toggleProfile={props.onProfileToggle}>
                      <SpiderGraph optionalName={pensionOptions[1].title} optionalData={pensionOptions[1].graphData} mainData={props.profileData} mainName='Profile'/> 
                    </TopCard>

                    <TopCard option={pensionOptions[2] } toggleStatus={props.toggleProfile} toggleProfile={props.onProfileToggle}>
                      <SpiderGraph optionalName={pensionOptions[2].title} optionalData={pensionOptions[2].graphData} mainData={props.profileData} mainName='Profile'/> 
                    </TopCard>

                    <TopCard option={pensionOptions[3] } toggleStatus={props.toggleProfile} toggleProfile={props.onProfileToggle}>
                      <SpiderGraph optionalName={pensionOptions[3].title} optionalData={pensionOptions[3].graphData} mainData={props.profileData} mainName='Profile'/> 
                    </TopCard>

                    <TopCard option={pensionOptions[4] } toggleStatus={props.toggleProfile} toggleProfile={props.onProfileToggle}>
                      <SpiderGraph optionalName={pensionOptions[4].title} optionalData={pensionOptions[4].graphData} mainData={props.profileData} mainName='Profile'/> 
                    </TopCard>


              </div>

            </div>
        )



    }

    export default withRouter(RetireOptions);



