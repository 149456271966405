import React from "react";

function Person({width=26, height=26}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 26"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="2"
        transform="translate(-246 -405)"
      >
        <rect
          width="286"
          height="390"
          x="69"
          y="149"
          stroke="#E8E3E3"
          rx="4"
        ></rect>
        <path
          fillRule="nonzero"
          stroke="#303030"
          d="M12 0C8.685 0 6 2.685 6 6s2.685 6 6 6 6-2.685 6-6-2.685-6-6-6zm0 13.5c-4.005 0-12 2.01-12 6V24h24v-4.5c0-3.99-7.995-6-12-6z"
          transform="translate(247 406)"
        ></path>
      </g>
    </svg>
  );
}

export default Person;