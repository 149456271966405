import React from 'react';
import './cardHeader.css';


const CardHeader = (props) => {

        let classes;

        if(props.primary){
            classes = 'cardHeader'
        } else {
            classes = 'cardHeaderSec'
        }
        
        return (
            <div className={classes}>

                <h6 className='centre white'>{props.title}</h6>

            </div>
            
        )
}


export default CardHeader;
