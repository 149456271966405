import React from 'react';

import './actionCard.css';

import Fade from 'react-reveal/Fade';


import Steps from '../../icons/steps';
import IconSection from '../iconSection/iconSection';
import ThumbDown from '../../icons/thumpDown';
import ThumbUp from '../../icons/thumbUp';
import Warning from '../../icons/warning';
import IconFinder from '../iconfinder/iconfinder';

const ActionCard = (props) => {

    return (

        <Fade delay={50}>
        
        <div className='actionCard'>

                {props.option.icon ? <IconFinder icon={props.option.icon}/> : null}
                
                <h3 className='centre bling'>{props.option.title}</h3>

                <p className='centre b espace'>{props.option.description}</p>

                        <div className='uBordLine'>

                        {props.option.pros.map((detail, i) => {
                            return <IconSection ket={i}>
                                <ThumbUp/>
                                <p>{detail}</p>
                            </IconSection>
                        })} 

                        </div>

                        <div className='uBordLine'>

                        {props.option.cons.map((detail, i) => {
                            return <IconSection ket={i}>
                                <ThumbDown/>
                                <p>{detail}</p>
                            </IconSection>
                        })} 

                        </div>

                        

                        {props.option.warnings ? props.option.warnings.map((detail,i) => {
                            return <div className='uBordLine' key={i}>
                                    <IconSection>
                                        <Warning/>
                                        <p>{detail}</p>
                                    </IconSection>
                                    </div>
                        }) : null }


                        <div>
                            {props.option.steps.map((detail, i) => {
                            return <IconSection ket={i}>
                                    <Steps/>
                                    <p>{detail}</p>
                                </IconSection>
                            })}
                        </div>

                
                    

                        

                



                

                

        </div>

    </Fade>
    )
}


export default ActionCard;