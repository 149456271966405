import React from "react";

function BookWhite({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 20"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="2"
        transform="translate(-136 -475)"
      >
        <rect
          width="286"
          height="390"
          x="69"
          y="149"
          stroke="#E8E3E3"
          rx="4"
        ></rect>
        <g stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round">
          <path
            d="M0 0h6a4 4 0 014 4v14a3 3 0 00-3-3H0V0zM20 0h-6a4 4 0 00-4 4v14a3 3 0 013-3h7V0z"
            transform="translate(137 476)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default BookWhite;