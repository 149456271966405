
import React, {useState} from 'react';

import * as ValFn from '../../validations/formValidations'

import CardHeader from '../../components/cardHeader/cardHeader';



const IncomeCalc = (props) => {

            const ageArray = [55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74 ,75]

            const [age, setAge] = useState(55);
            const [dbIncome, setDBIncome] = useState(0);
            const [dcIncome, setDCIncome] = useState(0);
            const [stateInc, setStateInc] = useState(0);
            const [propertyInc, setPropertyInc] = useState(0);
            const [otherVal, setOtherVal] = useState(0);

            const updateIncomeNum = (value,fn) => {

                let newValue = ValFn.currencyToNumber(value)

                if(ValFn.validateNumber(newValue)){
                    fn(newValue)
                }

        }

            const totalIncValue = () => {
                return ValFn.numberToCurrency(dbIncome + (dcIncome/(90-age)) + propertyInc + (otherVal/(90-age)))
            }

            const totalStateIncValue = () => {
                return ValFn.numberToCurrency(dbIncome + (dcIncome/(90-age)) + propertyInc + stateInc + (otherVal/(90-age)))
            }



        return (

            <div>

                <CardHeader title='Then do you have enough?' primary='yes'/>

                <div className='standardPadding'>
                                    

                    <div className='formStyle'>

                        {/* <h4 className=''>Basic Retirement Income Calculator</h4> */}

                        <div className='inputWrap'>
                            <label className="fw5">Age</label>
                            <select value={age} onChange={(event) => { setAge(+event.target.value)}}>
                                {ageArray.map((number) => { return <option value={number}> {number}</option>})}
                            </select>
                        </div>



                        <div className='inputWrap'>
                            <label className="fw5">Annual Pension Income <span className='i sText'>(Defined Benefit Pension, Final Salary Pension)</span></label>
                            <input type='text' value={ValFn.numberToCurrency(dbIncome)} onChange={(event) => {

                                updateIncomeNum(event.target.value, setDBIncome)
                                }}></input>
                        </div>

                        <div className='inputWrap'>
                            <label className="fw5">Pension Value <span className='i sText'>(Defined Contribution Pensions, Personal Pensions, AVCs, SIPPs)</span></label>
                            <input type='text' value={ValFn.numberToCurrency(dcIncome)} onChange={(event) => {

                                updateIncomeNum(event.target.value, setDCIncome)
                                }}></input>
                        </div>

                        <div className='inputWrap'>
                            <label className="fw5">State Pension</label>
                            <input type='text' value={ValFn.numberToCurrency(stateInc)} onChange={(event) => {

                                updateIncomeNum(event.target.value, setStateInc)
                                }}></input>
                        </div>

                        <div className='inputWrap'>
                            <label className="fw5">Property Rental Income</label>
                            <input type='text' value={ValFn.numberToCurrency(propertyInc)} onChange={(event) => {

                                updateIncomeNum(event.target.value, setPropertyInc)
                                }}></input>
                        </div>

                        <div className='inputWrap'>
                            <label className="fw5">Other Wealth <span className='i sText'>(ISAs, Cash and Savings, Stocks and Shares)</span></label>
                            <input type='text' value={ValFn.numberToCurrency(otherVal)} onChange={(event) => {

                                updateIncomeNum(event.target.value, setOtherVal)
                                }}></input>
                        </div>

                    </div>

                </div>

                <div className='cardFoot standardPadding'>

                        <h4 className='b white'>Total (inc state): </h4>

                        <h4 className='b largeNumber white'>{totalStateIncValue()}</h4>

                        <h4 className='b c white'>Total (exc state): {totalIncValue()}</h4>

                        

                        <p className='i sText white'>* This calculator is for indicative purposes only.  The actual income you will receive will depend on the options you select, whether you provide for a spouses income and unknown future variables.</p>
                        
                </div>



            </div>

            

           
        )



    }

    export default IncomeCalc;
