const outcomeCalc = (results) => {

    let financial = financialOutcome(results);
    let time = timeOutcome(results);
    let risk = riskOutcome(results);
    let overall = overallCalc(results);

    let output = {  overall: '',
                    financial: '',
                    time: '', 
                    risk: ''
                };

    

    output.overall = overall
    output.financial = financial;
    output.time = time;
    output.risk = risk;

    return output;

 
};


const overallCalc = (results) => {

    let numWealth = +results.wealth;

    if(numWealth<50000 || (numWealth<100000 && results.objective !== 'Wealth Creation')){
      return "No";
    } else if((results.knowledge !== 'Good'  && results.time !== 'Lots') && numWealth>99999) {
      return "Yes";
    } else if(results.knowledge === 'Low' && numWealth>99999) {
        return "Yes";
    } else {
      return "Maybe";
    }
};


const financialOutcome = (results) => {

    let numWealth = +results.wealth;
    let financial;

    if(numWealth<50000 || (numWealth<100000 && results.objective !== 'Wealth Creation')){
        
        financial = 'The cost of advice will likely outweigh the financial benefit.';
        
        return {outcome: 'No', wording: financial, title: 'Not Beneficial'};

    } else if(numWealth<100000 && results.objective === 'Wealth Creation'){
        
        financial = 'While, the cost of advice will likely outweigh the financial benefit at this point, advice will be beneficial as your wealth grows.';
        
        return {outcome: 'Maybe', wording: financial, title: 'Maybe Beneficial'};

    } else if (results.knowledge !== 'Good'){

        financial = 'The financial benefits of advice will likely outweigh the cost of advice. '

        return {outcome: 'Yes', wording: financial, title: 'Beneficial'};

    } else {

        financial = 'Given your ' + results.knowledge.toLowerCase() + ' level of financial knowledge, Advice may not provide sufficient financial benefit to justify the cost of advice.   ';
 
        // financial += temp;
        return {outcome: 'Maybe', wording: financial, title: 'Maybe Beneficial'};

    }  

};

const timeOutcome = (results) => {

    let time;

    if(results.knowledge === 'Low' && results.time !== 'Lots'){
    
        time = 'Given your level of knowledge, you probably have insufficient time to make the right decisions. ';
        
        return {outcome: 'Yes', wording: time, title: 'Beneficial'};

    } else if (results.knowledge === 'Good' && results.time !== 'Little'){

        time = 'You probably have sufficient time to research and make the right decisions yourself without getting advice. ';

        return {outcome: 'No', wording: time, title: 'Not Beneficial'};

    } else {

        time = 'You probably have the time to research and make the right decisions yourself. However advice may be preferable given the time required.';

        return {outcome: 'Maybe', wording: time, title: 'Maybe Beneficial'};

    }  

};

const riskOutcome = (results) => {

    let numWealth = +results.wealth;
    let risk;

    if(results.knowledge !== 'Good'){
        
        risk = 'Advice would be worthwhile to manage the risks associated with your money. ';
        
        return {outcome: 'Yes', wording: risk, title: 'Beneficial'};

    } else if (results.knowledge === 'Good' && numWealth>100000){

        risk = 'While you have a good level of financial knowledge, a second opinion may be worthwhile given your level of wealth. ';

        return {outcome: 'Maybe', wording: risk, title: 'Maybe Beneficial'};

    } else {

        risk = 'Given your level of knowledge, you probably have sufficient knowledge to manage the risk around your wealth. ';

        return {outcome: 'No', wording: risk, title: 'Not Beneficial'};

    }  

};


export default outcomeCalc;



