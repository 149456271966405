import React from 'react';
import CardHeaderSec from '../../components/cardHeader/cardHeaderSec';


const AffliateWealth = () => {







        return (
            <div>
                                <p className='i centre'>Full disclosure, as an Amazon Associate we earn from qualifying purchases made.</p>

                                <CardHeaderSec title='The book for all round improvement'/>
                
                                

                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/1501124021/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=1501124021&linkCode=as2&tag=nicholasg1-21&linkId=5dadd314b08526c348167d57dcd3785b"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=1501124021&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=1501124021" width="1" height="1" border="0" alt="" />

                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>Principles</p>
                                    <p className='centre i'>by Ray Dalio</p>
                                    <br></br>
                                </div>

                
                                <CardHeaderSec title='The books to make you a better investor'/>

                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/0060555661/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=0060555661&linkCode=as2&tag=nicholasg1-21&linkId=b1c1819833e8ae06155c84379e3cfcb7"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=0060555661&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=0060555661" width="1" height="1" border="0" alt=""/>

                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>The Intelligent Investor </p>
                                    <p className='centre i'>by Benjamin Graham</p>
                                    <br></br>
                                </div>

                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/0743200403/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=0743200403&linkCode=as2&tag=nicholasg1-21&linkId=1cc8d83898af04043a84129d7a78dd67"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=0743200403&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=0743200403" width="1" height="1" border="0" alt="" />

                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>One Up on Wall Street</p>
                                    <p className='centre i'>by Peter Lynch</p>
                                    <br></br>
                                </div>

                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/085719495X/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=085719495X&linkCode=as2&tag=nicholasg1-21&linkId=4bee1fd1cd8cea647acdcc20cd500762"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=085719495X&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=085719495X" width="1" height="1" border="0" alt="" />
                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>The Art of Execution</p>
                                    <p className='centre i'>by Lee Freeman-Shor</p>
                                    <br></br>
                                </div>

                 
                                <CardHeaderSec title='The books for property investing'/>

                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/1784521280/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=1784521280&linkCode=as2&tag=nicholasg1-21&linkId=02e7924f7a63e426635292a43658f6da"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=1784521280&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=1784521280" width="1" height="1" border="0" alt=""/>
                                
                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>Property Magic</p>
                                    <p className='centre i'>by Simon Zutshi</p>
                                    <br></br>
                                </div>

                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/B00CZA6ZLG/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=B00CZA6ZLG&linkCode=as2&tag=nicholasg1-21&linkId=bd54eec1ad2b7a7c2b85d1d6d7a95344"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=B00CZA6ZLG&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=B00CZA6ZLG" width="1" height="1" border="0" alt=""/>

                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>Property Investing Secrets</p>
                                    <p className='centre i'>by Rob Moore and Mark Homer</p>
                                    <br></br>
                                </div>

                                <CardHeaderSec title='The books to better understand your own limitations'/>
                                
                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/0241951224/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=0241951224&linkCode=as2&tag=nicholasg1-21&linkId=dd24da3891022107919b02e885b359fb"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=0241951224&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=0241951224" width="1" height="1" border="0" alt=""/>

                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>Misbehaving </p>
                                    <p className='centre i'>by Richard Thaler</p>
                                    <br></br>
                                </div>
                                
                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/0141033576/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=0141033576&linkCode=as2&tag=nicholasg1-21&linkId=2c2be4c76898cddc27c403328e7ef70c"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=0141033576&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=0141033576" width="1" height="1" border="0" alt=""/>

                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>Thinking, Fast and Slow </p>
                                    <p className='centre i'>by Daniel Kahneman</p>
                                    <br></br>
                                </div>

           
                                <CardHeaderSec title='The book to make better predictions'/>
                                
                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/1847947158/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=1847947158&linkCode=as2&tag=nicholasg1-21&linkId=aa844b5dce8b7dda1df05831c45f2b6d"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=1847947158&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=1847947158" width="1" height="1" border="0" alt=""/>
                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>Superforecasting: The Art and Science of Prediction </p>
                                    <p className='centre i'>by Philip Tetlock and Dan Gardner</p>
                                    <br></br>
                                </div>

                  
                                <CardHeaderSec title='The books for those who do not like to read'/>

                                <iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=22&l=ur1&category=audible&banner=11NTP5K3KRY3M7SPQBG2&f=ifr&linkID=2eb5e29a79175fd45256987c9fd7e777&t=nicholasg1-21&tracking_id=nicholasg1-21" width="250" height="250" scrolling="no" border="0"></iframe>
                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>Audible </p>
                                    <br></br>
                                </div>

          </div>
        )



    }

    export default AffliateWealth;