import React from 'react';
import CardHeaderSec from '../../components/cardHeader/cardHeaderSec';


const AffliateRetire = () => {







        return (
            <div>
                                <p className='i centre'>Full disclosure, as an Amazon Associate we earn from qualifying purchases made.</p>


                                <CardHeaderSec title='The book for financial decisions'/>


                                

                                 <a target="_blank"  href="https://www.amazon.co.uk/gp/product/1789660653/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=1789660653&linkCode=as2&tag=nicholasg1-21&linkId=197d5c468b011d46347ebd96687c6d3e"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=1789660653&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=1789660653" width="1" height="1" border="0" alt=""/>
                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>The Good Retirement Guide 2020</p>
                                    <p className='centre i'>by Jonquil Lowe</p>
                                    <br></br>
                                </div>

                   
                                <CardHeaderSec title='The books for a happier retirement'/>


                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/096941949X/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=096941949X&linkCode=as2&tag=nicholasg1-21&linkId=89e66a6e88d0b73d9ca235e2e83a32a3"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=096941949X&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=096941949X" width="1" height="1" border="0" alt=""/>

                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>How to Retire Happy, Wild, and Free</p>
                                    <p className='centre i'>by Ernie Zelinski</p>
                                    <br></br>
                                </div>

                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/1472951336/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=1472951336&linkCode=as2&tag=nicholasg1-21&linkId=68e7c45237a6a519e62e07eaa276906f"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=1472951336&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=1472951336" width="1" height="1" border="0" alt="" />


                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>Not Fade Away: How to Thrive in Retirement</p>
                                    <p className='centre i'>by Ernie Zelinski</p>
                                    <br></br>
                                </div>





                                <CardHeaderSec title='The books for healthier retirement'/>


          

                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/147292598X/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=147292598X&linkCode=as2&tag=nicholasg1-21&linkId=49e781e69c4b9de4b9339c9691b3f6a9"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=147292598X&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=147292598X" width="1" height="1" border="0" alt=""/>
                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>Sod Sixty</p>
                                    <p className='centre i'>by Claire Parker and Muir Gray</p>
                                    <br></br>
                                </div>



                                <a target="_blank"  href="https://www.amazon.co.uk/gp/product/1472918975/ref=as_li_tl?ie=UTF8&camp=1634&creative=6738&creativeASIN=1472918975&linkCode=as2&tag=nicholasg1-21&linkId=8ccb0649a9ca0e336d44b6aa11503e1e"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=GB&ASIN=1472918975&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=nicholasg1-21" ></img></a><img src="//ir-uk.amazon-adsystem.com/e/ir?t=nicholasg1-21&l=am2&o=2&a=1472918975" width="1" height="1" border="0" alt="" />
                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>Sod Seventy</p>
                                    <p className='centre i'>by Muir Gray</p>
                                    <br></br>
                                </div>


                                <CardHeaderSec title='The books for those who do not like to read'/>

                                <iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=22&l=ur1&category=audible&banner=11NTP5K3KRY3M7SPQBG2&f=ifr&linkID=2eb5e29a79175fd45256987c9fd7e777&t=nicholasg1-21&tracking_id=nicholasg1-21" width="250" height="250" scrolling="no" border="0"></iframe>
                                <div className='nMar'>
                                     <br></br>
                                    <p className='bling subHead'>Audible </p>
                                    <br></br>
                                </div>

          </div>
        )



    }

    export default AffliateRetire;