import React from "react";

function PriceTag({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-396 -274)">
          <g transform="translate(396 274)">
            <path d="M9.5 24a.502.502 0 01-.354-.146l-9-9a.5.5 0 010-.707l14-14A.5.5 0 0114.5 0h9a.5.5 0 01.5.5v9c0 .133-.053.26-.146.354l-14 14A.502.502 0 019.5 24zm-8.293-9.5L9.5 22.793 23 9.293V1h-8.293l-13.5 13.5z"></path>
            <path d="M18.5 8A2.503 2.503 0 0116 5.5C16 4.122 17.121 3 18.5 3S21 4.122 21 5.5 19.879 8 18.5 8zm0-4c-.827 0-1.5.673-1.5 1.5S17.673 7 18.5 7 20 6.327 20 5.5 19.327 4 18.5 4z"></path>
            <path d="M10.5 18a.5.5 0 01-.5-.5v-8a.5.5 0 011 0v8a.5.5 0 01-.5.5z"></path>
            <path d="M7.5 14c-.827 0-1.5-.673-1.5-1.5S6.673 11 7.5 11s1.5.673 1.5 1.5S8.327 14 7.5 14zm0-2a.5.5 0 10.002 1.002A.5.5 0 007.5 12zM13.5 16c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5zm0-2a.5.5 0 10.002 1.002A.5.5 0 0013.5 14z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PriceTag;