import React from "react";

function CostWhite({width=20, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 25"
    >
      <g fill="#ffffff" fillRule="nonzero" stroke="#ffffff" strokeWidth="1">
        <path
          d="M2.953 22.965a.57.57 0 01-.542-.752l2.206-6.616a6.642 6.642 0 00-1.062-6.192c-1.673-2.151-1.402-5.22.618-6.987C5.849.95 8.112.108 10.383.108c5.009 0 8 1.923 8 5.143a.572.572 0 01-1.143 0c0-2.504-2.563-4-6.857-4-1.997 0-3.986.739-5.457 2.028-1.565 1.367-1.77 3.75-.469 5.423a7.787 7.787 0 011.244 7.256l-1.843 5.524c3.86-1.195 6.32-.73 8.911-.24 1.511.285 3.073.58 5.04.58a.572.572 0 010 1.143c-2.073 0-3.689-.306-5.252-.6-2.788-.527-5.196-.982-9.408.564a.569.569 0 01-.196.036z"
          transform="translate(-213 -124) translate(214 125)"
        ></path>
        <path
          d="M15.525 12.679H.667a.572.572 0 010-1.143h14.858a.572.572 0 010 1.143z"
          transform="translate(-213 -124) translate(214 125)"
        ></path>
      </g>
    </svg>
  );
}

export default CostWhite;