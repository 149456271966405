import React from "react";

function Stick({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero" transform="translate(-253 -315)">
          <g transform="translate(253 315)">
            <path d="M23.599 5.906l-.463-1.392a1.514 1.514 0 00-1.443-1.023c-3.044-.006-5.28-.935-7.254-1.753C12.905 1.102 11.456.5 9.938.5 6.942.5 3.209 1.747 1.071 2.979a1.48 1.48 0 00-.708.951c-.095.404-.017.834.214 1.181l1.128 1.691c.313.471.857.716 1.441.66 1.125-.126 1.901-.544 2.651-.948C6.765 5.992 7.679 5.5 9.438 5.5c1.996 0 2.716.674 3.282 1.363a5.467 5.467 0 011.218 3.456V23a.5.5 0 00.5.5h5c.276 0 .5-.224.499-.5v-4.995l.001-.005v-5l-.001-.005V9.76c0-.672.43-1.249 1.071-1.437.507-.148 1.052-.32 1.642-.519a1.507 1.507 0 00.949-1.898zM18.938 22.5h-3.999v-4h3.999v4zm0-5h-3.999v-4h3.999v4zm3.393-10.643c-.576.194-1.107.361-1.602.507a2.476 2.476 0 00-1.791 2.396v2.74h-3.999v-2.181a6.467 6.467 0 00-1.445-4.09C12.757 5.331 11.756 4.5 9.439 4.5c-2.012 0-3.13.603-4.116 1.134-.727.392-1.355.73-2.287.834a.519.519 0 01-.498-.221L1.41 4.556a.503.503 0 01-.073-.397.484.484 0 01.233-.313C3.593 2.68 7.118 1.5 9.938 1.5c1.319 0 2.616.538 4.118 1.161 2.059.854 4.393 1.823 7.635 1.829.23.001.429.137.497.34l.464 1.392a.507.507 0 01-.321.635z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Stick;