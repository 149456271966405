import React from "react";

function Building({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-610 -292)">
          <g transform="translate(610 292)">
            <path d="M9 12H7a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2A.5.5 0 009 12zm-.5 2h-1v-1h1v1zm.5 2H7a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2A.5.5 0 009 16zm-.5 2h-1v-1h1v1zM9 8H7a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2A.5.5 0 009 8zm-.5 2h-1V9h1v1zm6.5 5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5zm.5-2h1v1h-1v-1zM9 4H7a.5.5 0 00-.5.5v2A.5.5 0 007 7h2a.5.5 0 00.5-.5v-2A.5.5 0 009 4zm-.5 2h-1V5h1v1zm2-1.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2A.5.5 0 0013 4h-2a.5.5 0 00-.5.5zm1 .5h1v1h-1V5zm3.5 6h2a.5.5 0 00.5-.5v-2A.5.5 0 0017 8h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5zm.5-2h1v1h-1V9zM23 4h-3.5V2.5a.501.501 0 00-.363-.481l-7-2a.51.51 0 00-.274 0l-7 2A.501.501 0 004.5 2.5V4H1a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-19A.5.5 0 0023 4zM4.5 6H3a.5.5 0 000 1h1.5v2H3a.5.5 0 000 1h1.5v2H3a.5.5 0 000 1h1.5v2H3a.5.5 0 000 1h1.5v2H3a.5.5 0 000 1h1.5v2H3a.5.5 0 000 1h1.5v1h-3V5h3v1zm10 17h-5v-2h5v2zm4 0h-3v-2.5a.5.5 0 00-.5-.5H9a.5.5 0 00-.5.5V23h-3V2.877L12 1.02l6.5 1.857V23zm4 0h-3v-1H21a.5.5 0 000-1h-1.5v-2H21a.5.5 0 000-1h-1.5v-2H21a.5.5 0 000-1h-1.5v-2H21a.5.5 0 000-1h-1.5v-2H21a.5.5 0 000-1h-1.5V7H21a.5.5 0 000-1h-1.5V5h3v18zM15 19h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5zm.5-2h1v1h-1v-1zM13 12h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm-.5 2h-1v-1h1v1zm.5 2h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zm-.5 2h-1v-1h1v1zM15 7h2a.5.5 0 00.5-.5v-2A.5.5 0 0017 4h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5zm.5-2h1v1h-1V5zM13 8h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2A.5.5 0 0013 8zm-.5 2h-1V9h1v1z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Building;