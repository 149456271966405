import React from 'react';
import ReactGA from "react-ga";

import { withRouter } from "react-router-dom";

import ExpandableCard from '../../components/expandableCard/expandableCard'
import Cost from '../../icons/cost';
import Trust from '../../icons/trust';
import Options from '../../icons/options';
import Pen from '../../icons/pen';
import Team from '../../icons/team';
import Person from '../../icons/person';
import ClockYel from '../../icons/clockYel';
import Book from '../../icons/book';
import Meeting from '../../icons/meeting';
import Discussion from '../../icons/discussion';
import Exchange from '../../icons/exchange';
import CardHeader from '../../components/cardHeader/cardHeader';
import IconHead from '../../components/iconHead/iconHead';
import BannerHeader from '../../components/bannerHeader/bannerHeader';

const Advice = (props) => {

    ReactGA.pageview('/Advice');

    const financialAdvice = () => {
        const url = 'http://www.google.com/search?q=financial+advice'
        window.open(url, '_blank');
    }

    const linkToWhy = () => {
        // ReactGA.event({
        //     category: 'link',
        //     action: 'about to home'
        //   });
        window.scrollTo(0, 0);
        props.history.push('/why')
    }

    const adviceCheck = [
            {title: 'References', wording: 'One of the best ways to ensure quality is to get references, ideally from someone you know.'},
            {title: 'Cost', wording: 'The cost of advice depends on the complexity of advice, the amount of you have and your location.  Advice should not be more than 2%.'},
            {title: 'Options', wording: 'Some advisers are tied to certain product providers, reducing the amount of solutions they can offer to you. The trade off should be reduced advice costs.  There are pros and cons to this.'},
            {title: 'Qualifications', wording: 'The Financial Conduct Authority (FCA) requires advisers to have sufficient qualifications before providing certain types of advice.'},
            {title: 'Personality', wording: 'Make sure you like the person you get advice from.'},
            {title: 'Support', wording: 'Advice is a team effort. Behind an adviser there will be specialist support staff.  The support will help ensure quality, communication and will improve your experience. Find out about the team.'}
    ]

    const adviceProcess = [
        {title: 'Introduction Meeting/Call', wording: 'Often free of charge, the introduction meeting is a chance for both you and the adviser to scope each other out.  You will both decide whether the relationship is worth continuing.'},
        {title: 'Fact Finding', wording: 'If you decide to continue, your adviser will embark on a fact find into your life, your assets, your debt and your goals.  You will have to provide this information by completing various paperwork and online forms.'},
        {title: 'A Wait', wording: 'The adviser will need to get information from the existing investments and pensions from your current providers.  This can take some time.'},
        {title: 'Fact Find Discussion', wording: 'The adviser may call to discuss the information gathered in the fact finding process.'},
        {title: 'Report', wording: 'After a couple of weeks (hopefully sooner), you will receive a report with a recommendation on what to do and how to do it.'},
        {title: 'Report Discussion', wording: 'The adviser will discuss the report with you and answer any questions you may have.'},
        {title: 'Implementation', wording: 'Once you complete the paperwork, the adviser will arrange your new policies and products to be set up. This can take some time.'},
        {title: 'Optional Ongoing Reviews', wording: 'You will have the option to have ongoing reviews. These reviews ensure your set up continues to be aligned with your requirements and the market. As change is constant, this is recommended. '},
    ]


        return (

            <div>

                <div className='banner advice'>


                        <BannerHeader title="Get Advice" subHeader="How to find a good adviser"/> 



                        


                </div>

                <div className='container'>

                    <div className='content-area'>

                    

                    <ExpandableCard title='What to expect'>

                                <div className='expandLeftSide Opaceighty'>
                                    <p className=''>1</p>
                                </div>

                                <div>

                                    <CardHeader title='Advice will involve' primary='yes'/>
                            
                                    <div className='standardPadding'>

                                    <IconHead title={adviceProcess[0].title} wording={adviceProcess[0].wording}>
                                        <Meeting width='50' height='48'/>

                                    </IconHead>

                                    <IconHead title={adviceProcess[1].title} wording={adviceProcess[1].wording}>
                                        <Pen width='50' height='48'/>

                                    </IconHead>

                                    <IconHead title={adviceProcess[2].title} wording={adviceProcess[2].wording}>
                                        <ClockYel width='50' height='48'/>

                                    </IconHead>

                                    <IconHead title={adviceProcess[3].title} wording={adviceProcess[3].wording}>
                                        <Discussion width='50' height='48'/>

                                    </IconHead>

                                    <IconHead title={adviceProcess[4].title} wording={adviceProcess[4].wording}>
                                        <Book width='50' height='48'/>

                                    </IconHead>

                                    <IconHead title={adviceProcess[5].title} wording={adviceProcess[5].wording}>
                                        <Discussion width='50' height='48'/>

                                    </IconHead>

                                    <IconHead title={adviceProcess[6].title} wording={adviceProcess[7].wording}>
                                        <Exchange width='50' height='48'/>

                                    </IconHead>

                                    <IconHead title={adviceProcess[7].title} wording={adviceProcess[7].wording}>
                                        <Discussion width='50' height='48'/>

                                    </IconHead>


                                    <p className='centre'>The end to end process will take between 2 to 4 months.</p>

                                    </div>


                                        
                                         
                                    
                                    
                                    
            
                                </div>

                            </ExpandableCard>
 

                            <ExpandableCard title='What to check'>

                                <div className='expandLeftSide Opacninety'>
                                    <p className=''>2</p>
                                </div>

                                <div>

                    <CardHeader title='The key items are' primary='yes'/>
            
                    <div className='standardPadding'>

                        <IconHead title={adviceCheck[0].title} wording={adviceCheck[0].wording}>
                            <Pen width='50' height='48'/>

                        </IconHead>

                        <IconHead title={adviceCheck[1].title} wording={adviceCheck[1].wording}>
                        <Cost width='50' height='48'/>

                        </IconHead>

                        <IconHead title={adviceCheck[2].title} wording={adviceCheck[2].wording}>
                            <Options width='50' height='48'/>

                        </IconHead>

                        <IconHead title={adviceCheck[3].title} wording={adviceCheck[3].wording}>
                            <Trust width='50' height='48'/>

                        </IconHead>

                        <IconHead title={adviceCheck[4].title} wording={adviceCheck[4].wording}>
                            <Person width='50' height='48'/>

                        </IconHead>

                        <IconHead title={adviceCheck[5].title} wording={adviceCheck[5].wording} nounderline='true'>
                            <Team width='50' height='48'/>

                        </IconHead>

                    </div>

                    

                </div>

            </ExpandableCard>


                                <ExpandableCard title='Find an adviser'>

                                    <div className='expandLeftSide'>
                                        <p className=''>3</p>
                                    </div>

                                
                                <div>

                                    <CardHeader title='Coming Soon' primary='yes'/>
                            
                                    <div className='standardPadding'>

                                        <p>In the meantime, click below to find an adviser using one of the world's favourite search engines</p>

                                    <button className='actionButton' onClick={financialAdvice}> Find an Adviser</button>
                                    
                
                                    </div>
                                </div>

                            </ExpandableCard>

                    </div>

                    <div className='aside'>

                        

                        <div className='card fixedPadding'>

                            <h3 className='centre'>Not Sure?</h3>

                            <button className='actionButtonSec' onClick={linkToWhy}>Find Out More</button>

                         </div>

                    </div>

                    

                    </div>

            </div>
        )



    }

    export default withRouter(Advice);
