



export let validateTelephone = (variable) => {

}

export let validateNumber= (variable) => {

    const re = /^[0-9,+\b]+$/;
    return re.test(variable);
    
}

export let validateCurrency = (variable) => {
    
}

export let validateEmail = (email) => {

    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
    
}

export let currencyToNumber= (number) => {

  let newValue = number.replace(/[,£]/g,'')
  return +newValue

}

export const numberToCurrency= (number, currency = 'gbp') => {

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0
  })

  if(number === '' ){
    return '£'
  }else{
    return formatter.format(number)
  }

}


export let submissionCheck = (questions) => {

    let check = true;
  
    questions.forEach(element => {
      if(element.value === ''  || element.value === 'Select an option'  ){
        check = false;
      }

      if(element.type === 'Check' && !element.checked){
        check = false;
      }
      
    });

    return check;



}

export let resultsObject = (questions) => {

  let results = {};

  for(let i in questions){
    results[questions[i].code] = questions[i].value;
  }

  return results;


}

export let questionObject = (questions, results) => {

  for(let i in questions){
    if(results[questions[i].code]){
      questions[i].value = results[questions[i].code];
    }else{
      questions[i].value = '';
    }
  }

  return questions;

}

export let updateCheck = (questions, id, value) => {


  return questions.map((question) => {

    if (question.id !== (id + 1)) {
        return question
    }

    if (value === ''){
      return {
        ...question,
        value: value
      }
    }

    if (question.type === 'Number'){
      
        if(validateNumber(value)){
          return {
            ...question,
            value: value
          }
        }else{
          return question
        }

    }

    if (question.type === 'Currency'){


      let newValue = currencyToNumber(value)

      if(validateNumber(newValue)){
        return {
          ...question,
          value: newValue
        }
      }else{
        return question
      }
    }

    if (question.type === 'Check'){

      let status = questions[id].checked ? false : true
      return {
        ...question,
        checked: status,
      }

    }

    return {
      ...question,
      value: value
    }

  
  })

}
