import React from "react";

function Cycle({width=24, height=21}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 21"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#303030" transform="translate(-103 -29)">
          <path d="M104 39.897c1.915-6.619 3.87-9.918 5.864-9.897 1.994.02 3.998 3.361 6.012 10.02 1.93 6.681 3.91 10.021 5.938 10.021 2.03 0 4.091-3.34 6.186-10.02"></path>
        </g>
      </g>
    </svg>
  );
}

export default Cycle;