
import React from 'react';
// import ReactGA from "react-ga";

import ExpandableCard from '../../components/expandableCard/expandableCard'
import ActionCard from '../../components/actionCard/actionCard';
import CardHeader from '../../components/cardHeader/cardHeader';

const ProtectWealth = () => {

    // https://www.gov.uk/income-tax

    // https://www.gov.uk/capital-gains-tax 

    // https://www.gov.uk/individual-savings-accounts 

    const inflation = [

        {
        title: 'Invest',
        description: 'Investing in assets is the only way to stop inflation from eroding your wealth',
        pros: ['Investing may increase your wealth in real terms.'],
        cons: ['Investing comes with risk that your wealth may decrease in real terms.'],
        steps: ['Invest in assets if you have a long investment horizon.'],
        icon: 'Invest',
        },
        ]

        const investmentRisk = [

            {
                title: 'Emergency Fund',
                description: 'An emergency fund, around 3 months of cash, will allow you to invest and take on risk. Should you need money, the fund will protect your investments from early access.',
                pros: ['Provides quick access to money and ensures you will not be forced into selling your investments on unfavourable terms.'],
                cons: ['You will have three months of cash that will not be keeping pace with inflation.'],
                steps: ['Ensure you have cash available for an emergency'],
                icon: 'Ambulance',
                           },
                {
                title: 'Diversification',
                description: 'Diversification is holding a range of investments.  The goal is to limit the downside of certain disruptive market events.  Good diversification is managing exposure to certain sectors, regions and investment types.',
                pros: ['Stops you from losing everything due to one piece of misfortune'],
                cons: ['Reduces the potential upside of your investments'],
                steps: ['Look to diversify in line with your attitude to risk.'],
                icon: 'Diversify',
                },
                {
                    title: 'Attitude to Risk',
                    description: 'Different investments have differing levels of risk and uncertainty.  You can tailor your investments to the risk level you are comfortable with',
                    pros: ['You are comfortable with your investments and not excessively worried.'],
                    cons: ['Investing inline with your attitude to risk may not help you achieve your goals.  To achieve your goals you may be taking insufficient or unnecessary risk by investing inline with your attitude to risk.'],
                    steps: ['Understand your attitude to risk and invest accordingly. Many investment platforms provide tools to calculate your attitude to risk and recommend aligned funds.'],
                    icon: 'Scales',
                                       },
                                       
            ]


            const marketCrash = [

                {
                title: 'Time Horizon',
                description: 'The best defence against a market crash is time',
                pros: ['Assuming productivity continues to increase, which historically it has, the market will return to and improve upon its previous levels in time.  '],
                cons: ['Past performance is not a guarantee of future performance.'],
                warnings: ['If you have a short investment horizon, market crashes can be devastating and it would be worth reducing the risk in your investments.'],
                steps: ['Ensure your investments are invested inline with your time horizon.'],
                icon: 'Calender',
                },
                {
                    title: 'Pound Cost Averaging',
                    description: 'By investing at regular intervals, you buy more shares when the market falls.  This technique allows you to benefit from falling markets when paired with a long term investment horizon ',
                    pros: ['Better investment returns in a turbulent market than by investing with a single lump sum at outset.'],
                    cons: ['Worse investment returns in a positive market than by investing with a single lump sum at outset.'],
                    steps: ['Look to invest periodically rather than with large lump sums if you are concerned about a market crash.'],
                    icon: 'CostAverage',
                    },
                    {
                    title: 'Physical Gold',
                    description: 'Gold is regarded as a ‘safe haven’ and store of value during times of uncertainty and provides a hedge against market crashes ',
                    pros: ['During market crashes, gold traditionally has seen positive investment returns.'],
                    cons: ['Gold is also vulnerable to price swings and investment risk in its own right.'],
                    steps: ['If you think the market is overvalued or nearing the end of the market cycle, it may be worth investing some of your portfolio in gold.'],
                    icon: 'Gold',
                    },
                ]

        return (

            <div>
            
                                <ExpandableCard title='Money Risks'>

                                <div className='expandLeftSide Opacseventy'>
                                    <p className=''>2</p>
                                </div>
                                
                                <div>

                                    <CardHeader title='Inflation' primary='yes'/>

                                    <div className='standardPadding'> 

                                        { inflation.map((action) => {
                                            return <ActionCard option={action}/>
                                        }) }

                                    </div>

                                    <CardHeader title='Investment Risk'/>

                                    <div className='standardPadding'> 


                                        { investmentRisk.map((action) => {
                                            return <ActionCard option={action}/>
                                        }) }


                                    </div>

                                    <CardHeader title='Market Crashes'/>

                                    <div className='standardPadding'>

                                        { marketCrash.map((action) => {
                                            return <ActionCard option={action}/>
                                        }) }




                                    </div>

                                </div>

                            </ExpandableCard>
                        </div>  
                
        )



    }

    export default ProtectWealth;






