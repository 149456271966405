import React from 'react';
// import ReactGA from "react-ga";

import ExpandableCard from '../../components/expandableCard/expandableCard'
import Team from '../../icons/team';
import ClockYel from '../../icons/clockYel';
import Book from '../../icons/book';
import Discussion from '../../icons/discussion';
import LinkedIn from '../../icons/linkedin';
import Presentation from '../../icons/presentation';
import Meeting from '../../icons/meeting';
import Twitter from '../../icons/twitter';
import Coach from '../../icons/coach';
import Diploma from '../../icons/diploma';
import CardHeader from '../../components/cardHeader/cardHeader';
import IconHead from '../../components/iconHead/iconHead';
import Steps from '../../icons/steps';
import CardHeaderSec from '../../components/cardHeader/cardHeaderSec';

const WealthYou = () => {

        const youCat = [
            {title: 'Knowledge', wording: 'Knowing what to do is the foundation upon which wealth is built.'},
            {title: 'Experience', wording: 'Understanding when and how is much harder than knowing what.  Having the right technique for the occasion differentiates the top 10% from the rest.'},
            {title: 'Network', wording: 'Knowing who can solve a problem is half the solution. Building a network requires you to meet new people, provide value and stay present. A network will bring you the greatest wealth over time.'}
        
        ]

        const youKnow = [
            {title: 'Books', wording: 'Within every field there are 10 books that will change your world.  These books will provide the foundation of what you need to succeed. Google the top 10 books in ... and buy them.'},
            {title: 'Courses', wording: 'Courses provide knowledge with technique.  Location based courses provide technique and provide coaching and networking opportunities.  But, these courses are often expensive. Online courses (e.g. Udemy) are cheaper and often excellent alternatives.'},
            {title: 'Social Media', wording: 'Find your heroes, follow them and learn from them.'},

        ]

        const youExp= [
            {title: 'Just Do It', wording: "The only way to get experience is to walk the walk. The more you do, the more you will learn.  You will fail and that's ok."},
            {title: 'Coaching', wording: 'The best in the world have coaches.  Coaches bring encouragement, accountability, perspective and experience.  A good coach will push you to the next level and is the surest way to success. '},
        ]

        const youNetMeet = [
            {title: 'Day to Day', wording: 'Every day provides you with opportunities.  From chatting to colleagues at the coffee machine to talking to people in your gym class.  Being mindful to building relationships everyday will take effort.'},
            {title: 'Meetups and Seminars', wording: 'The networking equal to speed dating. Most of the conversations will be uncomfortable and awkward. Others will open doors. Pushing yourself to go to these events is one of the best ways to build networks.'},
            {title: 'Social Media', wording: 'If you send enough messages and tweets, you will get some responses. Every response, every share and even every negative comment expands your network.'},
        ]

        const youNetVal = [
            {title: 'Make people feel important', wording: '"People will not remember the conversation, but they will remember how you made them feel". Being a know-it-all and advising without being asked, makes people feel dumb. Generally, people don not like this.'},
            {title: 'Presentations and talks', wording: 'Having a platform, any platform to share views will build a network. When sharing, you will disinterest more people than you interest. More people still will be unpleasant. Luckily you are only interested in people who are interested.'},
            {title: 'Introductions', wording: '"The art of good business is being a good middleman". The lesson, passed on by Eddie Temple in the film Layer Cake,  is connect as many people as you can.  '},
        ]

        const youNetCon = [
            {title: 'Talk', wording: 'We all have relationships that have drifted because we did not talk. Birthdays, "I saw this and thought of you" and work anniversaries are all opportunities to say hi. Dedicate 30 minutes each week to maintaining relationships. Just talk'},
          ]
        

        
        return (

            <div>

            <ExpandableCard title='You'>

                                <div className='expandLeftSide Opacseventy'>
                                    <p className=''>3</p>
                                </div>

                                <div>

                                <CardHeader title='Your value increases with' primary='yes'/>
                            
                                    <div className='standardPadding'>

                                        <IconHead title={youCat[0].title} wording={youCat[0].wording}>
                                            <Book width='50' height='48'/>

                                        </IconHead>

                                        <IconHead title={youCat[1].title} wording={youCat[1].wording}>
                                            <ClockYel width='50' height='48'/>

                                        </IconHead>

                                        <IconHead title={youCat[2].title} wording={youCat[2].wording} nounderline='true'>
                                            <Team width='50' height='48'/>

                                        </IconHead>

                                    </div>


                                   <CardHeader title='How to gain knowledge'/>

                                   <div className='standardPadding'>


                                        <IconHead title={youKnow[0].title} wording={youKnow[0].wording}>
                                            <Book width='50' height='48'/>

                                        </IconHead>

                                        <IconHead title={youKnow[1].title} wording={youKnow[1].wording}>
                                            <Diploma width='50' height='48'/>

                                        </IconHead>

                                        <IconHead title={youKnow[2].title} wording={youKnow[2].wording} nounderline='true'>
                                            <Twitter width='50' height='48'/>

                                        </IconHead>

                                    </div>

                                    <CardHeader title='How to gain experience'/>

                                   <div className='standardPadding'>

                                    <IconHead title={youExp[0].title} wording={youExp[0].wording}>
                                        <Steps width='50' height='48'/>
                                        

                                    </IconHead>

                                    <IconHead title={youExp[1].title} wording={youExp[1].wording} nounderline='true'>
                                        <Coach width='50' height='48'/>

                                    </IconHead>
                               

                                    </div>

                                    <CardHeader title='How to build a network'/>

                                    <div className='standardPadding'>

                                    <CardHeaderSec title='How to meet new people'/>

                                    <IconHead title={youNetMeet[0].title} wording={youNetMeet[0].wording}>
                                        <Team width='50' height='48'/>

                                    </IconHead>

                                    <IconHead title={youNetMeet[1].title} wording={youNetMeet[1].wording}>
                                        <Presentation width='50' height='48'/>

                                    </IconHead>

                                    <IconHead title={youNetMeet[2].title} wording={youNetMeet[2].wording} nounderline='true'>
                                        <LinkedIn width='50' height='48'/>

                                    </IconHead>

                                
                                    <CardHeaderSec title='How to give value'/>

                                    <IconHead title={youNetVal[0].title} wording={youNetVal[0].wording}>
                                        <Discussion width='50' height='48'/>

                                    </IconHead>

                                    <IconHead title={youNetVal[1].title} wording={youNetVal[1].wording}>
                                        <Presentation width='50' height='48'/>

                                    </IconHead>

                                    <IconHead title={youNetVal[2].title} wording={youNetVal[2].wording} nounderline='true'>
                                        <Meeting width='50' height='48'/>

                                    </IconHead>


                                    <CardHeaderSec title='How to maintain relationships'/>

                                    <IconHead title={youNetCon[0].title} wording={youNetCon[0].wording} nounderline='true'>
                                        <Discussion width='50' height='48'/>

                                    </IconHead>




                                    </div>
                                </div>

                            </ExpandableCard>
                        </div>  
                
        )



    }

    export default WealthYou;






