import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import About from './containers/About/About'
import Advice from './containers/Advice/Advice'
import Why from './containers/Why/Why'
import Home from './containers/Home/home'
import './App.css';
import DIYRetirement from './containers/DIYRetirement/DIYRetirement';
import DIYWealth from './containers/DIYWealth/DIYWealth';
import DIYWealthPres from './containers/DIYWealthPres/DIYWealthPres';

import ReactGA from 'react-ga';
import Layout from './containers/Layout/layout';

function App() {

  ReactGA.initialize('UA-146502320-1');



  const home = () => {
    return <Home/>
  }

  const about = () =>  {
    return <About/>
  }

  const advice = () =>  {
    return <Advice/>
  }

  const diyRetirement = () =>  {
    return <DIYRetirement/>
  }

  const diyWealth = () =>  {
    return <DIYWealth/>
  }

  const diyWealthProtection = () =>  {
    return <DIYWealthPres/>
  }

  const why = () =>  {
    return <Why/>
  }



  return (
    <Router>
    <div className="App">

      <Layout>
        <Switch>
          <Route exact path="/why/" component={why} />
          <Route exact path="/advice/" component={advice} />
          <Route exact path="/retire/" component={diyRetirement} />
          <Route exact path="/createwealth/" component={diyWealth} />
          <Route exact path="/protectwealth/" component={diyWealthProtection} />
          <Route exact path="/about/" component={about} />
          <Route exact path="/" component={home} />
          <Redirect from="/" to="/"  />
      </Switch>
      </Layout>

    </div>
  </Router>
  );
}

export default App;
