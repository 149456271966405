import React from "react";

function Discussion({width=24, height=20}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 20"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-251 -309)"
      >
        <rect
          width="286"
          height="390"
          x="69"
          y="149"
          stroke="#E8E3E3"
          strokeWidth="2"
          rx="4"
        ></rect>
        <g fill="#303030" fillRule="nonzero">
          <path
            d="M17.937 6.498c0-3.645-2.644-6.5-6.018-6.5H6.334C3.034-.002.45 2.909.45 6.625c0 3.269 1.823 5.75 4.681 6.438v4a.5.5 0 00.855.353l4.382-4.417h1.55c3.375-.001 6.019-2.856 6.019-6.501zm-7.777 5.5a.503.503 0 00-.355.147l-3.674 3.703v-3.193a.5.5 0 00-.414-.492C3.125 11.712 1.45 9.538 1.45 6.625 1.45 3.47 3.596.998 6.334.998h5.584c2.814 0 5.018 2.416 5.018 5.5s-2.204 5.5-5.018 5.5H10.16z"
            transform="translate(251 309)"
          ></path>
          <path
            d="M19.262 7.105a.5.5 0 000 1c1.907 0 3.401 1.674 3.401 3.811 0 1.99-1.557 3.796-3.33 3.862a.5.5 0 00-.481.5v1.533l-1.668-1.667a.5.5 0 00-.354-.146h-1.516c-1.018 0-2.035-.616-2.723-1.648a.5.5 0 10-.832.554c.874 1.311 2.203 2.094 3.555 2.094h1.309l2.375 2.374a.5.5 0 00.854-.354v-2.29c2.11-.351 3.812-2.443 3.812-4.812-.001-2.697-1.935-4.811-4.402-4.811zM13.48 6.498a.5.5 0 00-.5-.5H5.14a.5.5 0 000 1h7.84c.277 0 .5-.223.5-.5zM5.141 4.998h7.84a.5.5 0 000-1h-7.84a.5.5 0 000 1zM9.061 7.998h-3.92a.5.5 0 000 1h3.92a.5.5 0 000-1z"
            transform="translate(251 309)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Discussion;