import React from 'react';

import './bannerHeader.css';


const BannerHeader = (props) => {

    return (
        
        <div className='banner-content'>

                <h1 className='bannerTitle animate-pop-in '>{props.title}</h1>

                <div className="bannerUnderline animate-pop-in"></div>
                
                <h3 className='bannerText animate-fade-in anim-delay-third'>{props.subHeader}</h3>

        </div>

    )
}


export default BannerHeader;