import React from "react";

function Shoe({width=24, height=12}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 12"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-400 -316)">
          <g transform="translate(400 316)">
            <g>
              <path d="M22.5 11.5h-21C.673 11.5 0 10.827 0 10V9a.5.5 0 01.5-.5h23a.5.5 0 01.5.5v1c0 .827-.673 1.5-1.5 1.5zM1 9.5v.5a.5.5 0 00.5.5h21a.5.5 0 00.5-.5v-.5H1z"></path>
              <path d="M23.5 9.5A.5.5 0 0123 9c0-1.931-1.746-3.063-2.847-3.323-1.144-.271-2.137-.635-3.035-1.114l-5.289-2.821c-.822-.438-1.904-.241-2.446.383C8.837 2.752 7.806 3.5 6 3.5c-2.113 0-3.164-1.036-3.603-1.654A.782.782 0 001.76 1.5H1V9a.5.5 0 01-1 0V1A.5.5 0 01.5.5h1.26c.567 0 1.11.287 1.452.767C3.612 1.83 4.42 2.5 6 2.5c1.43 0 2.219-.561 2.629-1.032.84-.963 2.448-1.258 3.67-.608l5.289 2.821c.821.438 1.735.773 2.795 1.023C21.781 5.034 24 6.493 24 9a.5.5 0 01-.5.5z"></path>
            </g>
            <path d="M4.5 9.5A.5.5 0 014 9C4 7.07 2.43 5.5.5 5.5a.5.5 0 010-1C2.981 4.5 5 6.519 5 9a.5.5 0 01-.5.5zM16.5 9.5a.5.5 0 01-.422-.768c.602-.949 2.681-4.042 4.19-4.042a.5.5 0 010 1c-.665 0-2.254 1.857-3.346 3.578a.499.499 0 01-.422.232zM11.5 4a.5.5 0 01-.416-.777l1-1.5a.5.5 0 01.832.554l-1 1.5A.498.498 0 0111.5 4zM13.5 5a.5.5 0 01-.416-.777l1-1.5a.5.5 0 11.832.554l-1 1.5A.498.498 0 0113.5 5zM15.5 6a.5.5 0 01-.416-.777l1-1.5a.5.5 0 11.832.554l-1 1.5A.498.498 0 0115.5 6z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Shoe;