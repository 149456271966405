
import React from 'react';
import './footer.css';
import { withRouter } from "react-router-dom";

const Footer = ((props) => {

    const linkToAbout = () => {
        window.scrollTo(0, 0);
        props.history.push('/about')
    }


 return (
     <div className="footer">
            <p>© 2020</p>
            <p></p>
            <div className='nMar'>
                <p onClick={linkToAbout} className='b pointer'>About</p>
            </div>
         
     </div>
 )

})

export default withRouter(Footer);

