import React from "react";

function Team({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-281 -405)"
      >
        <rect
          width="286"
          height="390"
          x="69"
          y="149"
          stroke="#E8E3E3"
          strokeWidth="2"
          rx="4"
        ></rect>
        <path
          fill="#303030"
          fillRule="nonzero"
          d="M11.914 5c1.378 0 2.5-1.122 2.5-2.5S13.293 0 11.914 0a2.503 2.503 0 00-2.5 2.5c0 1.378 1.122 2.5 2.5 2.5zm0-4c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5-1.5-.673-1.5-1.5.674-1.5 1.5-1.5zM19 6c1.378 0 2.5-1.122 2.5-2.5S20.379 1 19 1a2.503 2.503 0 00-2.5 2.5C16.5 4.878 17.622 6 19 6zm0-4a1.501 1.501 0 11-1.5 1.5c0-.827.674-1.5 1.5-1.5zm-2.597 12.237l-.663-5.808C15.583 7.044 14.486 6 13.19 6h-2.553c-1.294 0-2.39 1.044-2.548 2.429l-.663 5.808a1.7 1.7 0 00.411 1.323 1.48 1.48 0 001.027.505l.552 7.472a.5.5 0 00.498.463h4c.262 0 .48-.202.498-.463l.552-7.472a1.478 1.478 0 001.027-.505c.318-.355.467-.837.412-1.323zm-1.156.657a.498.498 0 01-.366.173H14.5a.5.5 0 00-.499.463L13.45 23h-3.071l-.55-7.47a.5.5 0 00-.499-.463h-.38a.5.5 0 01-.366-.173.699.699 0 01-.164-.544l.663-5.808C9.183 7.663 9.852 7 10.639 7h2.553c.786 0 1.455.663 1.556 1.542l.663 5.808a.699.699 0 01-.164.544zm8.108-.579l-.641-5.124a2.502 2.502 0 00-2.48-2.19h-2.469c-.278 0-.558.049-.833.147a.5.5 0 00.334.942c.167-.059.335-.089.499-.089h2.469c.755 0 1.394.565 1.488 1.314l.641 5.124a.495.495 0 01-.121.393.493.493 0 01-.375.169H21.5a.5.5 0 00-.499.464l-.467 6.536h-3.069l-.36-5.037a.495.495 0 00-.534-.463.5.5 0 00-.463.534l.393 5.501c.02.261.238.464.499.464h4a.5.5 0 00.498-.463l.467-6.539a1.5 1.5 0 001.39-1.683zm-15.933 2.3a.494.494 0 00-.534.463L6.536 22h-3.07L3 15.464A.501.501 0 002.5 15h-.367a.493.493 0 01-.375-.169.494.494 0 01-.121-.393l.641-5.124A1.5 1.5 0 013.766 8h2.469c.112 0 .231.017.377.056a.5.5 0 00.254-.968A2.46 2.46 0 006.234 7H3.766a2.503 2.503 0 00-2.48 2.19l-.641 5.124a1.501 1.501 0 001.39 1.683l.467 6.539A.5.5 0 003 23h4a.5.5 0 00.499-.464l.385-5.387a.5.5 0 00-.462-.534zM5 6c1.378 0 2.5-1.122 2.5-2.5S6.379 1 5 1a2.503 2.503 0 00-2.5 2.5C2.5 4.878 3.622 6 5 6zm0-4a1.501 1.501 0 11-1.5 1.5C3.5 2.673 4.174 2 5 2z"
          transform="translate(281 405)"
        ></path>
      </g>
    </svg>
  );
}

export default Team;