import React from 'react';
import './topCard.css';

import Fade from 'react-reveal/Fade';
import ExpandableCard from '../../components/expandableCard/expandableCard';
import IconSection from '../iconSection/iconSection';
import ThumbUp from '../../icons/thumbUp';
import ThumbDown from '../../icons/thumpDown';
import Warning from '../../icons/warning';

const TopCard = (props) => {

    let borderGraphic = props.option.ratings.map((option, i) => {
        return  <div key={i} className='flexItem'>
                    <p className='b'>{option.name}</p> 
                    <p><span className={props.option.colour}>{option.value}</span></p>
                </div>
    })

    return (

        <Fade delay={50}>
        
        <div className='topCard'>

            <h3 className='centre bling'>{props.option.title}</h3>

                <div className="pictureWrap radarProfile">
                    {props.children}
                </div>

                {props.toggleStatus ? <button className='actionButtonS' onClick={props.toggleProfile}>Hide Profile</button> : <button className='actionButtonS' onClick={props.toggleProfile}>Show Profile</button> }

                <p className='b centre'>{props.option.wording}</p>
                            
                            <IconSection>
                                <ThumbUp/>
                                <p>{props.option.pros}</p>
                            </IconSection>
                
                            <IconSection>
                                <ThumbDown/>
                                <p>{props.option.cons}</p>
                            </IconSection>

                            {props.option.warning ? 
                            <IconSection>
                                <Warning/>
                                <p>{props.option.warning}</p>
                            </IconSection>
                            : null }

                <div className='flexWrap'>

                    <div className='lightPadding sixty'>
                        <p className='b'>Key Question: </p>
                        <p>{props.option.question}</p>
                     </div>

                      <div className='borderedGraphic forty'>
                        {borderGraphic}
                      </div>

                                                  
                </div>

                

    </div>

    </Fade>
    )
}


export default TopCard;