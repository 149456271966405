import React, {useState} from 'react';
// import ReactGA from "react-ga";

import ExpandableCard from '../../components/expandableCard/expandableCard'

import Cost from '../../icons/cost';
import Trust from '../../icons/trust';
import Options from '../../icons/options';
import Easy from '../../icons/easy';
import FormDynHook from '../../components/formDynHook/formDynHook';
import WealthInvestApp from './WealthInvestApp';
import WealthProduct from './WealthProduct';
import CardHeader from '../../components/cardHeader/cardHeader';
import WealthInvestYou from './WealthInvestYou';
import IconHead from '../../components/iconHead/iconHead';
import Steps from '../../icons/steps';
import WealthShares from './WealthShares';
import CardHeaderSec from '../../components/cardHeader/cardHeaderSec';

const WealthOpportunity = () => {


    let questions = [
        {id: 1, code:'objective', title: 'Your objective?', type: 'Select', value: '', options: ['Wealth', 'House Deposit', 'Retirement']},
       {id: 2, code:'knowledge', title: 'Your level of investment expertise?', type: 'Select', value: '', options: ['Beginner', 'Knowledgeable','Expert']},
        {id: 3, code:'time', title: 'How much time you have to research?', type: 'Select', value: '', options: ['Little', 'Some','Lots']},
        {id: 4, code:'horizon', title: 'How long you plan to be invested for?', type: 'Select', value: '', options: ['Less than 2 Years', 'Between 2 and 10 Years','Longer than 10 Years']}
      
    ];

    const [formQuestions, setformQuestions] = useState(questions);
    const [results , setResults ] = useState();
    const [notificationMessage, setNotificationMessage] = useState(null);
    const [showResults , setShowResults ] = useState(false);




        const mse= () => {
            const url = 'http://www.moneysavingexpert.co.uk/'
            window.open(url, '_blank');
        }

        const updateForm = (answers) => {

            

            setformQuestions(answers.questions)

            setResults(answers.results)

            if(!showResults){
                setNotificationMessage('Tets')
                toggleResults();
            }

        
        }

        const validateForm = () => {
            
            setNotificationMessage('Please complete all the fields')

        }

        



            const toggleResults= () => {

                setShowResults(!showResults)

            }



        return (


            <div>

            <ExpandableCard title='Stock Market'>

                <div className='expandLeftSide Opaceighty'>
                    <p className=''>4</p>
                </div>

                <div>

                        <WealthShares/>

                         {!showResults ? <div>

                         <CardHeader title='How to Invest'/>
                        
                         <div className='standardPadding'>

                            <CardHeaderSec title='Complete the below to find out'/>
                            

                            <div className='espace'>
                                <FormDynHook questions={formQuestions} action={updateForm} validation={validateForm} button='Find Out'/>
                            </div>

                            <p className='i centre'>Please note these suggestions do not constitute advice.</p>
                        </div>

                        </div> : null }


                        {showResults ? <div>

                            <WealthInvestYou results={results} toggleResults={toggleResults}/>                           

                            <WealthProduct results={results}/>
                            


                            <WealthInvestApp results={results}/>

                            <CardHeader title='To find a platform to invest with'/>

                            <div className='standardPadding widthControl'>

                                    <IconHead title='Check costs' wording='Investment platforms and funds have many ways of charge you. These include annual platform fees, annual management charges and transaction charges. Ensure you know what your paying for. '>
                                        <Cost width='50' height='48'/>
                                        
                                    </IconHead>

                                    <IconHead title='Investment options' wording='Investment options are critical.  Make sure your investment platform has the range of investments and support you need to manage your money.'>
                                        <Options width='50' height='48'/>
                                        
                                    </IconHead>

                                    <IconHead title='Ensure reliability' wording='The investment platform you choose needs to be reliable enough to look after your money. Regulatory accreditation is key.'>
                                        <Trust width='50' height='48'/>
                                        
                                    </IconHead>

                                    <IconHead title='Easy to use' wording='You will be engaging with your platform on a regular basis to manage your money.  You need to ensure they have the tools to make this easy.'>
                                        <Easy width='50' height='48'/>
                                        
                                    </IconHead>

                                    <div className='iconHead standardPadding'>
                                
                                        <Steps width='50' height='48'/>

                                        <h4 className='centre bling'>Make it happen</h4>

                                        <p className='centre'>In our opinion, the best site to find good investment providers is Money Saving Expert.</p>

                                        <button className='actionButton b' onClick={mse}>Money Saving Expert</button>

                                    </div>


                                </div>
                                    


                        </div> : null }


                </div>

            </ExpandableCard>
        </div>

                
        )



    }

    export default WealthOpportunity;