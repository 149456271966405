import React from "react";

function Auction({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24 "
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-111 -145)">
          <g transform="translate(111 145)">
            <path d="M3.322 23.909a2.684 2.684 0 01-1.909-.791l-.593-.593a2.706 2.706 0 01-.788-2.031A2.705 2.705 0 011 18.542l10.149-8.458a.5.5 0 01.674.031l2 2a.5.5 0 01.031.674L5.397 22.938a2.696 2.696 0 01-2.075.971zm8.118-12.765L1.641 19.31a1.68 1.68 0 00-.61 1.229c-.022.482.154.937.496 1.279l.593.593c.68.68 1.894.623 2.509-.114l8.165-9.798-1.354-1.355z"></path>
            <path d="M15.47 14.968a.502.502 0 01-.354-.146l-6-6a.5.5 0 010-.707l5-5a.5.5 0 01.707 0l6 6a.5.5 0 010 .707l-5 5a.498.498 0 01-.353.146zm-5.293-6.5l5.293 5.293 4.293-4.293-5.293-5.293-4.293 4.293z"></path>
            <path d="M17.47 17.932a2.981 2.981 0 01-2.121-.878 2.244 2.244 0 01-.002-3.17l4.539-4.539c.847-.847 2.321-.847 3.168 0a3.004 3.004 0 01.002 4.244l-3.465 3.465a2.98 2.98 0 01-2.121.878zm4-8.244c-.331 0-.643.129-.877.363l-4.539 4.539a1.233 1.233 0 00-.363.877c0 .331.129.643.363.877.759.759 2.075.757 2.83.002l3.465-3.465c.78-.78.78-2.048 0-2.828a1.235 1.235 0 00-.879-.365zM8.47 9.247a2.226 2.226 0 01-1.584-.656 3.004 3.004 0 01-.002-4.244L10.349.882c1.133-1.133 3.109-1.133 4.242 0 .875.875.875 2.296.002 3.17l-4.539 4.539a2.224 2.224 0 01-1.584.656zm4-8.244c-.534 0-1.037.208-1.414.585L7.591 5.054c-.78.78-.78 2.048 0 2.828a1.268 1.268 0 001.756.002l4.539-4.539c.234-.234.363-.546.363-.877 0-.331-.129-.643-.363-.877a1.985 1.985 0 00-1.416-.588zM23.47 23.968h-9.875a.5.5 0 01-.5-.5c0-1.378 1.122-2.5 2.5-2.5h5.875c1.378 0 2.5 1.122 2.5 2.5a.5.5 0 01-.5.5zm-9.289-1h8.704c-.207-.582-.763-1-1.415-1h-5.875c-.651 0-1.208.418-1.414 1z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Auction;