


import React from "react";

function InvestRisk({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 26"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#303030"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M24 12L19.2 12 15.6 24 8.4 0 4.8 12 0 12"
        transform="translate(-31 -33) translate(32 34)"
      ></path>
    </svg>
  );
}

export default InvestRisk;