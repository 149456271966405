import React from 'react';
import './numberSelect.css';


const NumberSelect = ({question, withInputChange}) => {
    
    let radioItems = question.options.map((option, key, id) => {
        return <li key={key}>
                    <label className='numSelectContainer'>
                    <input
                        id = {question.id}
                        type="radio"
                        name={question.code}
                        value={option}
                        checked={question.value === option ? true : false}
                        onChange={withInputChange}
                    />
                    <span className="numSelectmark"> {option}</span>
                    
                    </label>
                </li>
    });

        return (
            <div className='flexWrap'>
                <p className="fw5 b"><span className='blingSec introText'>Q{question.id}: </span>{question.title}</p>
                <ul className='numSelectList'>      
                    {radioItems}
                </ul>
                
            </div>


        )

}


export default NumberSelect;
