import React from "react";

function Exchange({width=24, height=21}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 21"
    >
      <path
        fill="#303030"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M.5 6.4h21.793l-4.147 4.146a.5.5 0 00.706.708l5-5c.014-.013.02-.032.031-.046.029-.036.06-.073.077-.117.018-.043.022-.089.027-.133.003-.02.012-.037.012-.059 0-.047-.015-.089-.027-.132-.005-.017-.004-.037-.011-.056a.473.473 0 00-.115-.17L18.852.549a.5.5 0 00-.707.707L22.29 5.4H.498a.5.5 0 000 1H.5zm23 8H1.707l4.146-4.146a.5.5 0 00-.707-.708l-5 5c-.004.004-.005.01-.01.014a.492.492 0 00-.098.531c.013.033.037.059.058.087.017.025.027.052.049.074l5 5a.503.503 0 00.707-.002.5.5 0 000-.707l-4.146-4.145H23.5a.5.5 0 000-1v.001z"
        transform="translate(-141 -73) translate(141 73)"
      ></path>
    </svg>
  );
}

export default Exchange;