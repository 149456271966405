import React from 'react';
import Backdrop from '../../../components/Backdrop/Backdrop';
import Navigation from '../Navigation/navigation';
import './SideBar.css';



const SideBar = ( props ) => {
    let attachedClasses = 'SideBar Close';
    if (props.open) {
        attachedClasses = 'SideBar Open';
    }
    return (
        <div>
            <Backdrop show={props.open} clicked={props.closed}/>
            <div className={attachedClasses} onClick={props.closed}>
                <div>
                    <div className='sideHeader flexWrap'>
                        <p className='b white'>MENU</p>
                    </div>
                    <Navigation/>   
                </div>
            </div>
        </div>
    );
};

export default SideBar;
