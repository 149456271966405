import React from 'react';
// import ReactGA from "react-ga";

import ExpandableCard from '../../components/expandableCard/expandableCard'
import CardHeader from '../../components/cardHeader/cardHeader';
import Fade from 'react-reveal/Fade';



const WealthPrinciples = () => {




        return (


            <div >

            
            <ExpandableCard title='Principles'>

                <div className='expandLeftSide Opacfifty'>
                    <p className=''>1</p>
                </div>

                


                <div>
                    <CardHeader title='The 10 golden rules are' primary='yes'/>
                
                        <div className='standardPadding'>

                    <Fade delay={50}>
                    

                        <div className='uBordLine iconHead'>

                            <p className='largeNumber'>1</p>
                            <h4 className='centre bling'>Spend opportunity not money</h4>

                            <p className='centre'>Before spending money, ask "Would I rather spend the money on something else?" If you answer yes, don't spend the money.</p>
                            
                            <p className='centre'>Spend on what you'd rather instead.</p>
                            
                        </div>
                    </Fade>

                    <Fade delay={50}>

                        <div className='uBordLine iconHead '>

                            <p className='largeNumber'>2</p>
                            <h4 className='centre bling'>Invest, don't save</h4>


                            <p className='centre'>Every year, money buys less and less.  This is called inflation. </p>
                            
                            <p className='centre'>To maintain wealth, you have to increase your wealth at or above this rate of inflation. </p>
                            
                            <p className='centre'>The only way to do this is to invest. </p>

                            <p className='centre'>Saving does not create wealth. </p>


                        </div>

                    </Fade>

                    <Fade delay={50}>

                        <div className='uBordLine iconHead'>
                            
                                <p className='largeNumber'>3</p>
                                
                                <h4 className='centre bling'>Have an emergency fund</h4>

                            

                            <p className='centre'>Anything that can go wrong, will. Having cash available, enough to pay your bills for 3 months, will keep you in control.</p>
                            
                            <p className='centre'>Without this safety net, unforeseen events will force you into unwanted actions. Such as taking on debt or selling assets.</p>

                            <p className='centre'>With this safety net, you have capacity to invest and build wealth.</p>
                            
                            
                            

                        </div>

                    </Fade>

                    <Fade delay={50}>

                        <div className='uBordLine iconHead'>
                            
                                <p className='largeNumber'>4</p>
                                
                                <h4 className='centre bling'>Buy assets not liabilities</h4>

                            
                            
                            <p className='centre'>Robert Kiyoski, author of Rich Dad, Poor Dad has a very simple formula for wealth creation. Buy assets.</p>

                            <p className='centre'>If an item generates money, it's an asset. E.g. a house that you rent. If an item costs money, it's a liability. E.g. A house that sits idle. </p>

                            <p className='centre'>Buy items that will generate money, buy assets</p>

                        </div>

                    </Fade>

                    <Fade delay={50}>

                        <div className='uBordLine iconHead'>
                            
                            
                                <p className='largeNumber'>5</p>
                                
                                <h4 className='centre bling'>Diversify</h4>

                            
                            <p className='centre'>As the famous idiom goes "Don't keep all your eggs in one basket".</p>
                            
                            <p className='centre'>Diversification is one of the best ways to manage risk to ensure a single event does not prove disastrous for your wealth.  Good investing is effective risk management.</p>

                        </div>

                    </Fade>


                    <Fade delay={50}>
                    

                        <div className='uBordLine iconHead'>
                            
                                <p className='largeNumber'>6</p>
                                
                                <h4 className='centre bling'>Use good debt</h4>

                            
                            <p className='centre'>Using debt to buy liabilities (e.g cars) is bad debt. The repayments will take from your future without providing a return.  </p>
                            
                            <p className='centre'>Using debt to buy assets (e.g. property) is good debt.  The repayments will be offset by earnings and value growth.   Good debt gives to your future.</p>

                            <p className='centre'>Debt is both good and bad. Use good debt.</p>

                        </div>

                    </Fade>

                    <Fade delay={50}>

                        <div className='uBordLine iconHead'>
                            
                                <p className='largeNumber'>7</p>
                                
                                <h4 className='centre bling'>Look for increasing demand and limited supply</h4>

                            
                            <p className='centre'>The law of supply and demand determines the price for everything.   If demand is high and supply is constrained, prices will go up and vice versa.</p>
                            <p className='centre'>Property is popular for this reason. Populations are growing and land is not.</p>

                        </div>

                    </Fade>


                    <Fade delay={50}>

                        <div className='uBordLine iconHead'>
                            
                                <p className='largeNumber'>8</p>
                                
                                <h4 className='centre bling'>Always have an edge</h4>

                            

                            <p className='centre'>Whenever you invest, you need an edge over other investors. </p>

                            <p className='centre'>This could be buying at a discount, having more knowledge or having a better strategy.  A good example of the latter are low cost index trackers.</p>
                            
                            <p className='centre'>A market index represents average market performance. Beating the market is hard due to costs.  These costs drag the majority of investors below the index.</p>
                            
                            <p className='centre'>Low cost index trackers have an edge over the majority of investors because their costs are lower. The result is that they beat the majority of investors.</p>

                        </div>

                    </Fade>

                    <Fade delay={50}>

                        <div className='uBordLine iconHead'>

                                <p className='largeNumber'>9</p>
                                
                                <h4 className='centre bling'>Get comfortable with risk</h4>

                            
                            
                            <p className='centre'>To create wealth, risk is essential, given the correlation between risk and reward.</p>

                            <p className='centre'>Yet, it is important to find the right balance of risk and reward for you.  Finding this balance keeps you comfortable and in control.</p>
                            
                            <p className='centre'>If you are not comfortable, reduce your exposure or invest in something less risky.  </p>

                            <p className='centre'>Use experience to gain comfort with risk over time.</p>
                            

                        </div>

                        </Fade>

                    <Fade delay={50}>

                        <div className= 'iconHead'>

                            
                                <p className='largeNumber'>10</p>
                                
                                <h4 className='centre bling'>Act when losing</h4>

                            
                            
                            <p className='centre'>When you find yourself in a losing position, hope will not reverse your fortunes. Action will.</p>
                            
                            <p className='centre'>Sell and take a loss, if the reasons for making the investment have changed. This will keep your losses manageable and allow you to look for better opportunities.</p>
                            
                            <p className='centre'>Double down, if the reasons for making the investment have not changed.  You've now got a discounted price.</p>
                    

                        </div>

                    </Fade>

                    

                    </div>

                </div>

            </ExpandableCard>
        </div>

                
        )



    }

    export default WealthPrinciples;