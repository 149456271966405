import React from 'react';
import TextInput from '../components/textInput/textInput';
import SelectInput from '../components/selectInput/selectInput'
import TextAreaInput from '../components/textArea/textArea'


export const composeForm = (question, onInputChange) => {

    if(question.type==='Select'){
        return <SelectInput key={question.id} question={question} withInputChange={onInputChange}/>
      } else if(question.type === 'TextArea'){
        return <TextAreaInput key={question.id} question={question} withInputChange={onInputChange}/>
      } else {
        return <TextInput key={question.id} question={question} withInputChange={onInputChange}/>
      }
      

}
