import React from "react";

function ThumbUp({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 23"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-201 -383)"
      >
        <rect
          width="286"
          height="390"
          x="69"
          y="149"
          stroke="#E8E3E3"
          strokeWidth="2"
          rx="4"
        ></rect>
        <g fill="#303030" fillRule="nonzero">
          <path
            d="M5.5 23h-3A2.502 2.502 0 010 20.5v-8C0 11.121 1.121 10 2.5 10h5a.5.5 0 01.5.5v.191l.119-.061L11 4.391V1a.5.5 0 01.188-.391C11.219.585 11.962 0 13 0c1.157 0 3 1.831 3 4 0 1.368-.49 3.071-.796 4h6.177c1.346 0 2.491.996 2.608 2.267a2.483 2.483 0 01-.64 1.909c.412.413.651.973.651 1.574a2.24 2.24 0 01-1.234 2.001 2.24 2.24 0 01-1.001 3c.228.456.295.981.18 1.503C21.721 21.266 20.768 22 19.679 22H10.5c-.695 0-1.815-.225-2.647-.653A2.506 2.506 0 015.5 23zm-3-12c-.827 0-1.5.673-1.5 1.5v8c0 .827.673 1.5 1.5 1.5h3c.827 0 1.5-.673 1.5-1.5a.5.5 0 01.854-.354c.472.473 1.834.854 2.646.854h9.179c.624 0 1.166-.404 1.289-.962a1.237 1.237 0 00-.362-1.193.5.5 0 01.286-.861A1.246 1.246 0 0022 16.75c0-.344-.141-.666-.395-.905a.5.5 0 01.286-.861A1.246 1.246 0 0023 13.75c0-.466-.262-.89-.685-1.105a.502.502 0 01-.034-.871c.491-.303.765-.845.712-1.415C22.923 9.597 22.215 9 21.381 9H14.5a.503.503 0 01-.412-.216.503.503 0 01-.055-.462C14.042 8.297 15 5.76 15 4c0-1.638-1.452-3-2-3-.431 0-.792.156-1 .271V4.5a.51.51 0 01-.046.21l-3 6.5a.5.5 0 01-.23.237l-1 .5a.51.51 0 01-.486-.021A.502.502 0 017 11.5V11H2.5z"
            transform="translate(201 383)"
          ></path>
          <path
            d="M7.5 21a.5.5 0 01-.5-.5v-9a.5.5 0 011 0v9a.5.5 0 01-.5.5z"
            transform="translate(201 383)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ThumbUp;