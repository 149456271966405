import React, {useState, useEffect} from 'react';
import * as ValFn from '../../validations/formValidations'

import { composeForm } from '../../validations/formComposition'


const FormDynHook = ((props) => {

    const [questions, setQuestions] = useState(props.questions);
    const [warning, setWarning] = useState(false);

    useEffect(() => {

      }, [questions]);

    function onInputChange(event){

        const {value} = event.target;

        const id = props.questions.findIndex((question) => {
        return question.id === +event.target.id;
        });

        setQuestions(ValFn.updateCheck(questions, id, value))

    }   


    const onSubmitClick = event => {

        event.preventDefault();
        
        let check = ValFn.submissionCheck(questions)

        if(check){

            let results = ValFn.resultsObject(questions);

            props.action({questions: questions, results: results})


        } else {

            props.validation()
            setWarning(true)


        }
    

    }

    let listItems = questions.map((question) => {
        return composeForm(question, onInputChange);
      });

    

    return (

        <div>
            <form onSubmit={onSubmitClick} className='formStyle'>
                {listItems}
                {warning ? <p className='centre i warningText'>Please complete all the fields</p>: null}
                <button type="submit" className='actionButton b'>{props.button}</button>
            </form>
        </div>



    );
  
})

export default FormDynHook;