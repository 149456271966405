import React from 'react';
import '../textInput/textInput.css';

const selectInput = ({question, withInputChange}) => {

        let selectItems;

        if(question.options){
            selectItems = question.options.map((option, key) => {
                return <option key={key} value={option}> {option}</option>
            });
        } else {
            selectItems = <option value="no options"> No Options</option>
        }

        

        return (
            <div className='inputWrap customSelect'>
                <label className="fw5">{question.title}</label>
                
                <select id={question.id} value={question.value} onChange={withInputChange}>
                    <option value="Select an option" > Select an option</option>
                    {selectItems}
                </select>
            </div>
        )
}


export default selectInput;