import React from 'react';
import Tick from '../../icons/tick';
import Maybe from '../../icons/maybe';
import Cross from '../../icons/cross';

const resultSummary = ((props) => {

    let picture;

    if (props.outcome === 'Yes') {
      picture = <Tick width='40' height='40'/>
    } else if(props.outcome  === 'No'){
        picture = <Cross width='40' height='40'/>
    }else{
      picture = <Maybe width='40' height='40'/>
    }


    // <div className='resultSummary widthControl'>
        //     <div className='fteen flexWrap'>
        //       {picture}
        //     </div>
            
        //     <p className='eighty'>{props.wording}</p>
        // </div>

    
      


 return (

        <div className='cardFoot'>

            <h4 className='centre b white blingBanner'>{props.title}</h4>

            <p className='centre white'>{props.wording}</p>

            

      </div>
        
     
 )

})

export default resultSummary;
