import React from "react";

function Crash({width=26, height=26}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 26"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#303030"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="translate(-175 -47) translate(176 48)"
      >
        <path d="M9 16.5L16.5 24 24 16.5"></path>
        <path d="M0 0h10.5a6 6 0 016 6v18"></path>
      </g>
    </svg>
  );
}

export default Crash;