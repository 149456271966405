
import React, {useState} from 'react';
import ReactGA from "react-ga";
import Results from './results';
import FormDynHook from '../../components/formDynHook/formDynHook';
import chargeCalc from '../../calculators/chargeCalc'
import outcomeCalc from '../../calculators/outcomeCalc'

import { withRouter } from "react-router-dom";
import ScoreCard from '../../components/scoreCard/scoreCard';
import BannerHeader from '../../components/bannerHeader/bannerHeader';


const Advice = (props) => {

        ReactGA.pageview('/Why');

        const [notificationMessage, setNotificationMessage] = useState(null);
        const [notificationWarning , setNotificationWarning] = useState(null);


        let questions = [
            {id: 1, code:'objective', title: 'What is your objective?', type: 'Select', value: '', options: ['Retirement', 'Wealth Creation','Wealth Protection']},
            {id: 2, code:'wealth', title: 'How much do you have in savings, pensions and investments?', type: 'Currency', value: ''},
            {id: 3, code:'knowledge', title: 'What is your level of financial knowledge?', type: 'Select', value: '', options: ['Low', 'Ok','Good']},
            {id: 4, code:'time', title: 'How much time do you have to research and implement?', type: 'Select', value: '', options: ['Little', 'Some','Lots']}
          ];


        const [showResults , setShowResults ] = useState(false);
        const [formQuestions, setformQuestions] = useState(questions);
        const [charges , setCharges ] = useState();
        const [results , setResults ] = useState();
        const [score , setScore ] = useState();

    //     results: 
    //     {objective: 'Wealth Creation', 
    //     wealth: 0,
    //     knowledge: 'Basic',
    //     time: 'Less than a week'
    //   },
    //   score: "",
    //   charges: '',
    //   investments: '',
        

        const messageReset = (message) => {

            setNotificationMessage(null)

        }


        const updateForm = (answers) => {

            // ReactGA.event({
            //     category: 'User',
            //     action: 'Review Results'
            //   });

            setformQuestions(answers.questions)



            let tempScore = outcomeCalc(answers.results);
            let charges = chargeCalc(+answers.results.wealth);


            setScore(tempScore)
            setCharges(charges)
            setResults(answers.results)

            window.scrollTo(0, 0);
            toggleResults();

        
        }

        const validateForm = () => {
            
            setNotificationMessage('Please complete all the fields')

        }

        const toggleResults= () => {

            setShowResults(!showResults)

        }

        





        return (

                <React.Fragment>
                    <div className='banner advice'>

                    {!showResults ? 


                    <BannerHeader title="Is advice right for you?" subHeader="Complete the form to find out"/> 


                    :

                    <div>

                        {/* <h1 className='blingBanner white'>Is advice right for you?</h1> */}

                        <ScoreCard score={score}/>

                    </div>

                    }


                    </div>
                    <div className='containerSingle'>

                        <div className='content-area'>

                        {!showResults ? 

                        <div className='standardPadding card'>

                            <FormDynHook questions={formQuestions} action={updateForm} validation={validateForm} button='Find Out'/>

                        </div>
                        
                        : 

                            <Results results={results} score={score} charges={charges} back={toggleResults}/>
                        
                        }

                        </div>

                    </div>
                </React.Fragment>



        )



    }

    export default withRouter(Advice);



