import React from "react";

function House({width=24, height=24}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#303030" fillRule="nonzero" transform="translate(-448 -236)">
          <g transform="translate(448 236)">
            <path d="M23.854 11.138L13.206.491c-.643-.644-1.767-.645-2.413 0L.146 11.138a.499.499 0 00.354.854H2v11.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-8.5h6v8.5a.5.5 0 00.5.5h6c.276 0 .5-.224.499-.5v-11.5h1.5a.5.5 0 00.355-.854zm-2.354-.146a.5.5 0 00-.5.5v11.5h-4.999v-8.5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5v8.5h-5v-11.5a.5.5 0 00-.5-.5h-.793l9.793-9.793a.728.728 0 01.999 0l9.793 9.793H21.5z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default House;