import React from "react";

function Pen({width=24, height=24}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-315 -406)"
      >
        <rect
          width="286"
          height="390"
          x="69"
          y="149"
          stroke="#E8E3E3"
          strokeWidth="2"
          rx="4"
        ></rect>
        <path
          fill="#303030"
          fillRule="nonzero"
          d="M24 2.1a.51.51 0 01-.147.373c-.022.022-.05.032-.075.05l-1.403 1.403.36.36c.025.017.053.028.075.05.102.102.15.238.147.372a.508.508 0 01-.147.373c-.022.022-.05.033-.075.05L6.188 21.678a.516.516 0 01-.273.251L.802 23.935a.53.53 0 01-.094.037l-.006.002c-.004.002-.01.001-.013.003a.494.494 0 01-.297.002c-.01-.004-.02-.001-.031-.005l-.01-.007a.49.49 0 01-.126-.073c-.02-.015-.042-.028-.06-.045-.016-.018-.03-.039-.044-.06a.497.497 0 01-.074-.124c-.002-.005-.005-.007-.006-.011-.004-.01-.002-.021-.005-.032a.517.517 0 01.002-.297c.002-.004 0-.008.002-.012l.003-.006c.01-.033.02-.065.037-.095L2.085 18.1a.52.52 0 01.252-.273L16.822 3.342A1.072 1.072 0 0015.8 2.59c-.318 0-.6.14-.797.36l-.002-.001-1.696 1.695.002.002-3.567 3.567a.51.51 0 01-.72-.72l5.242-5.243c.01-.009.02-.011.03-.02a2.065 2.065 0 013.28.362l1.312-1.31c.017-.025.028-.053.05-.075a.504.504 0 01.372-.147.504.504 0 01.374.147c.021.022.032.05.05.074l.36.36L21.493.237c.017-.025.027-.053.05-.075a.505.505 0 01.372-.147.505.505 0 01.373.147c.022.022.033.05.05.075l1.44 1.44c.026.018.054.029.076.05.1.103.148.239.145.373zM1.5 22.516l3.327-1.306-2.022-2.021L1.5 22.516zM19.306 2.299L3.331 18.274l2.41 2.41L21.716 4.709l-2.41-2.41zm2.61-1.044L20.81 2.361l.844.845L22.76 2.1l-.845-.845z"
          transform="translate(315 406)"
        ></path>
      </g>
    </svg>
  );
}

export default Pen;