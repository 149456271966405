import React from "react";

function Lightning({width=18, height=34}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 34"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#303030"
        strokeWidth="1"
        d="M7.61904762 10.2857143L9.04761905 0 0 13.7142857 5.71428571 13.7142857 4.28571429 24 13.3333333 10.2857143z"
        transform="translate(-77 -201) translate(79 206)"
      ></path>
    </svg>
  );
}

export default Lightning;